import { IListMetadata } from "@/interfaces/IApiResponse";
import { atom } from "recoil";

const GlobalSearchInfoAtom = atom<{searchStarted: boolean, callInProgress: boolean, resultListInfo: null | IListMetadata}>({
    key: 'GlobalSearchInfoAtomKey',
    default: {
        callInProgress: false,
        searchStarted: false,
        resultListInfo: null
    }
})

export default GlobalSearchInfoAtom;