export enum AreaType {
    ALL = "",
    beforeSecurityChecks = "%20AND%20xconServiceRegistry.flgFirstCheckSecurity=%27true%27",
    afterSecurityChecks = "%20AND%20xconServiceRegistry.flgAfterSecurityChecks=%27true%27"
}

export enum TerminalType {
    ALL = "",
    T0 = "%20AND%20xconServiceRegistry.flgTerminalT0=%27true%27",
    T1 = "%20AND%20xconServiceRegistry.flgTerminalT1=%27true%27",
    T2 = "%20AND%20xconServiceRegistry.flgTerminalT2=%27true%27"
}

export enum TerminalNameType {
    "" = "all",
    "%20AND%20xconServiceRegistry.flgTerminalT1=%27true%27" = "t1",
    "%20AND%20xconServiceRegistry.flgTerminalT2=%27true%27" = "t2"
}

export const servicePanelInitialValues = { category: "", area: AreaType["ALL" as keyof typeof AreaType], terminal: TerminalType["ALL" as keyof typeof TerminalType] }
