
const availableSites = () => {
    return [
        {
            id: 1,
            label: 'Malpensa',
            siteUrl: process.env.NEXT_PUBLIC_SITE_MALPENSA
        },
        {
            id: 2,
            label: 'Linate',
            siteUrl: process.env.NEXT_PUBLIC_SITE_LINATE
        }
    ]
}

export default availableSites;
