import CustomIcon from "../CustomIcon/CustomIcon";
import { IServiceCardProps } from "./ICard";
import { getPathByLink } from "../../utils";
import { useRecoilValue } from "recoil";
import { LanguageAtom } from "../../atoms";
import { useIsMobile, useModule } from "../../hooks";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

interface serviceDataDTO {
    data: {
        keywords: string;
        label: string;
        lblCategoryName: { [key: string]: string };
    }
}

const ServiceCard: React.FC<IServiceCardProps> = ({ data, language }: IServiceCardProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const [isMobile] = useIsMobile();
    const serviceData = useModule<serviceDataDTO>(data?.xconServiceRegistry?.xtaxServiceCategory?.ti[0]?.targeturl)
    const router = useRouter();

    let imagePath = ""
    if (isMobile) {
        imagePath = data.upiImageServiceMobile[lang]?.mediaserverpath && data.upiImageServiceMobile[lang]?.resourcename ? data.upiImageServiceMobile[lang].mediaserverpath + "/" + data.upiImageServiceMobile[lang].resourcename : ""
    } else {
        imagePath = data.upiImageServiceDesktop[lang]?.mediaserverpath && data.upiImageServiceDesktop[lang]?.resourcename ? data.upiImageServiceDesktop[lang].mediaserverpath + "/" + data.upiImageServiceDesktop[lang].resourcename : ""
    }

    return (
        <div className={`flex flex-col rounded-2xl max-w-xs max-h-full`}>
            <div className="rounded-t-2xl cursor-pointer overflow-hidden">
                {/* <div style={{ backgroundImage: `url(${imagePath})`}} className="bg-no-repeat bg-center bg-cover h-40 bg-white rounded-t-3xl shadow-lg"></div> */}
                <img src={imagePath} className={`rounded-t-2xl scale-100 hover:scale-125 ease-in duration-500 w-full min-h-[12rem] max-h-[12rem] desk:translate-y-0`} />
            </div>
            <div className={`flex flex-col bg-lightgrey justify-center desk:p-6 ${isMobile ? "max-h-[6rem] pl-2" : ""}`}>
                <div className="flex items-center">
                    { data.xconServiceRegistry?.flgTerminalT0 || data.xconServiceRegistry?.flgTerminalT1 || data.xconServiceRegistry?.flgTerminalT2 ?
                    <div>
                        <CustomIcon iconName="marker" className="fill-[#000000] w-5" />
                    </div>
                    : <div className="min-h-[0.6rem]"></div> }
                    <p className="w-fit text-sm">{(data.xconServiceRegistry?.flgTerminalT0 ? "T0 " : "") + (data.xconServiceRegistry?.flgTerminalT1 ? "T1 " : "") + (data.xconServiceRegistry?.flgTerminalT2 ? "T2" : "")}</p>
                </div>
                <h2 className="desk:flex w-fit text-xl font-bold desk:h-[3.5rem] desk:-mt-2 desk:items-center">{data.lblTitleService[lang]}</h2>
                <div className="w-fit text-sm pb-3 desk:pb-0">{serviceData?.data?.lblCategoryName && serviceData?.data?.lblCategoryName[lang] ? serviceData?.data?.lblCategoryName[lang] : "--------"}</div>
            </div>
            <a
                href={getPathByLink(data, lang)}
                target={data.flgTargetCtaService ? "_blank" : "_self"}>
                <div className="w-full cursor-pointer font-bold bg-lightblack rounded-b-2xl pt-3 pb-3 text-center text-white uppercase">
                    {data.lblLabelCtaService[lang] ? data.lblLabelCtaService[lang] : "-----"}
                </div>
            </a>
        </div>
    )
}

export default ServiceCard;