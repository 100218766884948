import { ICommerceCardProps } from "./ICard";


const CommerceCard: React.FC<ICommerceCardProps> = ({ title, description, ctaText, onClick, imageUrl }: ICommerceCardProps) => {

    return (
        <div style={{ boxShadow: '5px 6px 10px -5px #000000' }} className="flex flex-col rounded-2xl w-[300px]">
            <div className="rounded-t-2xl h-[240px] cursor-pointer overflow-hidden">
                <img src={imageUrl} className="rounded-t-2xl scale-100 hover:scale-125 ease-in duration-500 w-full" />
            </div>
            <div className="flex flex-col h-[100px] bg-white justify-center items-center p-6">
                <h2 className="w-fit text-2xl font-bold">{title}</h2>
                <h3 className="w-fit text-sm whitespace-nowrap">{description}</h3>
            </div>
            <div className="w-full h-[48px] text-base cursor-pointer font-bold bg-lightblack rounded-b-2xl py-3 flex items-center justify-center text-white uppercase" onClick={e => onClick(e)}>
                {ctaText}
            </div>
        </div>
    )
}

export default CommerceCard;