import { ButtonHTMLAttributes } from "react";

interface IButtonProps {
  text: string;
  centered?: boolean,
  /**
   * @deprecated
   */
  size?: "sm" | "md" | "lg";
  rounded?: string;
  bgColor?: string;
  textColor?: string;
  onClick?: (() => void) | (() => any) | ((e: any) => void) | ((e: any) => any);
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  centered = false,
  text,
  size = "md",
  rounded = "rounded-full",
  bgColor = "bg-lightblack",
  textColor = "text-white",
  onClick,
  type,
  className = '',
  disabled = false
}: IButtonProps) => {

  // const hoverColor = () => {
  //   if (textColor === " " + "text-[#000000]" || textColor === "text-black")
  //     return "hover:bg-black hover:text-[#FFFFFF]";
  //   if (textColor === " " + "text-[#FFFFFF]" || textColor === "text-white")
  //     return "hover:bg-white hover:text-[#000000]";
  // };

  return (
    <button
      type={type}
      className={`${className} py-4 px-8 flex ${centered ? 'justify-center items-center' : ''}  desk:hover:text-white desk:hover:bg-lightblue transition duration-300 uppercase font-bold ${textColor} ${bgColor} ${rounded}`}
      onClick={onClick}
      style={{WebkitTapHighlightColor: "transparent"}}
      disabled={disabled}
    >
      <div className="whitespace-nowrap">{text}</div>
    </button>
  );
};

export default Button;
