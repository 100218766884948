import { useAirlineList, useIsMobile, useOutsideClick } from "@/hooks";
import { MutableRefObject, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import selectedAirlineIdAtom from "@/atoms/SelectedAirlineIdAtom";
import IAirline from "@/interfaces/IAirline";

import TextInput from "../TextInput/TextInput";
import CustomIcon from "../CustomIcon/CustomIcon";
import styles from "./Autocomplete.module.css";

interface IAutocompleteProps {
    disabled?: boolean
}

const Autocomplete: React.FC<IAutocompleteProps> = ({ disabled = false }: IAutocompleteProps) => {

    const [isMobile, isTablet, _] = useIsMobile();
    const [airlineList] = useAirlineList();
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useRecoilState(selectedAirlineIdAtom);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation()
    useOutsideClick(dropdownRef as MutableRefObject<HTMLDivElement>, () => { setIsOpen(false) });

    const handleItemClick = (selectedItemId: any) => {
        setSelectedItemId(selectedItemId);
        setIsOpen(false);
    }

    const airlineOpts = useMemo(() => {

        let tmp = airlineList.filter((item, index) => index === airlineList.findIndex(itm => itm.airlineId === item.airlineId));

        return tmp.filter((airlineItem: IAirline) => airlineItem?.airlineDescription?.toLowerCase().includes(searchValue?.toLowerCase())).map((airlineItem: IAirline) => {
            
            return <div onClick={() => handleItemClick(airlineItem.airlineId)} className="flex justify-between cursor-pointer bg-white px-6 text-lightblack" key={airlineItem.airlineId}>
                <div>{airlineItem.airlineLabel} </div>
                {selectedItemId === airlineItem.airlineId ? <div className="w-8"><CustomIcon iconName="check" /></div> : null}
            </div>
        })
    }, [airlineList, searchValue, selectedItemId])

    const toggleOpening = () => {
        if (disabled) return;
        setIsOpen(isOpen ? false : true)
    }

    return (
        <div className="wrapper w-full">
            <div ref={dropdownRef} className={`autocomplete relative ${isMobile ? "w-full" : "w-full"}`}>
                <div onClick={() => toggleOpening()} className={"placeholder bg-white rounded-xl pl-4 pr-1.5 py-4 flex justify-between items-center " + (disabled ? ' bg-[#f8f8f8]' : 'cursor-pointer')}>
                    <div className="text-black">
                        {selectedItemId ? airlineList?.find((airlineItem: IAirline) => airlineItem.airlineId === selectedItemId)?.airlineLabel : t("airlines.airlines")}
                    </div>
                    {searchValue || selectedItemId ?
                        <div className="font-semibold z-[5]" onClick={() => { setSearchValue(""), setSelectedItemId(null) }}>
                            x
                        </div>
                        : <div>
                            <CustomIcon iconName="chevron-r-airlines" className={`w-2 transition-transform origin-center duration-300 ${!isOpen ? "rotate-90" : "rotate-0"}`} />
                        </div>}
                </div>
                {isOpen ? <div className={`dropdown absolute ${isMobile ? "w-full" : "w-full"} rounded-b-xl border z-[1]`}>
                    <div className="inputWrapper">
                        <TextInput className="rounded-t-xl rounded-b-none border-b border-mediumgrey" placeholder="Cerca" prefix={<CustomIcon className="w-5" iconName="search" />} onChange={(e: any) => setSearchValue(e.target.value)} value={searchValue} />
                    </div>
                    <div className={styles.searchResult + "border-b rounded-b-xl max-h-[300px] overflow-y-auto gap-5 flex flex-col py-4 bg-white"}>
                        {airlineOpts}
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default Autocomplete;