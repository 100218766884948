import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageAtom } from "@/atoms";
import { Dropdown } from "../DestinationFlightFilterPanel/DestinationFlightFilterPanel";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import TerminalSelector from "../TerminalSelector/TerminalSelector";
import TerminalFilterAtom from "@/atoms/TerminalFilterAtom";

interface ISelectOption {
    id: number | string;
    label: string;
}

interface IRestourantFilterProps {
    area: string,
    setCategory: (newCat: string) => void;
    setTerminal: (newTerm: string) => void;
    setArea: (newArea: string) => void;
}

const RestourantFilter: React.FC<IRestourantFilterProps> = ({area, setArea, setCategory, setTerminal}) => {
  const lang = useRecoilValue(LanguageAtom);
  const {t} = useTranslation();
  const CategoryList : ISelectOption[] = [{id:1 , label: "SNACK BAR"},{id:2 , label: t('shop.restaurants')}]
  const AreaList : ISelectOption[] = [{id:1 , label: t('shop.beforeFilter')},{id:2 , label: t('shop.afterFilter')}];
  const [selectedTerminal, setSelectedTerminal] = useRecoilState(TerminalFilterAtom);
  const [localTerminal, setLocalTerminal] = useState(selectedTerminal);

  useEffect(()=>{
    setSelectedTerminal(localTerminal);
  },[localTerminal]);

  return (
    <div className="flex bg-lightgrey rounded-2xl w-full mx-auto px-6 py-8 flex-col items-start desk:gap-0 desk:flex-row desk:items-center desk:p-10 mb-12 shadow-xl" >
      <div className="flex desk:justify-center w-full desk:items-center gap-12 desk:flex-row flex-col items-start justify-center">
        <Dropdown onChange={(e : any) => setCategory(e.target.value)} placeholder={t('shop.category')!} valueList={CategoryList} />
        <Dropdown onChange={(e : any) => setArea(e.target.value)} placeholder={'Area'} valueList={AreaList} />
        {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ? <TerminalSelector selectedId={localTerminal} setId={setLocalTerminal} /> : null}
      </div>
    </div>
  );
}

export default RestourantFilter;
