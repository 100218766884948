import { LanguageAtom } from "@/atoms";
import { useModule } from "@/hooks";
import { IForm } from "@/interfaces/IForm";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

interface IListConverterProps {
    item?: any;
    form?: IForm;
    title: string;
    name: string;
    isMobile: boolean;
}

const MultiListConverter: React.FC<IListConverterProps> = ({ item, name, form, isMobile }: IListConverterProps) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;
    const targetUrl = item?.xconListFormBlock?.targeturl ? item?.xconListFormBlock?.targeturl : item?.xconListForm?.targeturl
    const list = useModule<any>(targetUrl!);
    // const data : Array<any> = [];
    const [selected, setSelected] = useState(false);
    // let firstValue = "";
    const { t } = useTranslation();
    const inputRef = useRef<any>(null);

    const handleValidity = (e: any) =>{
        if(e.target.validity.valueMissing)
            e.target.setCustomValidity(lang === "it" ? "Campo necessario" : "Mandatory field");
        else
            e.preventDefault();
    }

    /*NOTE: Parte commentata è per ordinare le liste in ordine alfabetico, però bisognerebbe cambiare i valori di conseguenza sennò problemi con il CRM */
    
    return ( 
        <>
        {
            form 
            ?   <>
                {   
                form?.xtaxAirports?.ti.length > 1
                    ?  <select ref={inputRef} onInvalid={(e : any) => handleValidity(e)} className={`p-3 rounded-xl w-full ${isMobile ? " text-xs" : "text-sm"} mt-2 mb-2`} id={name} name="terminal" required form="helpForm">
                            <option selected value={0}>{form.lblTerminal[lang]}</option>
                                    { form?.xtaxAirports?.ti?.map((item : any) => {
                                        return <option id={name} value={"T" + (item.id-2)} >{item.label}</option>
                                    })}
                        </select>
                    :   <></>
                }
                </>
            :   <>
                {
                    item?.enuBlockType === "aeroporto" || name === "aeroporto" || item?.enuTypeBase === "terminal"
                    ?   <select ref={inputRef} className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2 `} style={ !selected ? { color: "#999999"} : { color: "black"}} id={name} name="terminal" required form="helpForm"  onChange={() => (setSelected(true))} onInvalid={e => handleValidity(e)}>
                                <option id={"0"} value="" disabled selected >{t('form.airport') + '*'}</option>
                                <option id={"T0"} value={"T0"} placeholder="Linate" style={{ color: "black"}} >Linate</option> 
                                <option id={"T1"} value={"T1"} placeholder="Malpensa T1" style={{ color: "black"}}>Malpensa T1</option>  
                                <option id={"T2"} value={"T2"} placeholder="Malpensa T2" style={{ color: "black"}}>Malpensa T2</option>       
                        </select>
                    : <></>
                }
                {
                    item?.enuBlockType === "terminal"
                    ?   <select className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2`} onInvalid={(e : any) => handleValidity(e)} id={name} name="terminal" required form="helpForm">
                                { list?.data?.lstAction.map((item : any) => {
                                return <option id={name} value={item.lblValue}>{item?.lblName[lang]}</option>      
                                })}
                        </select>
                    : <></>
                }
                {
                    item?.enuTypeBase === "codOggetto"
                    ?   <select className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2`} onInvalid={(e : any) => handleValidity(e)} id={name} name="codOggetto" required form="helpForm">
                                { list?.data?.lstAction.map((item: any, index: any) => {
                                return <option key={index} id={name} value={item.lblValue}>{item?.lblName[lang]}</option>      
                                })}
                        </select>
                    : <></>
                }
                {
                    item?.enuBlockType === "attivazione"
                    ?   <select className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "text-sm"} mt-2 mb-2`} onInvalid={(e : any) => handleValidity(e)} id={name} name={item?.enuBlockType} required form="helpForm">
                                {/* { list?.data?.lstAction.map((item : any, index: number) => {
                                    if(index === 0){
                                        firstValue = item.lblName[lang];
                                    } else {
                                        data.push(item.lblName[lang]);
                                        data.sort();
                                    }  
                                })}
                                <option value={0}>{firstValue}</option> */}
                                { list?.data?.lstAction.map((item : any) => {
                                    return <option id={name} value={item.lblName[lang]}>{item.lblName[lang]}</option>      
                                })}
                        </select>
                    : <></>
                } 
                {
                    item?.enuBlockType != "attivazione" && item?.enuBlockType != "aeroporto" && name != "aeroporto" &&  item?.enuTypeBase != "terminal" &&  item?.enuTypeBase != "codOggetto"
                    ?   <select className={`p-3 rounded-xl w-full ${isMobile ? "text-xs" : "max-w-full text-sm"} mt-2 mb-2`} onInvalid={(e : any) => handleValidity(e)} id={name} name={item?.enuBlockType} required form="helpForm">
                                 {/* { list?.data?.lstAction.map((item : any, index: number) => {
                                    if(index === 0){
                                        firstValue = item.lblName[lang];
                                    } else {
                                        data.push(item.lblName[lang]);
                                        data.sort();
                                    }   
                                })}
                                <option value={0}>{firstValue}</option> */}
                                { list?.data?.lstAction.map((item : any, index: number) => {
                                    return <option id={name} value={(index) as unknown as number * 10}>{item.lblName[lang]}</option>      
                                })}
                        </select>
                    : <></>
                } 
                </>
        }
        </>
    )
    
}


export default MultiListConverter;
