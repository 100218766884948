import { CardList } from "../index";
import { useRouter } from "next/router";
import { IMetaInfo } from "@/interfaces/IMetaInfo";
import { IImageInfoDTO } from "@/interfaces/IFooterDTO";
import { useSetRecoilState } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IModuleFourAPIResponse } from "@/interfaces/IModuleFourDTO";
import { useDataCard, useIsMobile } from "@/hooks";
import { configColor, getPathByLink } from "@/utils";
import { InfoCard, DestinationCard, InfoAccordionCard, LaunchCard, CommerceCard, ServiceCard } from "../Card";
import { FreeMode } from "swiper";
import { useTranslation } from "react-i18next";


import DOMPurify from 'isomorphic-dompurify';
import CustomIcon from "../CustomIcon/CustomIcon";
import ShopList from "../ShopList/ShopList";
import ShopFilter from "../ShopList/ShopFilter";
import TerminalFilterAtom from "@/atoms/TerminalFilterAtom";
import AreaFilterAtom from "@/atoms/AreaFilterAtom";
import CategoryFilterAtom from "@/atoms/CategoruFilterAtom";
import Product from "../ShopList/Product";
import RestourantList from "../ShopList/RestourantList";
import RestourantFilter from "../ShopList/RestourantFilter";
import uuid from "react-uuid";
import React from "react";

interface IModuleFourProps {
    data: IModuleFourAPIResponse,
    language: string
}

const typeMaxSlideMap: { [key: string]: { slidesOffsetBefore: number, freeMode: boolean, maxSlideDesktop: number | "auto", mobileSlideNumber: number | "auto", mobileCentered: boolean, desktopCentered: boolean, desktopDirection: 'horizontal' | 'vertical' | undefined, mobileDirection: 'horizontal' | 'vertical' | undefined, desktopSliderEnabled: boolean, mobileSliderEnabled: boolean } } = {
    'info': {
        maxSlideDesktop: 3,
        mobileSlideNumber: 3,
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'vertical',
        desktopSliderEnabled: false,
        mobileSliderEnabled: false,
        freeMode: false,
        slidesOffsetBefore: 0

    },
    'ecommerce': {
        maxSlideDesktop: "auto",
        mobileSlideNumber: 'auto',
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'horizontal',
        desktopSliderEnabled: true,
        mobileSliderEnabled: true,
        freeMode: true,
        slidesOffsetBefore: 0
    },
    'viaggio': {
        maxSlideDesktop: 4,
        mobileSlideNumber: 1,
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'vertical',
        desktopSliderEnabled: false,
        mobileSliderEnabled: false,
        freeMode: false,
        slidesOffsetBefore: 0
    },
    'lancio': {
        maxSlideDesktop: "auto",
        mobileSlideNumber: "auto",
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'horizontal',
        desktopSliderEnabled: true,
        mobileSliderEnabled: false,
        freeMode: true,
        slidesOffsetBefore: 0
    },
    'negozio': {
        maxSlideDesktop: 4,
        mobileSlideNumber: 1,
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'vertical',
        desktopSliderEnabled: false,
        mobileSliderEnabled: false,
        freeMode: false,
        slidesOffsetBefore: 0
    },
    'servizi': {
        maxSlideDesktop: "auto",
        mobileSlideNumber: 'auto',
        desktopCentered: false,
        mobileCentered: false,
        desktopDirection: 'horizontal',
        mobileDirection: 'horizontal',
        desktopSliderEnabled: true,
        mobileSliderEnabled: true,
        freeMode: true,
        slidesOffsetBefore: 0
    },
}

const ModuleFourWrapper: React.FC<IModuleFourProps> = ({ data, language }: IModuleFourProps) => {

    const moduleData = data;

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const [isMobile, isTablet] = useIsMobile();
    const [categoryFilter, setCategoryFilter] = useState<null | string>(null);

    const cardList = useMemo(() => {
        return moduleData?.data?.lstCard?.map((item) => {
            return item.xconCard.targeturl
        }) || []
    }, [moduleData, lang])

    const cardData = useDataCard<{ data: ICommerceCard }[]>({ urls: cardList });
    const cardToRender = useCardMatcher({ language: lang, cardList: cardData, isMobile, isTablet, currentlySettedFilter: categoryFilter, backgroundColor: moduleData.data.enuBackgroundCta})
    const backgroundColor = moduleData && moduleData?.data && moduleData.data.enuBackgroundCta === "grigio" ? 'bg-lightgrey' : 'bg-white';
    const isFlex = moduleData?.data?.enuTypeInfo === "lancio" && " desk:flex desk:gap-7";
    const lblTitle = moduleData && moduleData?.data ? DOMPurify.sanitize(moduleData.data?.lblTitle && moduleData.data?.lblTitle[lang] ? moduleData.data.lblTitle[lang] : "") : ""
    const txbSubtitle = moduleData && moduleData?.data ? DOMPurify.sanitize(moduleData.data?.txbSubtitle && moduleData.data?.txbSubtitle[lang] ? moduleData.data.txbSubtitle[lang] : '') : ""
    const enuTitleColor = moduleData && moduleData.data ? configColor('text', moduleData.data.enuTitleColor) : 'text-black';
    const dotted = moduleData && moduleData.data ? moduleData?.data?.flgHatch! : false;
    
    return (
        <>
            {moduleData && moduleData?.data?.enuTypeInfo !== 'viaggio' ?
                <div className={(moduleData.data?.lblNotes! === "Wifi - Card" ? "pt-8 pb-6 mt-10" : "py-8 desk:pb-6") + " " + backgroundColor + " " + enuTitleColor}>
                    <div className={`max-w-cont-2 mx-auto px-6 w-full mb-4 ${isFlex}`}>
                        <div className={`${isFlex ? "desk:w-1/3 " : " "} mb-11 underlineLink`}>
                            <h2 className='text-3xl font-semibold mb-2.5 font-secondary' dangerouslySetInnerHTML={{ __html: lblTitle }} ></h2>
                            <h3 className="text-lg" dangerouslySetInnerHTML={{ __html: txbSubtitle }} ></h3>
                        </div>
                        {cardData && cardToRender?.length ?
                            ((isMobile || isTablet) && typeMaxSlideMap[moduleData?.data?.enuTypeInfo]?.mobileSliderEnabled) || ((!isMobile && !isTablet) && typeMaxSlideMap[moduleData?.data?.enuTypeInfo]?.desktopSliderEnabled) || moduleData.data.lblNotes === "M04 - Lista Card (Pag. Homepage Parcheggi)" || moduleData.data.lblNotes === "Lista card promo - subhome shopping"? (
                                <Swiper
                                    modules={[FreeMode]}
                                    freeMode={/* !isMobile && */ typeMaxSlideMap[moduleData?.data?.enuTypeInfo].freeMode}
                                    slidesOffsetBefore={typeMaxSlideMap[moduleData?.data?.enuTypeInfo].slidesOffsetBefore}
                                    spaceBetween={isMobile || isTablet ? 15 : 30}
                                    slidesPerView={isMobile ? typeMaxSlideMap[moduleData.data.enuTypeInfo].mobileSlideNumber : typeMaxSlideMap[moduleData.data.enuTypeInfo].maxSlideDesktop}
                                    centeredSlides={isMobile ? typeMaxSlideMap[moduleData.data.enuTypeInfo].mobileCentered : typeMaxSlideMap[moduleData.data.enuTypeInfo].desktopCentered}
                                    direction={isMobile || isTablet ? typeMaxSlideMap[moduleData.data.enuTypeInfo].mobileDirection : typeMaxSlideMap[moduleData.data.enuTypeInfo].desktopDirection}
                                    className={`custom-carousel ${isFlex ? "desk:w-10/12" : ""}`}
                                >
                                    {/* {cardToRender.map(item => { return <SwiperSlide key={item.key} className={`w-auto ${isMobile || isTablet ? "flex justify-center" : ''}`} >{item}</SwiperSlide> })} */}
                                    {cardToRender.map((item, index) => { return <SwiperSlide key={index} className={`w-auto ${isMobile || isTablet ? "flex justify-center" : ''}`} >
                                        <div className="flex">
                                            {item}
                                            { cardToRender.length != (index + 1) && dotted === true ?
                                                <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-4" : ""} translate-x-4`}>
                                                    <CustomIcon iconName="dots" className="w-4" />
                                                </div>
                                                : <div></div> 
                                            }
                                        </div>
                                        </SwiperSlide> })}
                                </Swiper>
                            ) : <>
                            {
                            cardData[0].data?.grpType === "grpProduct"
                            ? <div className="grid desk:grid-cols-4 mob:grid-cols-2 tab:grid-cols-3 desk:gap-6 mob:gap-2">
                            {cardToRender.map((item, index) => {
                                return (
                                    <>
                                        <div key={index} >{item}</div>
                                    </>)
                            })}</div> 
                            : 
                            <>
                            {
                                cardData[0].data?.grpType === "grpShopping" ? 
                                <div className={`justify-between`} >
                                {cardToRender.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {/* <div key={item.key} >{item}</div> */}
                                            <div key={uuid()} >{item}</div>
                                            {   cardToRender.length != (index + 1) && dotted === true ?
                                                <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-4" : ""} translate-x-4`}>
                                                    <CustomIcon iconName="dots" className="w-4" />
                                                </div>
                                                : <div></div> 
                                            }
                                        </React.Fragment>)
                                })}</div> 
                            : <div className={`flex ${isMobile ? 'flex-col ' : ''} gap-4 justify-between`} >
                                {cardToRender.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {/* <div key={item.key} >{item}</div> */}
                                            <div>{item}</div>
                                            {   cardToRender.length != (index + 1) && dotted === true ?
                                                <div className={`flex items-center ${isMobile ? "justify-center rotate-90 my-4" : ""}`}>
                                                    <CustomIcon iconName="dots" className="w-4" />
                                                </div>
                                                : <div></div> 
                                            }
                                        </React.Fragment>)
                                })}
                                </div> 
                            }
                            </>

                            }
                            </>
                            : null
                        }
                    </div>
                </div>
                : <div className="max-w-cont-1 px-6 mx-auto w-full"><CardList currentySettedFilter={categoryFilter} onClickFilter={setCategoryFilter} cards={cardToRender} title={moduleData?.data?.lblTitle || { it: '', en: '' }} filters={moduleData?.data && moduleData.data.lstFilters ? moduleData.data.lstFilters : []} filterTitle={moduleData?.data && moduleData.data.lblTitleFilter ? moduleData.data.lblTitleFilter[lang] : ''} /></div>
            }
        </>
    )
}

export default ModuleFourWrapper;

interface ICardMatcherProps {
    language: string,
    cardList: { data: ICommerceCard }[],
    isMobile?: boolean,
    isTablet?: boolean,
    currentlySettedFilter: string | null,
    backgroundColor: string
}

const useCardMatcher = ({ language, cardList, isMobile = false, isTablet = false, currentlySettedFilter, backgroundColor }: ICardMatcherProps) => {

    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const router = useRouter();
    const [area, setArea] = useState("all"); // all 01 02 - accessType
    const [category, setCategory] = useState("all"); // cat varie - subcategoryDescr
    const [terminalNum, setTerminalNum] = useState("all"); // all t1 t2 - terminal
    const setAreaFilter = useSetRecoilState(AreaFilterAtom);
    const setCategoryFilter = useSetRecoilState(CategoryFilterAtom);
    const setTerminalFilter = useSetRecoilState(TerminalFilterAtom);

    useEffect(() => {

        setAreaFilter(area);
        setTerminalFilter(terminalNum);
        setCategoryFilter(category);
    })    
    
    const matchCardType = (card: any) => {
        
        switch (card.enuTypeInfo) {
            case 'info':
                let infoImagePath = card.upiImageInfo?.mediaserverpath && card.upiImageInfo?.resourcename ? card.upiImageInfo.mediaserverpath + "/" + card.upiImageInfo.resourcename : ""
                return !isMobile && !isTablet ?
                    <InfoCard language={lang} bgColor={ backgroundColor === "grigio" ? 'bg-white' : 'bg-lightgrey'} imageUrl={infoImagePath} infoItems={card.lstInfo} key={card.metainfo.id} title={card.lblTitleInfo[lang]} description={card.lblSubtitle[lang]} />
                    :
                    <InfoAccordionCard language={lang} bgColor={ backgroundColor === "grigio" ? 'bg-white' : 'bg-lightgrey'} imageUrl={infoImagePath} infoItems={card.lstInfo} key={card.metainfo.id} title={card.lblTitleInfo[lang]} description={card.lblSubtitle[lang]} />
            case 'ecommerce':
                let ecommerceImagePath = card.upiImageEcommerceDesktop[lang]?.mediaserverpath && card.upiImageEcommerceDesktop[lang]?.resourcename ? card.upiImageEcommerceDesktop[lang].mediaserverpath + "/" + card.upiImageEcommerceDesktop[lang].resourcename : ""
                return <a href={getPathByLink(card, lang)} target={card.flgTargetCtaEcommerce ? "_blank" : "_self"}><CommerceCard key={card.metainfo.id} title={card.lblTitleEcommerce[lang]} imageUrl={ecommerceImagePath} description={card.lblSubtitle[lang]} ctaText={card.lblLabelCtaEcommerce[lang]} onClick={() => { }} /></a>
            case 'negozio':
                if(card.grpType === "grpShopping" && card.lblTitleShopping[lang] === "shop")
                    return  <>
                            <ShopFilter setCategory={setCategory} setArea={setArea} setTerminal={setTerminalNum} area={area}/>
                            <ShopList category={category}  area={area} terminalNum={terminalNum}/>
                            </>
                if(card.grpType === "grpShopping" && card.lblTitleShopping[lang] === "food")
                    return  <>
                            <RestourantFilter setCategory={setCategory} setArea={setArea} setTerminal={setTerminalNum} area={area}/>
                            <RestourantList category={category}  area={area} terminalNum={terminalNum}/>
                            </>            
                if(card.grpType === "grpProduct")
                    return  <Product lblSubtitleShopping={card.lblSubtitleProduct[lang]} lblCrossedOutPrice={card.lblCrossedOutPriceProduct[lang]} lblLabelCtaShopping={card.lblLabelCtaProduct[lang]} lblPriceShopping={card.lblPriceProduct[lang]} lblTitleShopping={card.lblTitleProduct[lang]} upiImageShoppingDesktop={card.upiImageProductDesktop[lang].mediaserverpath + "/" + card.upiImageProductDesktop[lang].resourcename}/>
            case 'luogo':
                return <div key={card.metainfo.id}>Card tipo luogo</div>
            case 'promozione':
                return <div key={card.metainfo.id}>Card tipo promozione</div>
            case 'informativa':
                return <div key={card.metainfo.id}>Card tipo informativa</div>
            case 'lancio':
                let launchImagePath = '';
                switch (card?.grpImageBox) {
                    case "grpImageBoxQ":
                        if (isMobile)
                            launchImagePath = card.upiImageBoxQMobile[lang]?.mediaserverpath && card.upiImageBoxQMobile[lang]?.resourcename ? card.upiImageBoxQMobile[lang].mediaserverpath + "/" + card.upiImageBoxQMobile[lang].resourcename : "";
                        else
                            launchImagePath = card.upiImageBoxQDesktop[lang]?.mediaserverpath && card.upiImageBoxQDesktop[lang]?.resourcename ? card.upiImageBoxQDesktop[lang].mediaserverpath + "/" + card.upiImageBoxQDesktop[lang].resourcename : "";
                        break;
                    case "grpImageBoxH":
                        if (isMobile)
                            launchImagePath = card.upiImageBoxHMobile[lang]?.mediaserverpath && card.upiImageBoxHMobile[lang]?.resourcename ? card.upiImageBoxHMobile[lang].mediaserverpath + "/" + card.upiImageBoxHMobile[lang].resourcename : "";
                        else
                            launchImagePath = card.upiImageBoxHDesktop[lang]?.mediaserverpath && card.upiImageBoxHDesktop[lang]?.resourcename ? card.upiImageBoxHDesktop[lang].mediaserverpath + "/" + card.upiImageBoxHDesktop[lang].resourcename : ""
                        break;
                }
                if(card.upiImageBoxQMobile[lang]?.mediaserverpath)
                    return <a href={getPathByLink(card, lang)} target={card?.flgTarget ? "_blank" : "_self"}><LaunchCard isLaunch={true} isSquare={true} onClick={() => {}} imageUrl={launchImagePath} title={card.lblTitleBox[lang]} txtColor={configColor("text", card?.enuTitleColorBox)}/></a>
                else
                    return <></>
            case 'lancio con testi':
                return <div key={card.metainfo.id}>Card tipo lancio con testi</div>
            case 'servizio':
                let serviceImagePath = "";
                if (!isMobile) {
                    serviceImagePath = card.upiImageServiceDesktop[lang]?.mediaserverpath && card.upiImageServiceDesktop[lang]?.resourcename ? card.upiImageServiceDesktop[lang].mediaserverpath + "/" + card.upiImageServiceDesktop[lang].resourcename : ""
                } else {
                    serviceImagePath = card.upiImageServiceMobile[lang]?.mediaserverpath && card.upiImageServiceMobile[lang]?.resourcename ? card.upiImageServiceMobile[lang].mediaserverpath + "/" + card.upiImageServiceMobile[lang].resourcename : ""
                }
                return <ServiceCard language={lang} key={card.metainfo.id} data={card} />
            // return <div key={card.metainfo.id}>Card tipo viaggio-città</div>
            case 'viaggio_natura':
            case 'viaggio_cultura':
            case 'viaggio_citta':
            case 'viaggio_mare':
                let destinationImagePath = ''
                if (isMobile) {
                    destinationImagePath = card.upiImageMobile?.mediaserverpath && card.upiImageMobile?.resourcename ? card.upiImageMobile.mediaserverpath + '/' + card.upiImageMobile.resourcename : '';
                } else {
                    destinationImagePath = card.upiImageDesk?.mediaserverpath && card.upiImageDesk?.resourcename ? card.upiImageDesk.mediaserverpath + '/' + card.upiImageDesk.resourcename : '';
                }

                return <DestinationCard key={card.metainfo.id} onClickHint={() => null} hint={card?.xtaxDestinations?.ti && card?.xtaxDestinations?.ti[0] ? card.xtaxDestinations.ti[0]?.lblNation[lang] : ''} onClick={() => router.push(getPathByLink(card, lang))} title={card?.xtaxDestinations?.ti && card?.xtaxDestinations?.ti[0] ? card.xtaxDestinations.ti[0]?.lblCityName[lang] : ''} imageUrl={destinationImagePath} />

            default:
                return <div key={card.metainfo.id}>Card type not recognized</div>
        }
    }

    const resultCards = useMemo(() => cardList?.filter(cardItem => currentlySettedFilter ? cardItem.data.enuTypeInfo === currentlySettedFilter : !currentlySettedFilter).map(cardItem => {
        return matchCardType(cardItem.data)
    }), [cardList, lang, isMobile, currentlySettedFilter])

    return resultCards;
}


interface IGenericCardDTO {
    metainfo: IMetaInfo,
    lblSubtitle: { [key: string]: string }
    grpType: string,
    enuTypeInfo: 'info' | 'ecommerce' | 'negozio' | 'luogo' | 'promozione' | 'informativa' | 'lancio' | 'servizio' | 'lancio con testi' | 'viaggio_citta' | 'viaggio_cultura' | 'viaggio_natura' | 'viaggio_mare'
}

interface IInfoCard {
    lblTitleInfo: { [key: string]: string }
}

interface ICommerceCard extends IGenericCardDTO {
    lblNotes: string;
    enuBackgroundCtaEcommerce: string,
    enuSubtitleColorEcommerce: string,
    enuTitleColorEcommerce: string,
    flgTargetCtaEcommerce: boolean,
    lblLabelCtaEcommerce: { [key: string]: string },
    lblLinkCtaEcommerce: { [key: string]: string },
    lblTitleEcommerce: { [key: string]: string },
    upiImageEcommerceDesktop: { [key: string]: IImageInfoDTO }
}