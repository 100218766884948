import { useTranslation } from "react-i18next";
import { AirlineIataAtom } from "@/atoms";
import { useRecoilValue } from 'recoil';
import { useAirlineList, useIsMobile, useModule } from "../../hooks";
import { IStepperApiResponse, IStepperItem } from "@/interfaces/IStepper";
import { useEffect, useMemo, useState } from "react";
import StepperDot from "./StepperDot";
import Tabber from "../Tabber/Tabber";
import EditorialOnly from "./StepperType/EditorialOnly";
import ArrivalScheduledTime from "./StepperType/ArrivalScheduledTime";
import ArrivalCheckPassport from "./StepperType/ArrivalCheckPassport";
import DepartureCheckIn from "./StepperType/DepartureCheckIn";
import DepartureBoarding from "./StepperType/DepartureBoarding";
import DepartureScheduledTime from "./StepperType/DepartureScheduledTime";
import DepartureArrivalTime from "./StepperType/DepartureArrivalTime";
import CustomIcon from "../CustomIcon/CustomIcon";
import ArrivalBaggage from "./StepperType/ArrivalBaggage";
import IFrame from "./StepperType/IFrame";

interface IStepperProps {
    data: IStepperApiResponse,
    language: string
}

const Stepper: React.FC<IStepperProps> = ({ data, language }: IStepperProps) => {

    const moduleData = data;
    const { i18n, t } = useTranslation();
    const lang = language? language : i18n.language;
    const [isMobile] = useIsMobile();
    const airlineIata = useRecoilValue(AirlineIataAtom)
    const [airline, setAirline] = useState<any>();
    const [isMap, setIsMap] = useState(false);

    const getAirlineData = () => {

        let queryParams = new URLSearchParams({
            airlineIata: airlineIata || "",
            airportReferenceIata: process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE || "",
        })

        fetch(process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH + `/${lang}/anagraphic/airlines/lists${'?' + queryParams.toString()}`, {
            headers: {
                'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string
            }
        }).then(response => response.json())
            .then(response => setAirline(response.data))
    }

    const handleContentSelection = (stepItem: IStepperItem[]) => {

        return <>
            {stepItem?.map((item: IStepperItem, index: number) => {

                if (item.enuTypeStep == 'mappe') {

                    setIsMap(true);
                    return <IFrame key={item.id} content={item} language={lang} />
                }

                const stepperType = <>{handleTypeSelection(item)}</>

                return <div key={index} className={`desk:ml-8 desk:p-2 ${index === 0 ? "mt-10" : ""}`}>
                    <StepperDot key={item.id} isMobile={isMobile} content={stepperType} isActive={true} step={index + 1} bgColor={"black"} isTracking={true} />
                </div>

            })}
        </>
    }

    const handleTypeSelection = (typeItem: IStepperItem) => {
        
        switch (typeItem.enuTypeStep) {
            case 'solo-editoriale': return <EditorialOnly language={lang} isMobile={isMobile} content={typeItem} />
            case 'arrivi_orario-schedulato-effettivo': return <ArrivalScheduledTime language={lang} isMobile={isMobile} content={typeItem} />
            case 'arrivi_controllo_passaporti': return <ArrivalCheckPassport language={lang} isMobile={isMobile} content={typeItem} />
            case 'arrivi_Nastro bagagli' : return <ArrivalBaggage language={lang} isMobile={isMobile} content={typeItem} />
            case 'partenze_check-in': return <DepartureCheckIn language={lang} isMobile={isMobile} content={typeItem} />
            case 'partenze_imbarco-orario': return <DepartureBoarding language={lang} isMobile={isMobile} content={typeItem} />
            case 'partenze_orario-schedulato-effettivo': return <DepartureScheduledTime language={lang} isMobile={isMobile} content={typeItem} />
            case 'partenze_orario-di-arrivo': return <DepartureArrivalTime language={lang} isMobile={isMobile} content={typeItem} />
        }
    }

    const tabs = useMemo(() => moduleData?.data && moduleData?.data?.lstTab?.map((tabItem, index) => {

        return { id: tabItem.id, label: tabItem.lblTab[lang], content: <div>{handleContentSelection(tabItem.lslStep)}</div>, bgColor: "white", margin: "32", height: "0", border: "" }
    }) || [], [moduleData]);

    useEffect(() => {
        if (airlineIata) {
            getAirlineData()
        }
    }, [airlineIata])

    return (
        <div className={`${airline && airline[0]?.websiteUrl && !isMobile ? "flex max-w-cont-2 mx-auto px-6" : "max-w-cont-2 mx-auto my-5 px-6 py-0"}`}>
            <div className={`${airline && !airline[0]?.websiteUrl ? "max-w-cont-2 mx-auto my-5 px-6 py-0" : isMap ? "desk:max-w-[100%]" : "desk:max-w-[100%]"}`}>
                <h1 className={`${airline && !airline[0]?.websiteUrl ? "desk:ml-32" : ""} mb-3 text-2xl desk:text-3xl text-black font-bold`}>{moduleData?.data?.lblTitleModule[lang]}</h1>
                <Tabber tabs={tabs} defaultTabId={moduleData?.data?.lstTab[0].id} />
            </div>
            {
                airline && airline[0]?.websiteUrl ?
                    <div className="flex justify-end w-full mt-[70px] desk:mt-0">
                        <div className="desk:w-[365px] w-full h-[62px] flex rounded-3xl">
                            <div className="flex flex-col w-[303px] bg-lightgrey rounded-tl-3xl rounded-bl-3xl py-3 pl-5 font-primary">
                                <h2 className="font-bold">{t("airlines.contactAirline")}</h2>
                                <h3 className="text-xs">{t("airlines.contact") + " " + airline[0]?.airlineLabel} </h3>
                            </div>
                            <a className="w-[62px] bg-black rounded-tr-3xl rounded-br-3xl flex justify-center items-center" href={airline[0].websiteUrl} target="_blank">
                                <CustomIcon iconName="arrow" color="white" className="h-[30px]" />
                            </a>
                        </div>
                    </div>
                    : null
            }
        </div >
    );
}

export default Stepper;
