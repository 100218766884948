import { useIsMobile } from "@/hooks";
import { useTranslation } from "react-i18next";
import IAirline from "@/interfaces/IAirline";
import CustomIcon from "../CustomIcon/CustomIcon";

interface IAirlineItemProps{
    airlineItem: IAirline;
}

const AirlineItem: React.FC<IAirlineItemProps> = ({airlineItem}: IAirlineItemProps) => {

    const [isMobile, isTablet, _] = useIsMobile();
    const { t } = useTranslation();

    const logo = isMobile ? airlineItem?.logo?.mobile : airlineItem?.logo?.web;

    function terminalFilter(terminals: string[]) {

        let terminalToClean = terminals.filter((terminal: any) => {
            return terminal.indexOf(process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE?.toUpperCase()) === 0;
        });

        let terminalCleaned = terminalToClean.map((terminal) => {
            return terminal.replace(process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE?.toUpperCase() + ".", "");
        });

        if (terminalCleaned.length > 1) {
            
            return terminalCleaned[0] + "-" + terminalCleaned[1];
        } else {

            return terminalCleaned[0];
        }

    }

    return (
        <div className={`flex gap-2 desk:items-center border-b border-b-darkgrey relative pb-14 desk:p-0 text-center ${isMobile || isTablet ? "items-center" : ""}`}>
            <div className="w-1/3 desk:w-1/5 flex flex-col items-center">
                <img width={175} height={45} src={logo || 'https://upload.wikimedia.org/wikipedia/commons/0/0b/Air_Company_logo.png'} />
                <div className="desk:hidden">{airlineItem.airlineDescription}</div>
            </div>
            <div className="hidden desk:text-lg text-base desk:w-1/5 desk:block">{airlineItem.airlineDescription}</div>
            <div className="w-1/3 desk:text-lg text-base desk:w-1/5">{airlineItem.airlineIata}</div>
            <div className="w-1/3 desk:text-lg text-base desk:w-1/5">{airlineItem.terminal && airlineItem.terminal.length ? terminalFilter(airlineItem.terminal) : ""}</div>
            <div className={`desk:flex right-0 ${isMobile || isTablet ? "bottom-3 absolute" : ""} desk:justify-end desk:w-1/5 font-bold`}>
                { airlineItem.websiteUrl ? 
                    <a className="desk:text-lg text-base flex items-center gap-2" href={airlineItem.websiteUrl} target="_blank">
                        {t("airlines.goToWebSite")} <CustomIcon iconName="chevron-r" />
                    </a> : <></> 
                }
            </div>
        </div>
    )
}

export default AirlineItem;
