import { ReactHTMLElement, SyntheticEvent, useEffect } from "react";

interface ITextInputProps /* extends ReactHTMLElement<HTMLInputElement> */ {
    prefix?: React.ReactNode
    postfix?: React.ReactNode,
    placeholder?: string,
    onPostFixClick?: (() => void) | (() => any),
    onPreFixClick?: (() => void) | (() => any),
    onChange?: ((e: SyntheticEvent) => any) | ((e: SyntheticEvent) => void),
    value: string,
    fullWidth?: boolean,
    className?: string,
    inputBgColor?: string
    generalBgColor?: string
    type?: string
    onKeyDown?: any
}


const TextInput: React.FC<ITextInputProps> = ({generalBgColor = 'white', inputBgColor = 'white' ,type, prefix, postfix, className = '', placeholder, onPostFixClick, onPreFixClick, onChange, value, fullWidth = false , ...props }) => {

    return (
        <div className={`${className} customTextInputWrapper w-full flex justify-between items-center p-3 rounded-2xl bg-[${generalBgColor}]`}>
            {prefix ? <div className="iconPrefixer ml-2 mr-4" onClick={onPreFixClick ? () => onPreFixClick : undefined}> {prefix} </div> : null}
            <input value={value} onChange={onChange ? (e: SyntheticEvent) => onChange(e) : undefined} style={{ outline: 'none', backgroundColor: inputBgColor }} className={`${className} w-full ${!postfix ? 'mr-2' : ''}`} type={type} placeholder={placeholder ? placeholder : ''} {...props} />
            <div className="iconPostfixer">
                {postfix ? <div className="iconPostfixer cursor-pointer" onClick={onPostFixClick ? () => onPostFixClick : undefined}> {postfix} </div> : null}
            </div>
        </div>
    )
}

export default TextInput;