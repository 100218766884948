import DOMPurify from "isomorphic-dompurify";

interface ITitleSubtitleProps {
    title: string,
    subTitle: string
}


const TitleSubtitle: React.FC<ITitleSubtitleProps> = ({ title, subTitle }: ITitleSubtitleProps) => {

    return (
        <div className="desk:mb-8 mt-4 desk:mt-0 mb-5">
            <h1 className='font-bold desk:text-3xl text-xl mb-3 font-secondary'
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}></h1>
            {subTitle ?
                <h2 className="text-lg"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subTitle) }}></h2>
                : null}
        </div>
    )
}

export default TitleSubtitle
