import { INewsDTO } from "../../interfaces/IAccordionListDTO";
import { useEffect, useState } from "react";
import { NewsCard } from "../Card";

interface IBoxNewsProps {
    data: any[],
    lang: string,
}


const BoxNews: React.FC<IBoxNewsProps> = ({ data, lang }: IBoxNewsProps) => {

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined" && window.innerWidth < 768) setIsMobile(true)
    }, [isMobile])
    
    return (
        <div className="flex flex-col gap-16 desk:grid desk:grid-cols-3 desk:gap-y-[4.5rem] desk:gap-x-5">
            {data?.map((news: INewsDTO) => {
                return news.lblTitleNews[lang] && <div key={news.id} className="">
                    <NewsCard
                        data={news}
                        lang={lang}
                        isMobile={isMobile}
                        isFull={true} />
                </div>
            })}
        </div>
    )
}

export default BoxNews;
