import { configColor } from "@/utils";
import { IInformativeCardProps } from "./ICard";

import DOMPurify from "isomorphic-dompurify";

export interface IItemDescription {
    id: string,
    lblSubtitleInfo2: { [key: string]: string },
    enuSubtitleColorInfo2: string,
}


const InformativeCard: React.FC<IInformativeCardProps> = ({ title, imageUrl, titleColor, lang, lstDescription }: IInformativeCardProps) => {

    return (
        <>
            <div className="flex gap-5 w-60 h-full">
                <img src={imageUrl} alt='logo' className="h-fit w-auto" />
                <div className="flex flex-col gap-2 h-full">
                    <h2 dangerouslySetInnerHTML={{ __html: title }} className={`text-lg font-bold ${titleColor}`}></h2>
                    {lstDescription.map((itemDescr: IItemDescription) => {

                        const itemDescrId = itemDescr.id
                        const lblSubtitleInfo2 = DOMPurify.sanitize(itemDescr.lblSubtitleInfo2[lang]);
                        const enuSubtitleColorInfo2 = configColor('text', itemDescr.enuSubtitleColorInfo2)

                        return (
                            <h3 key={itemDescrId} className={`text-sm text-black ${enuSubtitleColorInfo2}`} dangerouslySetInnerHTML={{ __html: lblSubtitleInfo2 }} />
                        )
                    })}
                </div>
            </div>
            {/* <div className={`w-0 border-2 border-[#C4C4C4] mx-8 ${index + 1 == lenghtlist ? 'hidden' : 'block'}`}></div> */}
        </>
    )
}

export default InformativeCard;