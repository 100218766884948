import { useRecoilState } from "recoil";
import CustomIcon from "../CustomIcon/CustomIcon";
import { SearchMenuAtom } from "../../atoms";
import SearchMenu from "../SearchMenu/SearchMenu";

interface MobileSearchMenu {
    language: string;
    isMobile: boolean;
    imageUrl: { path: string, description: string, alt: string };
}

const MobileSearchMenu: React.FC<MobileSearchMenu> = ({ language, isMobile, imageUrl }: MobileSearchMenu) => {

    const [isSearchOpen, setIsSearchOpen] = useRecoilState(SearchMenuAtom);

    return (
        <div className={`fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50 transform duration-500 ease-in-out ${isSearchOpen && isMobile ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`relative overflow-y-auto h-full max-w-full w-full bg-white ${isSearchOpen && isMobile ? "shadow-lg" : ""}`}>
                <>
                    <div className="flex flex-col py-4 px-3">
                        <div className="flex border-lightgrey border-b-2">
                            <div className='logoContainer ml-auto w-6/12'>
                                <img src={imageUrl.path} />
                            </div>
                            <div className='w-3/12 flex justify-end'>
                                <button className="text-gray-600 focus:outline-none" onClick={() => { setIsSearchOpen(!isSearchOpen) }}>
                                    <CustomIcon iconName="close" />
                                </button>
                            </div>
                        </div>
                        <div>
                            <SearchMenu />
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
};

export default MobileSearchMenu;
