import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageAtom } from "@/atoms";
import { Dropdown } from "../DestinationFlightFilterPanel/DestinationFlightFilterPanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import TerminalSelector from "../TerminalSelector/TerminalSelector";
import TerminalFilterAtom from "@/atoms/TerminalFilterAtom";

interface ISelectOption {
    id: number | string;
    label: string;
}

interface IShopFilterProps {
    area: string,
    setCategory: (newCat: string) => void;
    setTerminal: (newTerm: string) => void;
    setArea: (newArea: string) => void;
}

const ShopFilter: React.FC<IShopFilterProps> = ({area, setArea, setCategory}) => {
  const lang = useRecoilValue(LanguageAtom);
  const {t} = useTranslation();
  const CategoryList : ISelectOption[] = [{id:1 , label: t('shop.books')},{id:2 , label: t('shop.fashion')},{id:3 , label: t('shop.food')},{id:4 , label: t('shop.dutyFree')},{id:5 , label: t('shop.beauty')}]
  const AreaList : ISelectOption[] = [{id:1 , label: t('shop.beforeFilter')},{id:2 , label: t('shop.afterFilter')}]
  const [selectedTerminal, setSelectedTerminal] = useRecoilState(TerminalFilterAtom);
  const [localTerminal, setLocalTerminal] = useState(selectedTerminal);

  useEffect(()=>{
    setSelectedTerminal(localTerminal);
  },[localTerminal]);

  return (
    <div className="flex bg-lightgrey rounded-2xl w-full mx-auto px-6 py-8 flex-col items-start desk:gap-0 desk:flex-row desk:items-center desk:p-10 mb-12 shadow-xl" >
      <div className="flex desk:justify-center w-full desk:items-center gap-6 desk:flex-row flex-col items-start justify-center">
        <Dropdown onChange={(e : any) => setCategory(e.target.value)} placeholder={t('shop.category') as string} valueList={CategoryList} />
        <Dropdown onChange={(e : any) => setArea(e.target.value)} placeholder={'Area'} valueList={AreaList} />
        {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ? <TerminalSelector selectedId={selectedTerminal} setId={setLocalTerminal} /> : null}
      </div>
    </div>
  );
}

export default ShopFilter;
