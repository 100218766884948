import { useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import CustomIcon from "../CustomIcon/CustomIcon";
import ReactDatePicker from "react-datepicker";
import it from 'date-fns/locale/it';
import styles from "./CustomPicker.module.css";

const CustomPicker: React.FC<any> = ({ colorArrow = '#909090', isError = false, className, ...props }) => {

    const pickerRef = useRef<null | any>(null)
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation()
    lang === "it" && registerLocale(lang, it)

    return (
        <div className="h-full w-full" onClick={(e) => { e.stopPropagation(); /* setIsTouched(true) */ }}>
            <div onClick={() => { setIsOpen(true) }} className={`bg-white py-3 desk:px-6 px-4 w-[135px] desk:w-full h-full cursor-pointer rounded-2xl desk:rounded-l-2xl ${isError ? 'border border-red' : null} flex gap-4 items-center justify-between`}>
                <div className="">{props.selected && new Date().toDateString()  === new Date(props.selected).toDateString() ? t('parking.today') : props.selected.toLocaleDateString() }</div>
                <CustomIcon className={"w-2 transition-transform origin-center duration-300 " + (isOpen ? 'rotate-90' : 'rotate-0')} iconName="chevron-r" color={colorArrow} />
            </div>
            <div className="overwritedPickerContainer">
                <ReactDatePicker
                    locale={lang === 'it' ? "it" : ""}
                    // popperPlacement="top-end"
                    // popperModifiers={[{ name: "offset", options: { offset: [0, 20], }, }]}
                    ref={pickerRef} {...props} open={isOpen} onSelect={() => setIsOpen(false)} className={`  ${className} ${styles.reactDatePicker} `} />
            </div>
        </div>
    )
}
export default CustomPicker;