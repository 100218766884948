import React, { ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
  customMessage?: string,
  targetUrl?: string
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
   
      this.state =  { hasError: false };
    }
    static getDerivedStateFromError(error: any) {
   
      return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
      console.log('ERROR BOUNDARY LOG: ', { error, errorInfo });
    }
    render() {
      if (this.state.hasError) {
        return (
          <div>
            <h2>Oops, there is an error!</h2>
            {this.props.customMessage ? <div>{this.props.customMessage}</div> : null}
            {this.props.targetUrl ? <div>Module target url: {this.props.targetUrl}</div> : null}
            <button
              type="button"
              onClick={() => this.setState({ hasError: false })}
            >
              Try again?
            </button>
          </div>
        );
      }
   
      // Return children components in case of no error
   
      return this.props.children;
    }
  }
   
  export default ErrorBoundary;