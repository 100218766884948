import { useIsMobile } from "@/hooks";
import { useRecoilValue } from 'recoil'
import { Swiper, SwiperSlide } from 'swiper/react';
import { configColor, getPathByLink } from "@/utils";
import { HideCrumbsAtom } from "@/atoms";
import { useTranslation } from "react-i18next";
import { IModuleCarouselApiResponse } from "@/interfaces/IModuleDTO";
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import styles from './Slider.module.css';
import Button from "../Button/Button";
import DOMPurify from 'isomorphic-dompurify';
import SearchPanel from "../SearchPanel/SearchPanel";
import 'swiper/css';
import "swiper/css/pagination";

SwiperCore.use([Autoplay]);

interface SliderProps {
  data: IModuleCarouselApiResponse,
  language: string
}

const paginationConfig = {
  clickable: true,
  renderBullet: (_: number, className: string) => {
    return '<div class="' + className + ' customBullet"></div>';
  },
};

const carouselAutoplayTime = process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS && !isNaN(Number(process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS)) ? Number(process.env.NEXT_PUBLIC_CAROUSEL_AUTOPLAY_MILLISECONDS) : 3000;

const Slider: React.FC<SliderProps> = ({ data, language }: SliderProps) => {

  const moduleData = data;

  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;
  const [isMobile] = useIsMobile();
  const isHideCrumbs = useRecoilValue(HideCrumbsAtom);

  return (
    <div className={"w-full mb-10 " + styles.carouselSectionWrapper + ' ' + ((moduleData?.data && moduleData.data.xconM02) && moduleData?.data?.enuTypeInfo === 'visore con M02-Widget-lancio in overlay' ? 'higherPagination' : 'lowerPagination')}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        className="custom-carousel"
        loop={true}
        pagination={paginationConfig}
        autoplay={{ delay: carouselAutoplayTime }}
        modules={[Pagination]}
      >
        {moduleData?.data?.lstViewer?.map(sliderItem => {
          let { mediaserverpath, resourcename } = isMobile ? sliderItem.upiImageMobile[lang] : sliderItem.upiImageDesktop[lang];
          let imagePath = mediaserverpath + '/' + resourcename;

          return <SwiperSlide key={sliderItem.id}>
            <div className={styles.slideItem + 'relative w-full h-[470px] desk:px-0 desk:py-0 px-8 py-0 '} >
              <img className="absolute z-0 w-full left-0 right-0 object-cover h-full" src={imagePath} />
              {/* {
                !isHideCrumbs ?
                  <div className="z-10" style={{ position: 'absolute', top: '16px' }}>
                    <Breadcrumbs colorCrumbs={moduleData.data.lstViewer[0].enuTitleColor} />
                  </div> : null
              } */}

              <div className='max-w-cont-1 pt-12 relative z-10 mx-auto px-6'>
                <div>
                  {sliderItem.lblTitle && sliderItem.lblTitle[lang] ? <h1 className={`${styles.slideTitle} ${configColor('text', sliderItem.enuTitleColor)} font-secondary desk:min-w-[40%]`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sliderItem.lblTitle[lang]) }}></h1> : null}
                  {sliderItem.lblSubtitle && sliderItem.lblSubtitle[lang] ? <h2 className={`${styles.slideSubtitle} ${configColor('text', sliderItem.enuTitleColor)}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sliderItem.lblSubtitle[lang]) }}></h2> : null}
                </div>

                {
                  sliderItem.lblLabelCta && !!sliderItem.lblLabelCta[lang] ?
                    <div className="mt-8 z-10">
                      <a href={getPathByLink(sliderItem,lang)} target={sliderItem.flgTarget ? "_blank" : "_self"}>
                        <Button className="active:bg-lightblue" text={sliderItem.lblLabelCta[lang]} />
                      </a>
                    </div> : null
                }
              </div>
            </div>
          </SwiperSlide>
        }) || []}
      </Swiper>
      {
        (moduleData?.data && moduleData.data.xconM02) && moduleData?.data?.enuTypeInfo === 'visore con M02-Widget-lancio in overlay' ?
          <div className='w-full relative max-w-cont-2 mx-auto -mt-[90px] px-6 z-20'>
            <SearchPanel language={lang} targetUrl={moduleData?.data.xconM02.targeturl} data={null}  />
          </div>
          : null
      }
    </div>
  )
}

export default Slider;
