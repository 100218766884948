import { IStepperItem } from "@/interfaces/IStepper";
import DOMPurify from "isomorphic-dompurify";

interface IStepperProps {

    content: IStepperItem;
    language: string;
}

const IFrame: React.FC<IStepperProps> = ({ content, language }: IStepperProps) => {

    return (
        <>
            {content.txbSubtitle ? 
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.txbSubtitle[language], { ADD_TAGS: ['iframe'] }) }}>
            </div> : <></>}
        </>
    );
}

export default IFrame;
