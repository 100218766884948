import { useEffect, useState } from "react";


const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState<boolean>(true);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if ((window.innerWidth < 768) !== isMobile) setIsMobile(window.innerWidth < 768)
        })

        return removeEventListener('resize', () => { })
    }, [])

    return isMobile;
}

export default useMobileDetect;