import { IShoppingCardProps } from "./ICard";

import CustomIcon from "../CustomIcon/CustomIcon";

const ShoppingCard: React.FC<IShoppingCardProps> = ({ title, titleColor = 'text-black', subtitleColor = 'text-black', imageUrl, subtitle, ctaText, onClickPath, hint }: IShoppingCardProps) => {

    return (
        <>
            <div className="rounded-3xl bg-[#F2F2F2] relative h-fit desk:w-72 mob:w-60 mb-4">

                <div style={{ backgroundImage: `url(${imageUrl})` }} className="bg-no-repeat bg-center bg-cover h-40 bg-cyan-500 rounded-t-3xl"></div>

                <div className="h-40 p-3 pb-7 desk:pb-12 ">
                    <div className={`flex flex-col gap-4`}>
                        <div>
                            <div className="flex items-center">
                                <CustomIcon iconName="marker" className="w-5" />
                                <a href="#" className="underline underline-offset-2 text-sm">{hint}</a>
                            </div>
                            <h2 className={`font-bold text-lg desk:text-xl ${titleColor}`}>{title}</h2>
                        </div>
                        <div className="flex gap-2">
                            <a className={`font-bold text-sm ${subtitleColor}`} href={onClickPath}>{subtitle}</a>
                            <CustomIcon iconName="arrow" className="w-2" />
                        </div>
                    </div>
                    {ctaText ? <a href="#" className={`absolute bottom-0 right-0 bg-[#6ABACE] text-[#FFFFFF] py-3 px-6 rounded-tl-3xl rounded-br-3xl text-sm font-bold`}>{ctaText}</a> : null}
                </div>
            </div>
        </>
    )
}

export default ShoppingCard