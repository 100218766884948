import { FLIGHT_DETAIL_PAGE_URLS, MovementType } from "@/config/flightPages";
import { configColor, formatTime } from "@/utils";
import { useTranslation } from "react-i18next";
import CustomIcon from "../CustomIcon/CustomIcon";
import TrackingButton from "../Tracking/TrackingButton";
import { useIsMobile } from "../../hooks";
import { ICrysisStateDTO } from "../../interfaces/ICrysisStateDTO";
import getFlightStatus from "@/utils/getFlightStatus";
import getStatusBackground from "@/utils/getStatusBackground";

interface IFlightRowProps {
    flightItem: IFlightItem
    crysisInfo: ICrysisStateDTO
}

const FlightRow: React.FC<IFlightRowProps> = ({ flightItem, crysisInfo }: IFlightRowProps) => {

    const { i18n } = useTranslation();
    const lang = i18n.language;
    const { t } = useTranslation();
    const [isMobile] = useIsMobile();

    const flightRouting = flightItem.movementType == 'A' ? flightItem.routing[0] : flightItem.routing[flightItem.routing.length-1];
    const flightRoutingTerminal = flightItem.movementType == 'A' ? flightItem.routing[1] : flightItem.routing.length > 2 ? flightItem.routing[1] : flightItem.routing[0];

    const handleFlight = () =>
        window.location.href = FLIGHT_DETAIL_PAGE_URLS[flightItem.movementType][lang] + '?flightId=' + flightItem.flightId + "&flightNumber=" + flightItem.flightNumber.replace(" ", "") + '&airportDestinationDescription=' + (flightItem.movementType === MovementType.D ? flightItem.routing[flightItem.routing.length - 1] : process.env.NEXT_PUBLIC_AIRPORT_REFERENCE);

    return (

        <div className="text-black group desk:p-8 p-4 mx-6 rounded-2xl flex flex-col desk:flex-row desk:items-center shadow-xl desk:hover:shadow-2xl desk:hover:scale-[1.01] duration-[600ms]">
            {isMobile &&
                <div className="flex justify-between items-center mb-4">
                    <div className="desk:w-[20%]">
                        {!crysisInfo?.flgHideStatus ? (
                            crysisInfo?.lblLabelStatus[lang] || flightItem?.statusPubblicDescription ?
                                <StateChip
                                    lang={lang}
                                    flightItem={flightItem}
                                    bgChip={crysisInfo?.flgColorStatus ? configColor('bg', crysisInfo?.enuColorStatus) : undefined}
                                    crysisLbl={crysisInfo?.lblLabelStatus && crysisInfo?.lblLabelStatus[lang] ? crysisInfo?.lblLabelStatus[lang].toLocaleUpperCase() : undefined} />
                                : null)
                            : null}
                    </div>
                    <div className="desk:w-[13.3%] flex justify-end">
                        <div onClick={() => handleFlight()} className="flex gap-1 items-center cursor-pointer">
                            <div className="w-2">
                                <CustomIcon color="" iconName="arrow" className="h-4" />
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="desk:w-[25%] flex flex-col mb-4 desk:mb-0">
                <FlightTime flightItem={flightItem} />
                <div className="desk:text-base text-sm font-bold w-[max-content] desk:w-[65%]">{flightRouting.airportLabel} ({flightRouting.airportIata})</div>
            </div>
            <div className="desk:w-[50%] flex flex-col justify-start">
                <div className="row flex gap-[2rem] desk:gap-3 items-center text-[13px] desk:text-base desk:whitespace-nowrap text-center justify-between">
                    <div className="flex flex-row gap-[0.5rem]">
                        <div>{flightItem.flightNumber}</div> |
                        <div>{flightItem.airlineDescription}</div> |
                        <div>{process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE == 'mxp'? <span>{flightRoutingTerminal.terminal} </span> : ''}<span>{flightRoutingTerminal.airportIata}</span></div>
                    </div>
                    {isMobile &&
                        <div className="desk:w-[13.3%] flex justify-center desk:ml-9">
                            <TrackingButton flightDetails={flightItem} />
                        </div>
                    }
                </div>
                {flightItem.codeShare && flightItem.codeShare.length ?
                    <div className="grid grid-cols-3 gap-x-2 desk:gap-3 mt-5 desk:text-base text-sm">
                        <div className="font-bold">Codesharing</div>
                        {flightItem.codeShare.map(codeItem => {
                            return <div className="whitespace-nowrap" key={codeItem.codeShareFlightNumber}>{codeItem.codeShareFlightNumber}</div>
                        })}
                    </div>
                    : null}
            </div>
            {!isMobile &&
                <div className="desk:w-[13.3%] flex justify-center">
                    <TrackingButton flightDetails={flightItem} />
                </div>
            }
            {!isMobile &&
                <>
                    <div className="desk:w-[20%]">
                        {!crysisInfo?.flgHideStatus ? (
                                <StateChip
                                    lang={lang}
                                    flightItem={flightItem}
                                    bgChip={crysisInfo?.flgColorStatus ? configColor('bg', crysisInfo?.enuColorStatus) : getStatusBackground(getFlightStatus(flightItem, lang))}
                                    crysisLbl={crysisInfo?.lblLabelStatus && crysisInfo?.lblLabelStatus[lang] ? crysisInfo?.lblLabelStatus[lang].toLocaleUpperCase() : undefined} />
                                )
                            : null}
                    </div>
                    <div className="desk:w-[13.3%] flex justify-end">
                        <div onClick={() => handleFlight()} className="flex gap-1 items-center cursor-pointer">
                            <div className="font-bold text-base uppercase">{t('flights.details')}</div>
                            <div className="w-2 desk:group-hover:translate-x-2 duration-[600ms]">
                                <CustomIcon color="" iconName="arrow" />
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    )
}

interface IFlightItemProps {
    flightItem: IFlightItem
}

const FlightTime: React.FC<IFlightItemProps> = ({ flightItem }: IFlightItemProps) => {
    const time = flightItem.movementType === MovementType.D ? formatTime(new Date(flightItem.scheduledTime)) : formatTime(new Date(flightItem.scheduledArrivalTime));
    const delayTime = flightItem.movementType === MovementType.D ? formatTime(new Date(flightItem.estimatedTime)) : formatTime(new Date(flightItem.estimatedArrivalTime));
    
    return (
        <div className="timeContainer flex gap-2 items-center">
            <div className={"time scheduledTime " + (time === delayTime ? 'font-bold desk:text-base text-sm' : 'line-through text-lg')}>
                {time || '-'}
            </div>
            {delayTime !== time ? <div className="time estimatedTime desk:text-lg text-base font-bold">
                {delayTime || '-'}
            </div> : null}
        </div>
    )
}

interface IFlightChipProps {
    lang: string,
    flightItem: IFlightItem
    bgChip?: string
    crysisLbl?: string
}

const StateChip: React.FC<IFlightChipProps> = ({ lang, flightItem, bgChip = "bg-lightblue", crysisLbl }: IFlightChipProps) => {

    return (
        getFlightStatus(flightItem, lang) ?
            <div className={`${bgChip} px-2 py-1 rounded-lg w-fit mr-auto whitespace-nowrap`}>
                <div className="font-bold text-sm text-white">
                    {crysisLbl || getFlightStatus(flightItem, lang) || null}
                </div>
            </div>
        : null
    )
}


export interface IFlightItem {
    flightId: string;
    flightNumber: string;
    airlineIata: string;
    dateFrom: string;
    dateTo: string;
    airlineDescription: string;
    frequency: string;
    bon?: null;
    tpc: string;
    ttc: string;
    pier: string;
    transitPier?: null;
    status: string;
    statusOp?: null;
    statusPubblic: string;
    statusPubblicDescription: string;
    boardingDate: string;
    closedDate?: null;
    paxFemale: string;
    paxChild: string;
    paxInfants: string;
    paxMale: string;
    stx: string;
    baggageClaim?: null;
    checkIn: string;
    gate: string;
    notes?: null;
    stand: string;
    mfFlightType?: null;
    areaType: string;
    aircraftType: string;
    aircraftRegistration: string;
    movementType: string;
    loadingType: string;
    loadingTypeDescription: string;
    trafficType: string;
    trafficTypeDescription: string;
    serviceType: string;
    serviceTypeDescription: string;
    flightQual: string;
    originFlightDate: string;
    actualTime?: null;
    scheduledTime: string;
    estimatedTime: string;
    actualArrivalTime?: null;
    scheduledArrivalTime: string;
    estimatedArrivalTime: string;
    originUe: string;
    originSchengen: string;
    destinationUe: string;
    destinationSchengen: string;
    logo?: null;
    routing: (RoutingEntity)[];
    codeShare: (CodeShareEntity)[];
    statusPublic: string;
}

export interface RoutingEntity {
    airportIata: string;
    airportDescription: string;
    airportLabel: string;
    terminal?: string | null;
}

export interface CodeShareEntity {
    codeShareIata: string;
    codeShareDescription: string;
    codeShareFlightNumber: string;
}

export default FlightRow;
