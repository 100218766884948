import { useEffect, MutableRefObject } from "react";


function useOutsideClick(ref: MutableRefObject<HTMLDivElement>, callbackFn: () => void | (() => any)) {

  useEffect(() => {
    
    function handleClickOutside(event: MouseEvent) {
      if(ref?.current && !ref.current.contains(event.target as Node)) {
        callbackFn();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideClick;