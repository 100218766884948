interface CloseIconProps {
  onClick: () => void;
}

export const CloseIcon = ({ onClick }: CloseIconProps) => {
  return (
    <div
      onClick={onClick}
      className="absolute top-[-6px] right-[-6px] bg-black text-white rounded-full cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
  );
};
