import { FLIGHT_LIST_PAGE_URLS, MovementType, flightPanelInitialValues } from "@/config/flightPages";
import { useEffect, useState } from "react";
import { Button, CustomPicker, CustomTimePicker, TerminalSelector } from "..";
import { useSetRecoilState } from "recoil";
import { FlightListAtom, IsSearchingAtom, LastFlightListCallAtom, PreviousFlightListAtom } from "@/atoms";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { add, endOfDay, isToday } from "date-fns";

import Livesearch from "../Livesearch/Livesearch";
import formatDate from "@/utils/formatDate";
import formatDateFlight from "@/utils/formatDateFlight";

interface IFlightsFilterPanelProps {
    hostPageType: string,
    language: string
}

const FlightsFilterPanel: React.FC<IFlightsFilterPanelProps> = ({ hostPageType, language }: IFlightsFilterPanelProps) => {

    const router = useRouter();
    const [liveSearchResults, setLiveSearchResults] = useState<any[]>([]);
    const [selectedElement, setSelectedElement] = useState<any>(null);
    const [form, setForm] = useState({ ...flightPanelInitialValues, dateFrom: router.query['dateFrom'] ? new Date(router.query['dateFrom'] as string) : new Date(), movementType: hostPageType.includes('ricerca_volo_partenze') ? 'D' : (hostPageType.includes('ricerca_volo_arrivi') ? 'A' : 'notDefined')});
    const setIsSearching = useSetRecoilState(IsSearchingAtom)
    const { i18n, t } = useTranslation();
    const lang = language ? language : i18n.language;
    const setPreviousFlightList = useSetRecoilState(PreviousFlightListAtom)
    const setFlightList = useSetRecoilState(FlightListAtom);
    const setLastFlightListCall = useSetRecoilState(LastFlightListCallAtom);
    

    function setNewTime(newTime: Date) {

        let newDate = new Date(form.dateFrom);
        newDate.setHours(newTime.getHours(), newTime.getMinutes(), newTime.getSeconds());
        setForm({ ...form, dateFrom: newDate });
    }

    function setNewDate(newDate: Date) {

        let updatedDate = new Date(form.dateFrom);
        updatedDate.setMonth(newDate.getMonth());
        updatedDate.setDate(newDate.getDate());

        if (isToday(newDate)) {

            let now = new Date();
            updatedDate.setHours(now.getHours());
            updatedDate.setMinutes(now.getMinutes());
        } 

        setForm({ ...form, dateFrom: updatedDate });
    }

    function setSearchData(newValue: string) {

        if (selectedElement) {

            setSelectedElement(null);
        }

        setForm({ ...form, searchText: newValue });
    }

    async function getFlights() {

        let queryParams = getQueryParamsFromState();
        return await fetch(`${process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH}/${lang}/operative/flights/lists${queryParams ? ('?' + queryParams.toString()) : ''}`, { headers: { 'Content-Type': 'application/json', 'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string } });
    }

    function isPreviousFlightList(flight: any): boolean {

        let flightDate = new Date();
        if (flight.movementType == 'D') {

            flightDate = new Date(flight.scheduledTime);
        } else if (flight.movementType == 'A') {

            flightDate = new Date(flight.scheduledArrivalTime);
        }

        let formattedFlightDate = flightDate.getDate() * 24 * 60 * 60 * 1000
        let formattedFlightTime = flightDate.getHours() * 60 * 60 * 1000 + flightDate.getMinutes() * 60 * 1000

        let formattedFormDate = form.dateFrom.getDate() * 24 * 60 * 60 * 1000
        let formattedFormTime = form.dateFrom.getHours() * 60 * 60 * 1000 + form.dateFrom.getMinutes() * 60 * 1000

        if (formattedFlightDate <= formattedFormDate) return formattedFlightTime < formattedFormTime
        else return false
    }

    function getQueryParamsFromState() {

        let previousDate = new Date(form.dateFrom);
        if (isToday(previousDate)) previousDate.setHours(previousDate.getHours() - 1);

        let paramsObj: any = {
            movementType: hostPageType.includes('ricerca_volo_partenze') ? 'D' : (hostPageType.includes('ricerca_volo_arrivi') ? 'A' : 'notDefined'),
            dateFrom: formatDateFlight(previousDate),
            dateTo: formatDate(endOfDay(previousDate)),
            loadingType: flightPanelInitialValues.loadingType,
            airportReferenceIata: process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE || '',
            mfFlightType: 'P'
        };

        if (selectedElement) {

            selectedElement?.type === 'destination' ? form.airportDestinationIata = selectedElement.data.airportIata : null;
            selectedElement?.type === 'airline' ? form.airlineDescription = selectedElement.data.airlineDescription : null;

            if (selectedElement?.type === 'destination') {

                paramsObj = { ...paramsObj, searchText: selectedElement.data.airportLabel };
                paramsObj = { ...paramsObj, airportDestinationIata: form.airportDestinationIata };
            }

            if (selectedElement?.type === 'airline') {

                paramsObj = { ...paramsObj, searchText: selectedElement.data.airlineDescription };
                paramsObj = { ...paramsObj, airlineIata: selectedElement.data.airlineIata };
            }
        }

        if (process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' && form.terminal !== 'all') {

            paramsObj = { ...paramsObj, terminal: form.terminal }
        }

        let queryParams = new URLSearchParams(paramsObj);

        return queryParams;
    }

    useEffect(() => {

        if (selectedElement && ((router.query['airportDestinationIata'] && selectedElement.data.airportIata == router.query['airportDestinationIata']) || (router.query['airlineIata'] && selectedElement.data.airlineIata == router.query['airlineIata']))) {

            getFlights()
                .then(response => response.json())
                .then((response) => {
                    
                    //NOTE: Filtriamo prendendo solo i voli di tipo L e C. Escludiamo così i tipi F.
                    let responseFiltered = response.data.filter((item: any) => item.serviceType == 'L' || item.serviceType == 'C');
                    setPreviousFlightList(responseFiltered.filter((flight: any) => isPreviousFlightList(flight)));
                    setFlightList(responseFiltered.filter((flight: any) => !isPreviousFlightList(flight)))
                    setLastFlightListCall(new Date());
                })
        }

    }), [selectedElement]

    useEffect(() => {

        let filteredQueryObjectKeys = Object.keys(router.query).filter(key => key !== 'paths' && key !== 'lang');

        if (!filteredQueryObjectKeys.length) {

            // router.push({
            //     pathname: router.asPath,
            //     query: getQueryParamsFromState().toString()
            // });
        }

        let newFormValues = { ...form };

        filteredQueryObjectKeys.forEach(key => {

            let date = null;

            if (key === 'dateTo' || key === 'dateFrom') {

                if (isNaN(Date.parse(router.query[key] as string as string))) {

                    date = new Date(router.query[key] as string + ' ' + new Date().getHours() + ':' + new Date().getMinutes());
                } else {

                    date = new Date(router.query[key] as string);
                }
            }

            if (key === 'terminal') {

                form.terminal = router.query[key] as string;
            }

            if (key === 'searchText') {

                form.searchText = router.query[key] as string;
            }

            newFormValues = {
                ...newFormValues,
                [key]: date ? new Date(router.query[key] as string) : router.query[key],
            };
        })

        if (filteredQueryObjectKeys.length) {

            setForm(newFormValues);
        }

        if (router.query.airportDestinationIata) {

            form.airportDestinationIata = router.query.airportDestinationIata as string;
        }

        getFlights()
            .then(response => response.json())
            .then((response) => {

                if (router.query['airportDestinationIata'] == undefined && router.query['airlineIata'] == undefined) {

                    //NOTE: Filtriamo prendendo solo i voli di tipo L e C. Escludiamo così i tipi F.
                    let responseFiltered = response.data.filter((item: any) => item.serviceType == 'L' || item.serviceType == 'C');
                    setPreviousFlightList(responseFiltered.filter((flight: any) => isPreviousFlightList(flight)));
                    setFlightList(responseFiltered.filter((flight: any) => !isPreviousFlightList(flight)))
                    setLastFlightListCall(new Date());
                }
            })
            .catch(error => {

                setPreviousFlightList([]);
                setFlightList([]);
                setLastFlightListCall(new Date());
                console.error('[FlightsFilterPanel] error -> ', error);
            });

    }, [router.query, hostPageType])

    const filterPassedTime = (time: any) => {

        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleSearchBtn = () => {

        if (selectedElement?.type == 'flight') {

            window.location.href = router.basePath + FLIGHT_LIST_PAGE_URLS[form.movementType][lang] + '?' + getQueryParamsFromState().toString();
        } else {

            getFlights()
                .then(response => response.json())
                .then((response) => {

                    //NOTE: Filtriamo prendendo solo i voli di tipo L e C. Escludiamo così i tipi F.
                    let responseFiltered = response.data.filter((item: any) => item.serviceType == 'L' || item.serviceType == 'C');
                    setPreviousFlightList(responseFiltered.filter((flight: any) => isPreviousFlightList(flight)));
                    setFlightList(responseFiltered.filter((flight: any) => !isPreviousFlightList(flight)))
                    setLastFlightListCall(new Date());
                    setIsSearching(false)
                })
                .catch(error => {

                    setPreviousFlightList([]);
                    setFlightList([]);
                    setLastFlightListCall(new Date());
                    setIsSearching(false)
                    console.error('[FlightsFilterPanel] error -> ', error);
                });
        }
    };

    return (
        <div className="text-black flex bg-lightgrey rounded-2xl w-full px-6 py-8 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center shadow-xl">
            <div className="flex desk:justify-center w-full desk:items-center gap-4 desk:flex-row flex-col items-start justify-center">
                <div className="customPickerWrapper w-full flex desk:w-fit gap-1">
                    <CustomPicker minDate={new Date()} maxDate={add(new Date(), { days: 4 })} className="customPicker" selected={form.dateFrom} onChange={(date: Date) => setNewDate(date)} />
                    <CustomTimePicker filterTime={filterPassedTime} selected={form.dateFrom} className="customTimePicker " onChange={(newTime: Date) => setNewTime(newTime)} />
                </div>
                <Livesearch selectedElement={selectedElement} setSelectedElement={setSelectedElement} terminal={form.terminal && form.terminal !== 'all' ? form.terminal : undefined} filterDate={form.movementType === MovementType.D ? formatDate(form.dateFrom) : formatDate(form.dateFrom)} searchResults={liveSearchResults} setSearchResults={(newValue: any[]) => setLiveSearchResults(newValue)} searchInput={form.searchText} setSearchInput={(newValue: string) => setSearchData(newValue)} placeholder={t('flights.livesearchPlaceholder') as string} movementType={form.movementType as 'A' | 'D'} />
                {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ? <TerminalSelector selectedId={form.terminal} setId={(newId) => setForm({ ...form, terminal: newId })} /> : null}
                <Button className="active:bg-lightblue self-center" text={t('flights.searchFlights')} onClick={() => { setIsSearching(true); handleSearchBtn() }} />
            </div>
        </div>
    )

}

export default FlightsFilterPanel;
