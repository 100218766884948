import { useState } from "react";
import { IInfoCardProps } from "./ICard";
import { useTranslation } from "react-i18next";

import DOMPurify from "isomorphic-dompurify";
import CustomIcon from "../CustomIcon/CustomIcon";

interface IInfoAccordionProps extends IInfoCardProps {
    openByDefault?: boolean,
    language: string
}

const InfoAccordionCard: React.FC<IInfoAccordionProps> = ({ title, imageUrl, infoItems, openByDefault = false, language, bgColor, description }: IInfoAccordionProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;

    const [isOpen, setIsOpen] = useState(openByDefault);

    const toggleOpening = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="accordionWrapper">
            {/* <div onClick={() => toggleOpening()} className="flex mx-auto gap-4 cursor-pointer bg-lightgrey rounded-2xl max-w-xs p-6">
            <div className="flex items-center">
                    <div className="flex justify-center gap-4 items-center">
                        <div style={{width: '27px', height: '35px'}}>
                            <img src={imageUrl} />
                        </div>
                        <div className='text-lg font-bold'>{title}</div>
                    </div>
                    {!isOpen ? <CustomIcon className="ml-4 h-5 w-5" iconName="arrow" /> : null}
                </div>
            </div>
            {isOpen ? <div className="flex flex-col gap-4 bg-white items-center py-4">
                <div className="w-2 h-2 bg-darkgrey rounded-full" style={{boxShadow: '2px 5px 38px -18px #000000'}}></div>
                <div className="w-2 h-2 bg-darkgrey rounded-full" style={{boxShadow: '2px 5px 38px -18px #000000'}}></div>
                <div className="w-2 h-2 bg-darkgrey rounded-full" style={{boxShadow: '2px 5px 38px -18px #000000'}}></div>
            </div> : null} */}
            <div className={`flex flex-col mx-auto gap-2 ${bgColor} justify-center p-6 rounded-2xl items-center`}>
                <div onClick={() => toggleOpening()} className="flex justify-between items-center mb-2 w-full">
                    <div className="flex justify-center gap-4 items-center">
                        <div style={{ width: '27px', height: '35px' }}>
                            <img src={imageUrl} />
                        </div>
                        <h2 className='text-xl font-bold'>{title}</h2>
                    </div>
                    <CustomIcon className={"ml-4 h-5 w-5 cursor-pointer transition duration-300" + (isOpen ? ' rotate-90' : '')} iconName="arrow" />
                </div>
                {isOpen ? <div className="w-full px-2">
                    {infoItems?.map(infoItem => {

                        const lblSubtitleInfo = DOMPurify.sanitize(infoItem.lblSubtitleInfo[lang]);

                        return <a key={infoItem.id} target={infoItem.flgTargetCtaInfo ? '_blank' : '_self'} href={infoItem.blLinkCtaInfo[lang]}>
                            <div className='cursor-pointer my-3 text-lg'>
                                <h3 dangerouslySetInnerHTML={{ __html: lblSubtitleInfo }} />
                            </div>
                        </a>
                    })}
                    {
                        description ?
                        <div className='my-3 text-lg'>
                            <h3>{description}</h3>
                        </div>
                        :
                        <></>
                    }
                </div> : null}
            </div>
        </div>
    )
}

export default InfoAccordionCard;
