const trackingResponseCode: TrackingErrorType = {
    CODE_200: "200",
    CODE_401: "401",
    CODE_403: "403",
    CODE_404: "404",
    CODE_500: "500",
    MESSAGE_CODE_500_FLIGHT_NOT_TRACEABLE: "Flight Not Traceable",
    MESSAGE_CODE_500_FLIGHT_ALREADY_SUBSCRIBED: "Flight already subscribed",
    MESSAGE_CODE_500_MAX_FLIGHTS_SUBSCRIBED: "Max flights subscribed"
};

interface TrackingErrorType {
    [key: string]: string
}

export default trackingResponseCode;