import { IFilterDTO } from "@/interfaces/IModuleFourDTO";
import { useIsMobile } from "@/hooks";
import { LanguageAtom } from "@/atoms";
import { useRecoilValue } from "recoil";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import CustomIcon from "../CustomIcon/CustomIcon";

interface ICardListProps {
    cards: JSX.Element[];
    filterTitle: string;
    filters: IFilterDTO[];
    title: { [key: string]: string };
    onClickFilter: Dispatch<SetStateAction<string | null>>;
    currentySettedFilter: string | null;
}

enum TravelCategoryType {
    'viaggio_natura' = "nature",
    'viaggio_cultura' = "culture",
    'viaggio_mare' = "sea",
    'viaggio_citta' = "city"
}

const CardList: React.FC<ICardListProps> = ({ cards, filterTitle, filters, title, onClickFilter, currentySettedFilter }: ICardListProps) => {

    const [isMobile] = useIsMobile();
    const lang = useRecoilValue(LanguageAtom);
    const { t } = useTranslation();

    return (
        <div className="py-16">
            <div className={`filterWrapper gap-10 flex items-center mb-4 ${isMobile && "justify-center"}`}>
                {!isMobile &&
                    <div className="filterTitle text-xl font-bold">
                        {filterTitle}
                    </div>}
                <div className="filterContainer flex desk:gap-4 gap-8">
                    {filters.map((filterItem, index) => {
                        let imagePath = filterItem.upiLogoDesktop?.mediaserverpath + "/" + filterItem.upiLogoDesktop?.resourcename
                        //let imagePath = isMobile ? (filterItem.upiLogoMobile?.mediaserverpath && filterItem.upiLogoMobile?.resourcename ? filterItem.upiLogoMobile.mediaserverpath + "/" + filterItem.upiLogoMobile.resourcename : '') : (filterItem.upiLogoDesk?.mediaserverpath && filterItem.upiLogoDesk?.resourcename ? filterItem.upiLogoDesk.mediaserverpath + "/" + filterItem.upiLogoDesk.resourcename : '')
                        /* return <Link href={filterItem.lblLink[lang]}><div className="filterItem flex flex-col justify-center items-center">
                            <div className="p-2 bg-lightgrey w-fit rounded-2xl">
                                <img src={imagePath} />
                            </div>
                            <div>{filterItem.lblCardCategory}</div>
                        </div></Link> */
                        return (
                            <div key={index} className={`filterItem flex flex-col justify-center items-center cursor-pointer relative `}>
                                {currentySettedFilter && currentySettedFilter === filterItem.lblCardCategory ? <div onClick={() => { onClickFilter(null) }} className="absolute cursor-pointer h-6 w-6 bg-black -top-2 -right-2 flex justify-center items-center rounded-full text-white z-10"><CustomIcon iconName="close" color="white" /></div> : null}
                                <div onClick={() => onClickFilter(filterItem.lblCardCategory)} className="gap-0.5 flex flex-col justify-center items-center">
                                    <div className={`p-2 hover:shadow-lg ${currentySettedFilter && currentySettedFilter === filterItem.lblCardCategory ? 'bg-lightblue' : 'bg-lightgrey h-[50px] w-[50px] flex items-center justify-center'} w-fit rounded-2xl`}>
                                        <img src={imagePath} className={currentySettedFilter && currentySettedFilter === filterItem.lblCardCategory ? 'brightness-[3]' : ''} />
                                    </div>
                                    <div>{t(`travel_inspiration.${TravelCategoryType[filterItem.lblCardCategory as keyof typeof TravelCategoryType]}`)}</div>
                                </div>
                            </div>)
                    })}
                </div>
            </div>
            <div className="cardListWrapper flex flex-col gap-4">
                <div className="font-bold text-xl">{title && title[lang] ? title[lang] : ''}</div>
                <div className="cardList grid desk:grid-cols-4 desk:gap-x-4 gap-5">
                    {cards}
                </div>
            </div>
        </div>
    )
}

export default CardList;
