import { useEffect, useState } from "react";
import { TerminalSelector } from "..";
import { AreaType, TerminalNameType, TerminalType, servicePanelInitialValues } from "../../config/servicePages";
import { useRecoilState} from "recoil";
import { ServiceListAtom } from "@/atoms";
import { useTranslation } from "react-i18next";

import CustomIcon from "../CustomIcon/CustomIcon";
import ServiceSelector from "../ServiceSelector/ServiceSelector";

const ServicesFilterPanel: React.FC = () => {

    const [form, setForm] = useState(servicePanelInitialValues);
    const [serviceList, setServiceList] = useRecoilState(ServiceListAtom)

    async function getServices() {
        return await fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/card?query=enuTypeInfo='servizio'` +
            `${form.category ? `%20AND%20xconServiceRegistry.xtaxServiceCategory.ti.label=%27${form.category}%27` : ''}` +
            `${form.area ? form.area : ''}` +
            `${form.terminal ? form.terminal : ''}`)
    }

    useEffect(() => {
        getServices()
            .then(response => response.json())
            .then(data => {
                const filteredData = data?.data.filter((item: any) => {
                    return item?.xconServiceRegistry?.xtaxAirports?.ti.some((airport: any) => airport?.label === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE)
                })
                return filteredData;
            })
            .then(rowList => setServiceList(rowList.filter((item: any) => item !== null && item !== undefined)));
    }, [form])

    return (
        // <div className="flex desk:flex-row flex-col bg-lightgrey rounded-2xl px-14 py-8 items-center text-black justify-between">
        <div className="bg-lightgrey flex rounded-2xl px-3 py-5 desk:p-10 flex-col items-start gap-4 desk:flex-row desk:items-center">

            <CustomCategoryPicker setCategory={(newId: any) => setForm({ ...form, category: newId })} />
            <CustomAreaPicker setArea={(newId: any) => setForm({ ...form, area: newId })} />
            {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === "mxp" ?
                <TerminalSelector selectedId={TerminalNameType[form.terminal as keyof typeof TerminalNameType]} setId={(newId) => { setForm({ ...form, terminal: TerminalType[newId.toUpperCase() as keyof typeof TerminalType] }), console.log(TerminalNameType[form.terminal as keyof typeof TerminalNameType]) }} />
                : null}

        </div>
    )
}

const CustomCategoryPicker: React.FC<any> = ({ className, setCategory, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation()
    const [value, setValue] = useState()
    return (
        <div className="w-full">
            <div onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
                className="">
                <div className="flex justify-between bg-white py-3 px-6 h-full cursor-pointer rounded-2xl">
                    <div className="">{value ? value : t('service.serviceCategory')}</div>
                    <CustomIcon className={"w-2 transition-transform origin-center duration-300 " + (isOpen ? 'rotate-90' : 'rotate-0')} iconName="arrow" />
                </div>
                <div style={{ boxShadow: '5px 6px 10px -5px #000000' }} className={`bg-white rounded-2xl mt-2 absolute z-[1] w-[inherit] ${isOpen ? "" : "hidden"}`}>
                    <ServiceSelector setValue={(value: any) => setValue(value)} setCategory={(item: any) => setCategory(item)} />
                </div>
            </div>
        </div>
    )
}

const CustomAreaPicker: React.FC<any> = ({ className, selected, setArea, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<any>("Area")
    const { t } = useTranslation()


    return (
        <div className="w-full">
            <div onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
                className="">
                <div className="flex justify-between bg-white py-3 px-6 h-full cursor-pointer rounded-2xl">
                    <div className="">{value}</div>
                    <CustomIcon className={"w-2 transition-transform origin-center duration-300 " + (isOpen ? 'rotate-90' : 'rotate-0')} iconName="arrow" />
                </div>
                <div style={{ boxShadow: '5px 6px 10px -5px #000000' }} className={`bg-white rounded-2xl mt-2 absolute w-[inherit] cursor-pointer ${isOpen ? "" : "hidden"}`}>
                    <div className="py-3 px-6 border-b-2 border-b-gray-150 text-gray-500"
                        onClick={() => { setValue("Area"), setArea(AreaType.ALL) }}>Area</div>
                    <div className="py-3 px-6 border-b-2 border-b-gray-150"
                        onClick={() => { setValue(t('service.beforeSecurityChecks')), setArea(AreaType.beforeSecurityChecks) }}>{t('service.beforeSecurityChecks')}</div>
                    <div className="py-3 px-6"
                        onClick={() => { setValue(t("service.afterSecurityChecks")), setArea(AreaType.afterSecurityChecks) }}>{t('service.afterSecurityChecks')}</div>
                </div>
            </div>
        </div >
    )
}

export default ServicesFilterPanel;
