import { useEffect, useState } from "react";

const faqUrl = "/rest/v1/SEA-LinMxp/contents/faq"
const newsUrl = "/rest/v1/SEA-LinMxp/contents/news"
const faqXtax = "xtaxFaqCategories.ti.label"
const newsXtax = "xtaxNewsCategory.ti.label"


function useCategory<T>(type: 'news' | 'faqs', newCategory?: string): T {

    const [data, setData] = useState<T>();
    const [category, setCategory] = useState(newCategory);

    const fetchData = () => {
        let endpoint = "";
        let xtax = "";
        let queryParams = new URLSearchParams();
        switch (type) {
            case 'news':
                endpoint = newsUrl;
                xtax = newsXtax;
                queryParams.set('xtaxAirports.ti.label', `'${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}'` as string);
                break;
            case 'faqs':
                endpoint = faqUrl;
                xtax = faqXtax
                break;
        }

        if (category !== "TUTTE") queryParams.set(xtax, "'" + category + "'")
        else queryParams.set("enuPageCategory", "'News'")

        if (type == 'news') queryParams.set('sort', 'dteNews:DESC');

        let queryParamsStr = queryParams.toString().replace('&', '+AND+');

        fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH + endpoint}${category ? "?query=" + queryParamsStr : ''}`)
            .then(respnse => respnse.json())
            .then(data => setData(data))
    }

    useEffect(() => fetchData(), [category])

    const updateCategoryBtn = (newCategory: string) => {
        setCategory(newCategory)
    }

    return [data, updateCategoryBtn] as T;
}

export default useCategory;
