import { IInfoBox } from "../../interfaces/IParagraph";

interface InfoBoxProps {
    lstInfoBox: IInfoBox[],
    lang: string,
    bgColor: string,
    voiceColor: string,
}


const InfoBox: React.FC<InfoBoxProps> = ({ lstInfoBox, lang, bgColor, voiceColor }: InfoBoxProps) => {
    return (
        <div className={`p-10 rounded-3xl w-auto ${bgColor}`}>
            <ul className="flex flex-col gap-2">
                {lstInfoBox.map((item) =>
                    <li key={item.id} className="">
                        <span className={`font-bold ${voiceColor}`}>
                            {item.lblVoice[lang].toUpperCase() + " "}
                        </span>
                        <span className="text-black">
                            {" " + item.lblValue[lang]}
                        </span>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default InfoBox;
