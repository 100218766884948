import { useEffect, useState } from "react";
import { FreeMode } from "swiper";
import { IPopupDTO } from "@/interfaces/IPopupDTO";
import { configColor } from "@/utils";
import { useIsMobile } from "@/hooks";
import { IParkingListApiResponse } from "@/interfaces/IParkingListDTO";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../CloseIcon/CloseIcon";

import DOMPurify from 'isomorphic-dompurify';
import CustomIcon from "../CustomIcon/CustomIcon";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";

interface IParkingListProps {
  data: IParkingListApiResponse,
  language: string
}

interface ProgressProps {
  lenghtlist: number
  lenghtBar: number
}


const ParkingList: React.FC<IParkingListProps> = ({ data, language }: IParkingListProps) => {

  const [isMobile, isTablet, isDesktop] = useIsMobile();
  const moduleData = data;

  const { t, i18n } = useTranslation();
  const lang = language? language : i18n.language;

  const [_, setActiveIndex] = useState(0)
  const [isMap, setIsMap] = useState(false)
  const [isOpenPopup, setisOpenPopup] = useState(false)
  const [popupData, setPopupData] = useState<IPopupDTO | null>()
  const [mapDataT1, setMapDataT1] = useState()
  const [mapDataT2, setMapDataT2] = useState()
  const [m11Data, setM11Data] = useState()
  const [iFrameTerminal, setiFrameTerminal] = useState('T1')
  const [selectedTab, setSelectedTab] = useState("P")
  const upiImageDesktopMap = moduleData?.data.upiImageDesktopMap[lang].mediaserverpath + '/' + moduleData?.data.upiImageDesktopMap[lang].resourcename
  const upiImageMobileMap = moduleData?.data.upiImageMobileMap[lang].mediaserverpath + '/' + moduleData?.data.upiImageMobileMap[lang].resourcename
  const txbSubtitleMap = DOMPurify.sanitize(moduleData.data.txbSubtitleMap[lang])

  const lblTitle = moduleData?.data.lblTitle[lang]
  const txbSubtitle = moduleData?.data.txbSubtitle[lang]
  const lenghtParkList = moduleData?.data?.lstParking.length
  const colorLine = configColor('border', 'azzurro')
  const upiImageDeskPopup = popupData?.upiImageDesk.mediaserverpath + '/' + popupData?.upiImageDesk.resourcename
  const upiImageMobPopup = popupData?.upiImageMobile.mediaserverpath + '/' + popupData?.upiImageMobile.resourcename
  const lblLinkPopup = popupData?.lblLink[lang]
  const lblTitlePopup = popupData?.lblTitle[lang]
  const flgTargetPopup = popupData?.flgTarget ? '_blank' : '_self'
  const lblLabelCtaPopup = popupData?.lblLabelCta[lang]

  const iFrameLink = `/webmaps/neoMap.jsp?lang=it&target=${iFrameTerminal}&fullscreenfun=fullscreenMap()`;

  const getPopupData = (targetUrl: string) => {
    fetch(targetUrl).then(res => res.json()).then(data => { setPopupData(data.data) });
  }

  const getMapData = () => {
    fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m09/855`).then(res => res.json()).then(data => { setMapDataT1(data.data) });
    fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m09/857`).then(res => res.json()).then(data => { setMapDataT2(data.data) });
    fetch(`${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m11/859`).then(res => res.json()).then(data => { setM11Data(data.data) });
  }

  const openPopup = (targetUrl: string) => { setisOpenPopup(true); getPopupData(targetUrl) }
  const closePopup = () => { setisOpenPopup(false); setPopupData(null) }

  useEffect(()=>{
    getMapData();
  },[])

  return (
    <>
      <div className="relative pt-8 desk:py-12 max-w-cont-2 mx-auto w-full">

        <div className={`pb-4 px-6`} >
          <h1 className={`font-semibold text-xl desk:text-3xl`}>{lblTitle}</h1>
          <h2 className={`font-normal text-md`}>{txbSubtitle}</h2>
        </div>

        <div className="flex gap-5 cursor-pointer pb-2 px-6">
          <div className={`hover:border-b-2 border-b-[#6ABACE] hover:text-[#6ABACE]`} onClick={() => setSelectedTab("P")}>
            <h1 className={`${selectedTab === "P" ? "border-b-4 border-b-[#6ABACE]" : "" } font-semibold text-xl`}>{t("parking.list")}</h1>
          </div>
          <div className={`hover:border-b-2 border-b-[#6ABACE] hover:text-[#6ABACE]`} onClick={() => setSelectedTab("M")}>
          <h1 className={`${selectedTab === "M" ? "border-b-4 border-b-[#6ABACE]" : "" } font-semibold text-xl`}>{t("parking.map")}</h1>
          </div>
        </div>

        {moduleData?.data.enuTypeTemplate.toLowerCase() === 'elenco' ? <>

        { selectedTab === "P" ? 
          <>
          {/* DESKTOP */}
          {isDesktop && !isMap && moduleData?.data?.lstParking?.map((item) => {

            const lblParkingClassification = DOMPurify.sanitize(item.lblParkingClassification[lang]);
            const lblInformationIconText = item.lblInformationIconText[lang]
            const lblIconText = DOMPurify.sanitize(item.lblIconText[lang]);
            const upiImageDesktop = item.upiImageDesktop[lang].mediaserverpath + '/' + item.upiImageDesktop[lang].resourcename
            const lblLabelCta = item.lblLabelCta[lang]
            const enuStatus = lang == 'it' ? item.enuStatus: item.enuStatusEn
            const lblTerminalIndication = item.lblTerminalIndication[lang]
            const lblTag = item.lblTag[lang]
            const lblLink = item.lblLink[lang]
            const flgTarget = item.flgTarget ? '_blank' : '_self'
            const flgActivateIcon = item.flgActivateIcon ? 'block' : 'hidden'
            const urlPopup = item.xconPopup.targeturl

            return (
              <div key={item.id} className="relative pt-2">
                <div className={`bg-red text-[#FFFFFF] w-fit px-5 py-1 rounded-bl-2xl rounded-tr-2xl font-bold text-sm absolute top-0 right-0 ${lblTag ? 'block' : 'hidden'}`}>{lblTag}</div>

                <div className="shadow-lg hover:shadow-2xl pl-10 p-5 rounded-2xl mb-5 grid grid-cols-12 items-center select-none">

                  <div className="col-span-4 flex flex-col justify-start">
                    <p dangerouslySetInnerHTML={{ __html: lblParkingClassification }} className="font-bold text-2xl mb-3" />
                    <div className="flex gap-3 cursor-pointer w-fit">
                      <CustomIcon iconName="info" className={`w-5 ${flgActivateIcon}`} />
                      <h3 onClick={() => { openPopup(urlPopup) }} className="underline underline-offset-2 text-sm">{lblInformationIconText}</h3>
                    </div>
                  </div>

                  <div className="col-span-2 align-middle text-center flex flex-col justify-center">
                    <img src={upiImageDesktop} alt="shortIconDesktop" className="m-auto" />
                    <p dangerouslySetInnerHTML={{ __html: lblIconText }} className="text-sm" />
                  </div>

                  <div className="col-span-2 flex justify-center">
                    <div className="bg-lightblue w-fit p-2 rounded-lg text-[#FFFFFF] text-sm font-bold">
                      {enuStatus}
                    </div>
                  </div>

                  <div className="col-span-2 flex justify-center">
                    <h3 className="bg-lightblue w-fit p-2 rounded-lg text-[#FFFFFF] text-sm font-bold">
                      {lblTerminalIndication}
                    </h3>
                  </div>

                  <a href={lblLink} target={flgTarget} className="col-span-2 flex justify-center cursor-pointer">
                    <p dangerouslySetInnerHTML={{ __html: lblLabelCta.toUpperCase() }} className="font-bold mr-4" />
                    <CustomIcon iconName="arrow" className="w-3" />
                  </a>

                </div>
              </div>
            )
          })}

          {/* MOBILE */}
          {!isDesktop && !isMap &&
            <Swiper
              spaceBetween={10}
              slidesPerView={isMobile ? 1 : 2}
              onRealIndexChange={newInstance => setActiveIndex(newInstance.realIndex)}
              modules={[FreeMode]}
              freeMode={true}
              className='pr-10 pl-5 py-5'
            >
              {moduleData?.data.lstParking?.map((item) => {

                const lblTag = item.lblTag[lang];
                const lblLink = item.lblLink[lang]
                const flgTarget = item.flgTarget ? '_blank' : '_self'
                const lblParkingClassification = DOMPurify.sanitize(item.lblParkingClassification[lang]);
                const upiImageMobile = item.upiImageMobile[lang].mediaserverpath + '/' + item.upiImageMobile[lang].resourcename;
                const lblIconText = DOMPurify.sanitize(item.lblIconText[lang]);
                const enuStatus = item.enuStatus
                const lblTerminalIndication = item.lblTerminalIndication[lang]
                const lblInformationIconText = item.lblInformationIconText[lang]
                const urlPopup = item.xconPopup.targeturl

                return (
                  <SwiperSlide key={item.id} className="rounded-2xl relative p-5 shadow-lg h-auto text-[#000000]">
                    <div className={`absolute top-0 right-0 bg-red px-5 py-1 rounded-bl-2xl rounded-tr-2xl font-bold text-sm text-[#FFFFFF] ${lblTag ? 'block' : 'hidden'}`}>{lblTag}</div>

                    <a href={lblLink} target={flgTarget} className="cursor-pointer flex justify-between mb-10">
                      <p dangerouslySetInnerHTML={{ __html: lblParkingClassification }} className="font-bold text-xl py-2" />
                      <CustomIcon iconName="arrow" className="w-3" />
                    </a>

                    <div className="flex justify-start gap-2 mb-3">
                      <img src={upiImageMobile} alt="upiImageMobile" className="w-fit" />
                      <p dangerouslySetInnerHTML={{ __html: lblIconText }} className="text-sm font-bold" />
                    </div>

                    <div className="bg-lightblue w-fit py-1 px-4 rounded-lg text-[#FFFFFF] text-sm font-bold mb-3">
                      {enuStatus}
                    </div>
                    <h3 className="bg-lightblue w-fit py-1 px-4 rounded-lg text-[#FFFFFF] text-sm font-bold mb-12">
                      {lblTerminalIndication}
                    </h3>

                    <div className="group flex gap-2 cursor-pointer">
                      <CustomIcon iconName="info" className="w-5" />
                      <h3 onClick={() => { openPopup(urlPopup) }} className="underline underline-offset-2 text-sm">{lblInformationIconText}</h3>
                    </div>
                  </SwiperSlide>
                )
              })}
          
              <div className="flex justify-center py-10">
                <div className="w-[90%]">
                  <Progress lenghtlist={lenghtParkList} lenghtBar={1} />
                </div>
              </div>
            </Swiper>
          }
          </>
        :
            <>
              <div className="desk:flex gap-5 text-[#000000] px-6 desk:px-0">
                <img src={isDesktop ? upiImageDesktopMap : upiImageMobileMap} alt="Map" className="rounded-2xl mb-5" />
        
                <div className="">
                  {moduleData?.data?.lstMap?.map((item: any) => {
        
                    const lblTerminal = item.lblTerminal[lang]
        
                    return (
                      <div key={item.id} className='mb-6 max-w-full'>
                        <p className="font-bold text-lg mb-3">{lblTerminal}</p>
                        {item.lstParkingsDetail.map((detail: any) => {
        
                          const lblParkingName = detail.lblParkingName[lang]
                          const lblDirections = detail.lblDirections[lang]
                          const lblLinkDirections = detail.lblLinkDirections[lang]
                          const flgTargetDirections = detail.flgTargetDirections ? '_blank' : '_self'
                          const parkingIcon = detail.lstIcon[0].upiImageDesktopIcon[lang].mediaserverpath + '/' + detail.lstIcon[0].upiImageDesktopIcon[lang].resourcename
        
                          return (
                            <div key={detail.id} className='mb-3'>
                              <div className="flex items-center">
                                <img src={parkingIcon} alt="parkingIcon" className="w-16" />
                                <div className="overflow-hidden w-full">
                                  <h3 className="font-bold text-sm">{lblParkingName}</h3>
                                  <div className="flex max-w-full items-center">
                                    <CustomIcon iconName="marker" className="w-5 h-10" />
                                    <a href={lblLinkDirections} target={flgTargetDirections} className="underline underline-offset-2 text-sm hover:text-lightblue active:text-lightblue">{lblDirections}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                  <p dangerouslySetInnerHTML={{ __html: txbSubtitleMap }} className="text-lg" />
                </div>
              </div>
            </>
        }  
        </>

          :

          <div>
            <div className="flex gap-12 font-bold text-lg select-none py-12 text-[#000000]">
              <div onClick={() => setiFrameTerminal('T1')} className='cursor-pointer'>
                <p className="px-5">Terminal 1</p>
                <div className={`border-2 ${colorLine} ${iFrameTerminal === 'T1' ? 'block' : 'hidden'}`}></div>
              </div>
              <div onClick={() => setiFrameTerminal('T2')} className='cursor-pointer'>
                <p className="px-5">Terminal 2</p>
                <div className={`border-2 ${colorLine} ${iFrameTerminal === 'T2' ? 'block' : 'hidden'}`}></div>
              </div>
            </div>
            <div>
              <iframe src={iFrameLink}></iframe>
            </div>
          </div>

        }


        {/* POPUP */}
        {isOpenPopup && popupData && <div className="select-none">
          <div className="bg-black opacity-50 fixed top-0 left-0 bottom-0 right-0 z-50"></div>
          <div className={` ${isMobile ? 'py-6' : ''} text-black bg-white w-[80%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[51] p-10 rounded-2xl desk:flex`}>
            <button
              className="bg-black p-0 rounded-3xl cursor-pointer w-5 absolute -top-1 right-0"
              onClick={closePopup}
            >
              <CloseIcon onClick={closePopup} />
            </button>
            {isDesktop ? <img src={isDesktop ? upiImageDeskPopup : upiImageMobPopup} alt="MapPopup" className="rounded-2xl" /> : null}
            <div className="w-full mb-5 desk:mb-0 desk:p-12">
              <p className="text-lg font-bold mb-5">{lblTitlePopup}</p>
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(popupData && popupData.txbDescription ? popupData.txbDescription[lang] : '') }} className="text-sm desk:text-base desk:mb-10" />
              <div className="flex items-center">
              { lblLabelCtaPopup
                ?
                <React.Fragment>
                  <CustomIcon iconName="marker" className="w-5" />
                  <a href={lblLinkPopup} target={flgTargetPopup} className="underline underline-offset-2 text-sm">{lblLabelCtaPopup}</a>
                </React.Fragment>
                :
                <React.Fragment></React.Fragment>
              }
              </div>
            </div>
            {!isDesktop ? <img src={isDesktop ? upiImageDeskPopup : upiImageMobPopup} alt="MapPopup" className="rounded-2xl" /> : null}
          </div>
        </div>}

      </div>
    </>
  );
};

const Progress: React.FC<ProgressProps> = ({ lenghtlist = 0, lenghtBar = 1 }: ProgressProps) => {
  const swiperRef = useSwiper();
  return (
    <ProgressBar completed={swiperRef.activeIndex + 2} maxCompleted={lenghtlist - lenghtBar} bgColor={'#000000'} baseBgColor={'#F2F2F2'} isLabelVisible={false} height={'5px'} />
  )
}

export default ParkingList;
