import React, { ReactElement, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Button } from "..";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { t } from "i18next";

import FlightByDestinationListAtom from "@/atoms/FlightByDestinationListAtom";
import AVAILABLE_CONTINENTS_IT from "@/config/continentsConfigIT";
import AVAILABLE_CONTINENTS_EN from "@/config/continentsConfigEN";
import DestinationPageAtom from "@/atoms/DestinationPageAtom";
import DestinationCityAtom from "@/atoms/DestinationCityAtom";
import styles from './DestinationFlightFilterPanel.module.css'
import FlightByDestinationListActiveAtom from "@/atoms/FlightByDestinationListActiveAtom";
import CategoryFilterAtom from "@/atoms/CategoruFilterAtom";
import AreaFilterAtom from "@/atoms/AreaFilterAtom";

interface ISelectOption {
  id: number | string;
  label: string;
}

interface IDestinationFlightFilterPanelProps {
  moduleData: any
}

const DestinationFlightFilterPanel: React.FC<IDestinationFlightFilterPanelProps> = ({ moduleData }: IDestinationFlightFilterPanelProps) => {

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [destinationList, setDestinationList] = useState<any[]>([]);
  const setFlightList = useSetRecoilState(FlightByDestinationListAtom);
  const setFlightListActive = useSetRecoilState(FlightByDestinationListActiveAtom);
  const setDestinatationModuleData = useSetRecoilState(DestinationPageAtom);

  useEffect(() => {

    setDestinatationModuleData(moduleData);
  }, [moduleData])

  const [selectedContinent, setSelectedContinent] = useState<string | null>(null);
  const [paging, setPaging] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useRecoilState<string | null>(DestinationCityAtom);

  const fetchByContinent = () => {

    var continents = lang == 'it' ? AVAILABLE_CONTINENTS_IT : AVAILABLE_CONTINENTS_EN;

    let continentToFetch = selectedContinent !== 'Europe' ? continents.find(item => item.label === selectedContinent)?.label : 'Europa';

    fetch(process.env.NEXT_PUBLIC_BE_BASE_PATH + `/rest/v1/SEA-LinMxp/contents/destinations?query=enuContinent=%27${continentToFetch}%27`).then(response => {

      return response.json();

    }).then(response => {

      setDestinationList(response.data);
      setPaging(response?.resultInfo?.paging?.next);

    })
  }

  const fetchByContinentDynamic = (url: string) => {

    fetch(url)
      .then(response => {

        return response.json();

      }).then(response => {

        let tmp = destinationList;

        response.data.forEach((item: any) => {

          tmp.push(item);
        });

        setDestinationList(tmp);
        setPaging(response?.resultInfo?.paging?.next);

      })
  }

  useEffect(() => {
    
    fetchByContinentDynamic(paging);
  }, [paging]);

  useEffect(() => {
    /* if (selectedContinent) */ fetchByContinent()
  }, [selectedContinent]);

  const countryOpts = useMemo(() => {

    let countryList: any = [];
    destinationList.forEach((destinationItem: any) => {

      if (!countryList.find((countryItem: any) => countryItem.label === destinationItem.lblNation[lang])) {
        countryList.push({ id: destinationItem.metainfo.id, label: destinationItem.lblNation[lang] })
      }
    })
    countryList.sort((a: any, b: any) => {
      var textA = a.label.toUpperCase()
      var textB = b.label.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })

    return countryList as ISelectOption[];
  }, [destinationList, lang])

  var cityOpts = useMemo(() => {
    return destinationList.filter(destinationItem => destinationItem.lblNation[lang] === selectedCountry)
      .sort((a: any, b: any) => {
        var textA = a.lblCityName[lang].toUpperCase()
        var textB = b.lblCityName[lang].toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
      .map((destinationItem, index) => {

        if ((process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE == 'mxp' && destinationItem.flgDeparturesFromMXP) || (process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE == 'lin' && destinationItem.flgDeparturesFromLIN)) {

          return <option key={index} value={destinationItem.lblAirportDestinationDescription}>{destinationItem.lblCityName[lang]}</option>
        } else {

          return <React.Fragment key={index}></React.Fragment>;
        }
      })
  }, [destinationList, selectedCountry, lang])

  const getFlightsByDestination = () => {
    fetch(process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH + `/it/scheduled/flights/lists?airportReferenceIata=${process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE}&loadingType=P&airportDestinationDescription=${selectedCity}`, {
      headers: {
        'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string
      }
    })
      .then(response => response.json())
      .then(response =>  {
        
        setFlightList(response.data);
        setIfActive();
    })
  }

  const setIfActive = () => {

    var item = destinationList.filter((item) => {
      return item.lblAirportDestinationDescription == selectedCity;
    })

    setFlightListActive(item[0].flgActivePartnership ? item[0].flgActivePartnership : false);
    
  }

  return (
    <div className="flex bg-lightgrey rounded-2xl w-full mx-auto px-6 py-8 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center desk:p-10">
      <div className="flex desk:justify-center w-full desk:items-center gap-4 desk:flex-row flex-col items-start justify-center">
        <Dropdown onChange={(e: any) => { setSelectedContinent(e.target.value) }} placeholder={t('destinations.selectContinent') as string} valueList={lang == 'it' ? AVAILABLE_CONTINENTS_IT : AVAILABLE_CONTINENTS_EN} />
        <Dropdown disabled={!(selectedContinent && countryOpts.length)} onChange={(e: any) => setSelectedCountry(e.target.value)} placeholder={t('destinations.selectNation') as string} valueList={countryOpts} />
        <Dropdown disabled={!(selectedCountry && cityOpts.length)} onChange={(e: any) => setSelectedCity(e.target.value)} placeholder={t('destinations.selectCity') as string} >{cityOpts}</Dropdown>
        <div className="flex justify-center w-full"><Button text={t('flights.searchFlights')} onClick={selectedCity && selectedCity.length ? () => getFlightsByDestination() : () => { }} className={`${!(selectedCity && selectedCity.length) ? "opacity-30 cursor-default pointer-events-none" : "active:bg-lightblue"}`} /></div>
      </div>
    </div>
  );
}

export default DestinationFlightFilterPanel;

interface IDropdownProps {
  placeholder?: string,
  valueList?: ISelectOption[],
  children?: ReactElement[]
  onChange?: (e: SyntheticEvent) => void,
  disabled?: boolean
}
export const Dropdown: React.FC<IDropdownProps> = ({ disabled = false, children = [], placeholder = 'Seleziona un opzione', onChange, valueList }: IDropdownProps) => {
  const [selectedArea, setSelectedArea] = useRecoilState(AreaFilterAtom);
  const [selectedCaregory, setSelectedCategory] = useRecoilState(CategoryFilterAtom);

  const content = children && children.length ? children : (valueList?.map(option => <option key={option.id} id={option.id as string} value={option.label} selected={selectedArea === option.label || selectedCaregory === option.label ? true : false}>{option.label}</option>) || [])

  return (
    <div className="custom-select h-[56px] w-full">
      <select disabled={disabled} className={`${disabled ? "" : "cursor-pointer"} h-[56px] bg-white rounded-xl w-full ` + styles.dropdownWrapper} onChange={(e) => onChange ? onChange(e) : null}>
        <option className="rounded-t-xl border-none bg-red" key="default" value="">
          {placeholder}
        </option>
        {content}
      </select>
    </div>
  );
}