import { useModule } from "@/hooks";
import { useMemo } from "react";
import { ISearchModuleApiResponse, ITabItem } from "@/interfaces/ISearchPanelModuleDTO";
import { useTranslation } from "react-i18next";

import FlightPanel from "../FlightPanel/FlightPanel";
import Tabber from "../Tabber/Tabber";
import FlightsFilterPanel from "../FlightsFilterPanel/FlightsFilterPanel";
import DestinationFlightFilterPanel from "../DestinationFlightFilterPanel/DestinationFlightFilterPanel";
import TrainPanel from "../TrainPanel/TrainPanel";
import ParkingPanel from "../ParkingPanel/ParkingPanel";
import ParkingFilterPanel from "../ParkingFilterPanel/ParkingFilterPanel";
import LaunchBoxPanel from "../LaunchBoxPanel/LaunchBoxPanel";

interface ISearchPanelProps {
    targetUrl: string,
    data: ISearchModuleApiResponse | null,
    language: string
}

const handleContentSelection = (tabItem: ITabItem, lang: string) => {

    let label = tabItem.xtaxProduct.ti.label.replace(" ", '');
    
    switch (/* tabItem.lblLabel[lang] */label) {
        case 'Volo':
        case 'Voli':
        case 'Flight':
        case 'Flights':
            return <div><FlightPanel language={lang} data={tabItem} /></div>
        case 'Parcheggio':
        case 'Parking':
        case 'Parcheggi':
        case 'Parkings':
            return <div><ParkingPanel data={tabItem} /></div>
        case 'Treni':
        case 'Treno':
        case 'Train':
        case 'Trains':
            return <div><TrainPanel data={tabItem} /></div>

        default: 
        console.log(tabItem.xtaxProduct.ti.label)
    }
}

const searchFlightTypes = [
    'ricerca_volo_partenze_MXP',
    'ricerca_volo_partenze_LIN',
    'ricerca_volo_arrivi_MXP',
    'ricerca_volo_arrivi_LIN'
]

const flightDetailsTypes = [
    'volo_dettaglio_partenze_MXP'
]

const destinationFlightTypes = [
    'voli_destinazioni_da_MXP',
    'voli_destinazioni_da_LIN'/* 
    'ricerca_volo_partenze_LIN',
    'ricerca_volo_arrivi_MXP',
    'ricerca_volo_arrivi_LIN' */
]

const searchParkingTypes = [
    'ricerca_parcheggio_MXP',
    'ricerca_parcheggio_LIN'
]

const LaunchBoxTypes = [
    'ricerca_parcheggio_MXP',
    'ricerca_parcheggio_LIN'
]

const SearchPanel: React.FC<ISearchPanelProps> = ({ targetUrl, data, language }: ISearchPanelProps) => {

    var moduleData;

    if (targetUrl) {

        moduleData = useModule<ISearchModuleApiResponse>(targetUrl);
    } else {
        
        moduleData = data;
    }

    
    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;

    /*  const tabs = useMemo(() => moduleData?.data && moduleData?.data?.lstTabber?.map(tabItem => {
         return {id: tabItem.id, label: tabItem.lblLabel[lang], content: <div>{handleContentSelection(tabItem, lang)}</div>}
     }) || [], [moduleData]) */


    return <>
        <div className="searchPanelContainer rounded-3xl bg-lightgray flex flex-column">
            {moduleData?.data && moduleData.data?.lstTabber && moduleData.data.lstTabber?.length && moduleData.data.enuTypeInfo === 'ricerca trasporti' ? <TabWrapper moduleData={moduleData} lang={lang} /> : null}
            {moduleData?.data && moduleData?.data?.enuTypeInfo && moduleData.data.enuTypeInfo === 'box lancio' ? <LaunchBoxPanel language={lang} data={moduleData?.data} /> : null}
            {moduleData?.data && moduleData?.data?.enuTypeInfo && searchFlightTypes.includes(moduleData?.data?.enuTypeInfo) ? <FlightsFilterPanel language={lang} hostPageType={moduleData?.data?.enuTypeInfo} /> : null}
            {moduleData?.data && moduleData?.data?.enuTypeInfo && destinationFlightTypes.includes(moduleData?.data?.enuTypeInfo) ? <DestinationFlightFilterPanel moduleData={moduleData?.data} /> : null}
            {moduleData?.data && moduleData?.data?.enuTypeInfo && searchParkingTypes.includes(moduleData?.data?.enuTypeInfo) ? <ParkingFilterPanel data={moduleData?.data} /> : null}
        </div>
    </>
}

export default SearchPanel;

interface ITabWrapperProps {
    moduleData: ISearchModuleApiResponse,
    lang: string
}

const TabWrapper: React.FC<ITabWrapperProps> = ({ moduleData, lang }: ITabWrapperProps) => {
    const tabs = useMemo(() => moduleData?.data && moduleData?.data?.lstTabber?.map(tabItem => {
        return { id: tabItem.id, label: tabItem.lblLabel[lang], content: <div>{handleContentSelection(tabItem, lang)}</div>, bgColor: "lightgrey", margin: "0", height: "max-h-36", border: "border-darkgrey border-b" }
    }) || [], [moduleData, lang])

    return (<Tabber tabs={tabs} defaultTabId={moduleData?.data.lstTabber[0].id} />)
}
