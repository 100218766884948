import AirlineListAtom from "@/atoms/AirlineListAtom";
import AirlineSelectedCountryCodeAtom from "@/atoms/AirlineSelectedCoutryCode";
import AirlineSelectedIataAtom from "@/atoms/AirlineSelectedIata";
import selectedAirlineIdAtom from "@/atoms/SelectedAirlineIdAtom";
import { selector } from "recoil";

export const filteredAirlineListSelector = selector({
    key: 'filteredListSelector',
    get: ({ get }) => {
      const airlineCountryCode = get(AirlineSelectedCountryCodeAtom);
      const airlineIataCode = get(AirlineSelectedIataAtom);
      const airlineId = get(selectedAirlineIdAtom);
      const list = get(AirlineListAtom);
  
      // Esegui il filtro sulla lista utilizzando i valori dei filtri
      const filteredList = list.filter(airlineItem => {
        // Logica di filtro personalizzata in base ai valori dei filtri
        // Restituisci true se l'elemento corrisponde ai criteri di filtro, altrimenti false
        if(airlineCountryCode) return airlineItem.countryCode === airlineCountryCode;
        if(airlineIataCode) return airlineItem.airlineIata === airlineIataCode;
        if(airlineId) return airlineItem.airlineId === airlineId;

        return list;
      });
  
      return filteredList;
    },
  });

  export default filteredAirlineListSelector;