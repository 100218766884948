import { ReactNode, useEffect, useMemo, useState } from "react";

import styles from './Tabber.module.css';

interface ITabberProps {
    defaultTabId?: number,
    tabs: {
        id: number,
        label: string,
        bgColor: string,
        margin: string,
        height: string,
        border: string,
        content: ReactNode
    }[]
}

const Tabber: React.FC<ITabberProps> = ({ tabs, defaultTabId }: ITabberProps) => {
    const [selectedTabId, setSelectedTabId] = useState<number>(defaultTabId ? defaultTabId : 0);

    let contentToShow = useMemo(() => tabs.find(tabItem => tabItem.id === selectedTabId)?.content, [selectedTabId])

    if(contentToShow === undefined){
        contentToShow = tabs[0]?.content
    }

    const tabList = tabs.map((item, index) => {
        return item.label ? 
            <div 
                className={`text-black tab-item w-28 desk:w-36 text-base text-center font-bold cursor-pointer py-4 ${selectedTabId.toString() === (item.id as unknown as string) ? styles.activeTab : '0'} `} 
                onClick={() => setSelectedTabId(item.id)} key={index}>
                    {item.label}
            </div> : 
            <div key={index} className="py-6"></div> 
    });

    return (
        <div className={`tab-container desk:${tabs[0]?.height} bg-${tabs[0]?.bgColor} rounded-2xl w-full`}>
            <div className={`tabs-container justify-start tab:justify-center desk:justify-start ${tabs[0]?.border} flex desk:ml-${tabs[0]?.margin} desk:gap-3`}>
                {tabList}
            </div>
            <div className="mt-1 tab-content">
                {contentToShow}
            </div>
        </div>
    )
};

export default Tabber;