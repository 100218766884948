import { useRef, useState } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it'
import { formatTime } from "@/utils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { LanguageAtom } from "../../atoms";

const CustomTimePicker: React.FC<any> = ({isParking=false, colorArrow = '#909090', isError = false, className, selected, ...props }) => {
    const pickerRef = useRef<null | any>(null)
    const [isOpen, setIsOpen] = useState(false);
    const lang = useRecoilValue(LanguageAtom);
    const { t } = useTranslation()
    lang === "it" && registerLocale(lang, it)

    return (
        <div className="h-full w-full" onClick={(e) => { e.stopPropagation(); }}>
            <div onClick={() => { setIsOpen(true) }} className={`bg-white py-3 px-6 h-full cursor-pointer rounded-2xl desk:rounded-r-2xl ${isError ? 'border border-red' : null} flex gap-4 items-center justify-between`}>
                <div className="">{selected ? formatTime(selected) : t('parking.hour')}</div>
                {!isParking ? 
                selected.getHours().toString() + ":" + selected.getMinutes().toString() !== new Date().getHours().toString() + ":" + new Date().getMinutes().toString() ?
                    <div className="z-[5]" onClick={() => { props.onChange(new Date()) }}>
                        x
                    </div> :
                    <CustomIcon className={"w-2 transition-transform origin-center duration-300 " + (isOpen ? 'rotate-90' : 'rotate-0')} iconName="chevron-r" color={colorArrow} />
                : <CustomIcon className={"w-2 transition-transform origin-center duration-300 " + (isOpen ? 'rotate-90' : 'rotate-0')} iconName="chevron-r" color={colorArrow} /> }
            </div>
            <div className="overwritedPickerContainer">
                <ReactDatePicker  timeInputLabel={t('parking.time') + ":"} timeCaption={t('parking.time')} locale={lang === 'it' ? "it" : ""} selected={selected} timeFormat="HH:mm" ref={pickerRef} {...props} open={isOpen} onClickOutside={() => setIsOpen(false)} onChange={(e: Date) => { setIsOpen(false); props.onChange(e) }} showTimeInput={false} showTimeSelect showTimeSelectOnly />
            </div>
        </div>
    )
}

export default CustomTimePicker;