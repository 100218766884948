import { useIsMobile } from "@/hooks";
import { configColor } from "@/utils";
import { IBannerViewerApiResponse } from "@/interfaces/IBannerViewerDTO";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import Button from "../Button/Button";
import DOMPurify from "isomorphic-dompurify";

interface IBannerViewerProps {
    data: IBannerViewerApiResponse,
    language: string
}

const getPathByLinkCTA = (item: any, linkType: string, lang: string) => {
    switch (linkType) {

        case "grpLink":
            return item?.lblLink[lang];
        case "grpLinkSecondCta":
            return item?.lblLinkSecondCta[lang];
        
        case "grpGenericPage":
            return item?.xconGenericPage?.lblUrlPath[lang];
        case "grpGenericPageSecondCta":
            return item?.xconGenericPageSecondCta?.lblUrlPath[lang];
            
        case "grpNews":
            return item?.xconNews?.lblUrlPath[lang];
        case "grpNewsSecondCta":
            return item?.xconNewsSecondCta?.lblUrlPath[lang];
            
        case "grpPopup":
            return ""
        case "grpPopupSecondCta":
            return ""
    }
}

const BannerViewer: React.FC<IBannerViewerProps> = ({ data, language }: IBannerViewerProps) => {

    const moduleData = data;
    const { t, i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const [backgroundImagePath, setBackgroundImagePath] = useState<string>('')

    const [isMobile] = useIsMobile();

    const lblTitle: string = DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : '');
    const lblLabelCta: string = moduleData?.data?.lblLabelCta && moduleData?.data?.lblLabelCta[lang] ? moduleData?.data?.lblLabelCta[lang] : '';
    const lblLabelSecondCta: string = moduleData?.data?.lblLabelSecondCta && moduleData?.data?.lblLabelSecondCta[lang] ? moduleData?.data?.lblLabelSecondCta[lang] : '';
    const lblSubtitle: string = DOMPurify.sanitize(moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? moduleData?.data?.lblSubtitle[lang] : '');
    const lblLink: string = moduleData?.data?.lblLink && moduleData?.data?.lblLink[lang] ? moduleData?.data?.lblLink[lang] : ''
    const lblLinkSecondCta: string = moduleData?.data?.lblLinkSecondCta && moduleData?.data?.lblLinkSecondCta[lang] ? moduleData?.data?.lblLinkSecondCta[lang] : ''
    const flgTarget: boolean = moduleData?.data?.flgTarget
    const flgTargetSecondCta: boolean = moduleData?.data?.flgTargetSecondCta


    const enuTitleColor: string = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data.enuTitleColor) : "text-black";
    const enuBackgroundCta: string = moduleData?.data?.enuBackgroundCta ? configColor('bg', moduleData?.data.enuBackgroundCta) : "bg-black";
    const enuBackgroundFirst: string = moduleData?.data?.enuBackgroundFirst ? configColor('text', moduleData?.data.enuBackgroundFirst) : "text-white";
    const enuBackgroundSecondCta: string = moduleData?.data?.enuBackgroundSecondCta ? configColor('bg', moduleData?.data.enuBackgroundSecondCta) : "bg-white";
    const enuBackgroundSecond: string = moduleData?.data?.enuBackgroundSecond ? configColor('text', moduleData?.data.enuBackgroundSecond) : "text-black";


    useEffect(() => {
        let backgroundImageDektopoUrl: string = moduleData?.data?.upiImageDesktop.hasOwnProperty(lang) ? moduleData?.data?.upiImageDesktop[lang]?.mediaserverpath + "/" + moduleData?.data?.upiImageDesktop[lang]?.resourcename : ''
        let backgroundImageMobileUrl: string = moduleData?.data?.upiImageMobile.hasOwnProperty(lang) ? moduleData?.data?.upiImageMobile[lang]?.mediaserverpath + "/" + moduleData?.data?.upiImageMobile[lang]?.resourcename : ''
        setBackgroundImagePath(isMobile ? backgroundImageMobileUrl : backgroundImageDektopoUrl)
    }, [isMobile, moduleData, lang])

    return (
        <>
            <div className="max-w-cont-2 w-full px-6 desk:px-0 desk:mx-auto mt-8 desk:mb-12 mb-8">

                <div style={{ backgroundImage: `url(${backgroundImagePath})` }} className={`desk:justify-center desk:min-h-[300px] bg-no-repeat bg-center bg-cover rounded-3xl px-5 py-10 desk:px-28 desk:py-12 ${enuTitleColor} flex flex-col ${lblSubtitle ? 'gap-14' : 'gap-3'} min-w-min max-w-full`}>
                    <div className="flex flex-col gap-2">
                        {lblTitle ? <h2 dangerouslySetInnerHTML={{ __html: lblTitle }} className={`font-semibold text-3xl font-secondary ${moduleData.data.lblLabelCta[lang] === "SCRIVICI" ? 'desk:translate-y-10' : ''}`}></h2> : null}
                        {lblSubtitle ? <h3 dangerouslySetInnerHTML={{ __html: lblSubtitle }} className="font-normal text-lg"></h3> : null}
                    </div>
                    { lblLabelCta || lblLabelSecondCta ?
                        <div className={`flex flex-col tab:flex-row gap-3 items-end ${isMobile ? "items-baseline" : ""}`}>
                            <div>
                                {lblLabelCta ? <a href={getPathByLinkCTA(moduleData?.data, moduleData?.data?.grpLinkType, lang)} target={flgTarget ? "_blank" : "_self"} className="w-full"><Button className={`active:bg-lightblue active:text-white ${moduleData.data.lblLabelCta[lang] === "SCRIVICI" ? 'desk:translate-y-10' : ''}`} text={lblLabelCta} bgColor={enuBackgroundCta + (isMobile ? " text-sm px-4":"") } textColor={enuBackgroundFirst} onClick={() => { }} /></a> : null}
                            </div>
                            <div>
                                {lblLabelSecondCta ? <a href={getPathByLinkCTA(moduleData?.data, moduleData?.data?.grpLinkTypeSecondCta, lang)} target={flgTargetSecondCta ? "_blank" : "_self"}><Button className="active:bg-lightblue active:text-white" text={lblLabelSecondCta} bgColor={enuBackgroundSecondCta} textColor={enuBackgroundSecond} onClick={() => { }} /></a> : null}
                            </div>
                        </div>
                        : <></>
                    }
                </div>

            </div>
        </>
    )
}

export default BannerViewer;
