import { configColor } from "@/utils";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { CrumbsItemAtom, PagePathAtom } from "@/atoms";
import { useTranslation } from "react-i18next";

import CustomIcon from "../CustomIcon/CustomIcon";


interface IBreadcrumbsPath {
    grpType: string,
    label: string,
    targetUrl: string
}

const getCrumbsData = (pagePath: string, crumbsItem: any, lang: string): IBreadcrumbsPath[] => {

    const arrayPathId: number[] = pagePath.split("/").map(Number).filter(num => num !== 1)
    let level: any[] = crumbsItem.data ? crumbsItem.data[0].ti : null

    let pos: number
    const retVal: any[] = arrayPathId?.map((id, index) => {
        for (let i = 0; i <= level?.length - 1; i++) {
            if (level[i]?.metainfo?.id == id) {
                pos = i
                let label: string = level[i].labelMultilanguage[lang] === "Malpensa" || level[i].labelMultilanguage[lang] === "Linate" ? "Homepage" : level[i].labelMultilanguage[lang]
                let grpType: string = level[i].grpTypeMenu
                let target: string = ''
                if (level[i].grpLink === 'grpLinkLibero') { target = level[i].lblLink[lang] }
                if (level[i].grpLink === 'xconGenericPage') { target = level[i].xconGenericPage.lblUrlPath[lang] }
                if (level[i].grpLink === 'xconNews') { target = '' }
                level = level ? level[pos]?.ti : null

                return {
                    grpType: grpType,
                    label: label,
                    targetUrl: target == "" && label === "Homepage" ? `/${lang}` : target
                }
            }
        }
        level = level ? level[pos]?.ti : null
    })
    return retVal
}


interface IBreadcrumbsProps {
    colorCrumbs?: string,
    language: string
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ colorCrumbs = 'text-grigiomedio', language }: IBreadcrumbsProps) => {

    const [breadcrumbsArray, setBreadcrumbsArray] = useState<IBreadcrumbsPath[]>([]);

    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const pagePath = useRecoilValue(PagePathAtom);
    const crumbsItem = useRecoilValue(CrumbsItemAtom);

    useEffect(() => {
        setBreadcrumbsArray(getCrumbsData(pagePath, crumbsItem, lang)?.filter(item => item?.grpType !== 'grpSecondLevelContainer'));
    }, [pagePath, lang]);

    return <>
        <div className={`flex ${configColor('text', colorCrumbs)} text-xs`}>
            {breadcrumbsArray ?
                breadcrumbsArray.map((item, index) => {

                    const isLastItem = index === breadcrumbsArray.length - 1 ? true : false
                    const label = item?.label
                    const targetUrl = item?.targetUrl

                    return (
                        <div key={index} className="flex">

                            {targetUrl && !isLastItem ?
                                <a href={targetUrl} className={`mr-2 hover:underline underline-offset-2  ${isLastItem ? 'font-extrabold' : 'cursor-pointer'}`}>
                                    {label}
                                </a>
                                :
                                <div className={`mr-2  ${isLastItem ? 'font-extrabold' : null}`}>
                                    {label}
                                </div>}

                            {isLastItem ? null : <CustomIcon iconName="arrow" color="" className={`w-1.5 mr-2 ${configColor('fill', colorCrumbs)}`} />}

                        </div>
                    )
                })
                : null
            }
        </div>
    </>
}

export default Breadcrumbs;