import { ICardDTO } from "@/interfaces/ICardDTO";
import { LaunchCard } from "../Card";
import { useRecoilValue } from "recoil";
import { Swiper, SwiperSlide } from 'swiper/react';
import { configColor, getPathByLink } from "@/utils";
import { useEffect, useMemo, useState } from "react";
import { useDataCard, useIsMobile } from "@/hooks";
import { ILaunchListApiResponse, ILaunchListDTO } from "@/interfaces/ILaunchListDTO";
import { FreeMode } from "swiper";
import { useTranslation } from "react-i18next";

import DOMPurify from "isomorphic-dompurify";
import DestinationCityAtom from "@/atoms/DestinationCityAtom";

import "swiper/css";
import "swiper/css/free-mode";
import useModuleForM5 from "@/hooks/useModuleForM5";
import uuid from "react-uuid";


interface ILaunchListProps {
  data: ILaunchListApiResponse | any,
  dividerMode?: boolean,
  language: string,
  targetUrl: string
}

const getTargetUrl = (item: ILaunchListDTO) => {
  return item?.xconCardLaunch?.targeturl || item?.xconCardLaunchWithText?.targeturl as string
}

const LaunchList: React.FC<ILaunchListProps> = ({ targetUrl = '', data, dividerMode = false, language }: ILaunchListProps) => {

  var moduleData: ILaunchListApiResponse | any;
  const settedDestination = useRecoilValue(DestinationCityAtom)
  const dividerTargetListUrl = `${process.env.NEXT_PUBLIC_BE_BASE_PATH}/rest/v1/SEA-LinMxp/contents/m05?query=xtaxDestinations.ti.lblAirportDestinationDescription=%27${settedDestination}%27`;

  if (data) {
    
    moduleData = data;
  } else {

    moduleData = useModuleForM5<ILaunchListApiResponse>(targetUrl? targetUrl : dividerTargetListUrl)
  }

  
  
   
  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;
  const [isMobile, isTablet] = useIsMobile()

  const cardList = useMemo(() => {
    return moduleData?.data?.grpType === 'grpLaunch' ? moduleData?.data?.lstCardLaunch?.map(getTargetUrl) : moduleData?.data?.lstCardLaunchWithText?.map(getTargetUrl)
  }, [moduleData])

  const dataCard = useDataCard<ICardDTO[]>({ urls: cardList })

  const lblTitle: string = DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : '');
  const enutitleColor: string = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data.enuTitleColor) : 'text-black'
  const txbDescription: string = DOMPurify.sanitize(moduleData?.data?.txbDescription && moduleData?.data?.txbDescription[lang] ? moduleData?.data?.txbDescription[lang] : '')
  const enuBackgroundCta: string = configColor('bg', moduleData?.data?.enuBackgroundCta)

  const isLaunch = moduleData?.data?.grpType === 'grpLaunch' ? true : false
  const bgColor = !dividerMode ? enuBackgroundCta : 'bg-[#FFFFFF]';

  const [isVertical, setIsVertical] = useState(true)

  useEffect(() => {
    setIsVertical(window.innerWidth < 768);
    const handleResize = () => {
      setIsVertical(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const templateContainer = () => {
    if (dividerMode) return ''
    if (moduleData?.data?.enuBackgroundCta?.toLowerCase() === 'bianco') return 'py-0 p-EdgeMob tab:p-EdgeTab tab:py-0'
    return 'p-8 desk:py-12 mt-8'
  }

  return (
    <>
      <div className={`${templateContainer()} h-fit px-6 ${bgColor}`}>
        <div className="max-w-cont-2 mx-auto w-full -translate-y-6">
          <div className={`${enutitleColor} text-center tab:text-left mb-5`}>
            <h1 dangerouslySetInnerHTML={{ __html: lblTitle }} className="text-lg font-bold mb-2"/>
            <h2 dangerouslySetInnerHTML={{ __html: txbDescription }} />
          </div>

          {!isLaunch ?
            <div className="h-full">
              <Swiper
                modules={[FreeMode]}
                freeMode={true}
                spaceBetween={20}
                slidesPerView={"auto"}
                direction={"horizontal"}
                className={`py-4`}
              >

                {dataCard?.map((item, index) => {

                  let imageUrl: string;
                  let lblCTAColor: string = item?.data?.enuTitleColorBox ? configColor("text", item?.data?.enuTitleColorBox) : "text-black"

                  if (isLaunch) {
                    imageUrl = isMobile ?
                      item?.data?.upiImageBoxHMobile[lang]?.mediaserverpath + "/" + item?.data?.upiImageBoxHMobile[lang]?.resourcename
                      :
                      item?.data?.upiImageBoxHDesktop[lang]?.mediaserverpath + "/" + item?.data?.upiImageBoxHDesktop[lang]?.resourcename
                  }
                  else {
                    imageUrl = isMobile ?
                      item?.data?.upiImageMobileLaunch.mediaserverpath + "/" + item?.data?.upiImageMobileLaunch?.resourcename
                      :
                      item?.data?.upiImageDeskLaunch?.mediaserverpath + "/" + item?.data?.upiImageDeskLaunch?.resourcename
                  }

                  return (
                    <SwiperSlide key={index} className="w-[90%]">
                      <a href={getPathByLink(item?.data,lang)} target={item?.data?.flgTarget ? "_blank" : "_self"}>
                        <LaunchCard title={item?.data?.lblTitleCard[lang]} subTitle={item?.data?.lblTextCard[lang]} onClick={() => {}} imageUrl={imageUrl} isLaunch={isLaunch} item={item} txtColor={lblCTAColor}/>
                      </a>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            : 
            <div className="flex flex-col tab:flex-row desk:gap-8 gap-4 py-6">
              {dataCard?.map((item, index) => {
  
                let imageUrl: string;
                let lblCTAColor: string = item?.data?.enuTitleColorBox ? configColor("text", item?.data?.enuTitleColorBox) : "text-black"

                if (isLaunch) {
                  imageUrl = isMobile ?
                    item?.data?.upiImageBoxHMobile[lang]?.mediaserverpath + "/" + item?.data?.upiImageBoxHMobile[lang]?.resourcename
                    :
                    item?.data?.upiImageBoxHDesktop[lang]?.mediaserverpath + "/" + item?.data?.upiImageBoxHDesktop[lang]?.resourcename
                }
                else {
                  imageUrl = isMobile ?
                    item?.data?.upiImageMobileLaunch.mediaserverpath + "/" + item?.data?.upiImageMobileLaunch?.resourcename
                    :
                    item?.data?.upiImageDeskLaunch?.mediaserverpath + "/" + item?.data?.upiImageDeskLaunch?.resourcename
                }

                return (
                  <div key={index} className="aspect-[320/120] w-full">
                    <a  href={getPathByLink(item?.data,lang)} target={item?.data?.flgTarget ? "_blank" : "_self"}>
                      <LaunchCard title={item?.data?.lblTitleBox[lang]} onClick={() => {}} imageUrl={imageUrl} isLaunch={isLaunch} item={item} txtColor={lblCTAColor}/>
                    </a>
                  </div>
                )
              })}
            </div>}
        </div>
      </div>
    </>
  )
};

export default LaunchList;
