import { LanguageAtom } from "@/atoms";
import { IParagraphList } from "@/interfaces/IParagraphList";
import DOMPurify from 'isomorphic-dompurify';
import { useRecoilValue } from "recoil";
import { configColor } from "@/utils";

interface ILayoutProps {
    isMobile: boolean,
    paragraph: IParagraphList,
    language: string
}

const Layout: React.FC<ILayoutProps> = ({ isMobile, paragraph: item, language }: ILayoutProps) => {

    const lang = language;

    return (
        <>
            {item?.lstItem.map((item: IParagraphList) => {

                let { mediaserverpath, resourcename } = isMobile ? item.upiImageMobileIcon[lang] : item.upiImageDesktopIcon[lang];
                let imagePath = mediaserverpath && resourcename ? mediaserverpath + '/' + resourcename : '';

                const labelColor: string = item?.enuTextColor ? configColor('text', item?.enuTextColor) : "text-black";

                return (
                    <div key={item.id} className={`flex flex-row gap-5 mt-5 ${item.lblTag[lang] ? "items-center" : ""}`}>
                        {imagePath &&
                            <img className='block w-5 h-5 mt-1' src={imagePath} />
                        }
                        <div>
                            <div className={`desk:max-w-[33.4rem] max-w-[20rem] text-base font-normal truncate whitespace-normal ${labelColor}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.txbDescription[lang]) }}></div>

                            {item.lblPhoneNumber ?
                                <a className="text-[#6ABACE] font-semibold" href={`tel:${item.lblPhoneNumber}`}> {item.lblPhoneNumber}</a>
                                : ""}
                        </div>

                        {item.lblTag[lang] ?
                            <div className="bg-lightblue w-fit p-2 rounded-lg text-[#FFFFFF] text-sm font-bold">
                                {item.lblTag[lang]}
                            </div>
                            : ""}

                    </div>
                )
            })}
        </>
    )
}

export default Layout;
