import { useIsMobile } from "@/hooks";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const availableLanguage = [
    { id: 1, label: 'IT', url: '/it' },
    { id: 2, label: 'EN', url: '/en' },
    { id: 3, label: '中文', url: 'http://milanomalpensa-airport.cn/' }
];

interface ILanguageSelectorProps {
    hostPageData: any;
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({ hostPageData }: ILanguageSelectorProps) => {

    const { i18n } = useTranslation();
    const { query } = useRouter();
    const lang = i18n.language;

    const [curLang, setCurLang] = useState<any>();

    useEffect(() => {

        availableLanguage.map((item) => {

            let urlLabel = item.label.toLowerCase();

            urlLabel == lang ? setCurLang(item.label) : "";
        });
        
    }, [lang]);

    const getCurrentPath = () => {

        let currentPath = window.location.href.toLowerCase();

        return currentPath.replace("/" + lang.toLowerCase() + "/", "/" + lang + "/");
    };

    const goToPath = (path: string) => {

        let pathClean = path.replaceAll('/', '');

        if (pathClean !== 'en' && pathClean !== 'it') {

            window.location.href = path;
        } else {

            let url = hostPageData ? hostPageData?.lblUrlPath[pathClean] : getCurrentPath();

            if (hostPageData && Object.keys(query).filter(key => key !== 'lang' && key !== 'paths').length) {
                url += ("?"+Object.keys(query).filter(key => key !== 'lang' && key !== 'paths').map(key => {
                    return key + "=" + query[key]
                }).join("&"));
            }

            window.location.href = url;
        }
    }

    return (
        curLang && <div>
            <FormControl sx={{ m: 0, minWidth: 70 }}>
                <NativeSelect
                    className="language-select"
                    value={"/" + curLang.toLowerCase()}
                    onChange={(item) => {
                        goToPath(item.target.value);
                    }}
                    displayEmpty
                    IconComponent={ExpandMoreIcon}
                >
                    { availableLanguage.map(item => {
                        return process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE == 'lin' && item.id == 3 ? "" : 
                        <MenuItem
                            className={item.label == curLang ? "!hidden" : "hover:text-white hover:!bg-lightblue language-item"}
                            key={item.id} 
                            value={item.url}>{item.label}
                        </MenuItem>
                    })}
                </NativeSelect>
            </FormControl>
        </div>
    );
}

export default LanguageSelector;
