import CustomIcon from "../CustomIcon/CustomIcon";

interface ISeeOtherBtnProps{
    text: string,
    iconType?: null | 'next' | 'previous',
    className?: string,
    onClick?: () => void
}
const SeeOtherBtn: React.FC<ISeeOtherBtnProps> = ({text, iconType = null, className = '', onClick}: ISeeOtherBtnProps) => {

    return(
        <div onClick={() => onClick ? onClick() : null} className={"bg-lightgrey text-sm font-bold px-4 py-[10px] rounded-2xl desk:w-fit justify-center flex items-center gap-3 cursor-pointer " + className} >
            {text} {iconType ? <CustomIcon iconName="arrow" color="" className={(iconType === 'next' ? "rotate-90" : "-rotate-90") + ' w-2 '} /> : null}
        </div>
    )
};

export default SeeOtherBtn;