import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Mousewheel } from 'swiper';
import { useIsMobile } from "@/hooks";
import { IInfoGraphicApiResponse } from "@/interfaces/IInfoGraphicDTO";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../CloseIcon/CloseIcon";

interface IInfoGraphicProps {
  data: IInfoGraphicApiResponse,
  language: string
}

const InfoGraphic: React.FC<IInfoGraphicProps> = ({ data, language }: IInfoGraphicProps) => {
  const moduleData = data;
  const { i18n } = useTranslation();
  const lang = language ? language : i18n.language;
  const [isMobile] = useIsMobile();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const infoGraphicAutoplayTime = 3000;

  const handleImageClickOpen = (imageUrl: string) => {
    setIsOpen(true);
    // document.body.style.overflow = "hidden";
    setSelectedImageUrl(imageUrl);
  };

  const handleImageClickClosed = () => {
    setIsOpen(false);
    // document.body.style.overflow = "auto";
    setSelectedImageUrl("");
  };

  return (
    <>
      {moduleData?.data?.lslImage.length > 1 ?
        <div className="mt-12">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            freeMode={true}
            modules={[FreeMode, Mousewheel]}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            className="infografic max-w-cont-2 px-6">
            {moduleData?.data?.lslImage?.map((item) => {
              const itemId = item.id;
              const imageDesktopUrl = item.upiImageDesktop[lang].mediaserverpath + "/" + item.upiImageDesktop[lang].resourcename;
              const imageMobileUrl = item.upiImageMobile[lang].mediaserverpath + "/" + item.upiImageMobile[lang].resourcename;

              return (
                <SwiperSlide key={itemId} className="select-none w-auto cursor-pointer">
                  <div className='rounded-lg overflow-hidden'>
                    <img alt="descrizione immagine" onClick={() => handleImageClickOpen(isMobile ? imageMobileUrl : imageDesktopUrl)} src={`${isMobile ? imageMobileUrl : imageDesktopUrl}`} className='h-[336px] transition-all duration-1000' />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        : <div className="max-w-cont-2 px-6 py-6 mx-auto">
          {moduleData?.data?.lslImage?.map((item) => {
            const imageDesktopUrl = item.upiImageDesktop[lang].mediaserverpath + "/" + item.upiImageDesktop[lang].resourcename;
            const imageMobileUrl = item.upiImageMobile[lang].mediaserverpath + "/" + item.upiImageMobile[lang].resourcename;
            return (
              <div className='rounded-lg overflow-hidden flex justify-center shadow-lg'>
                <img alt="descrizione immagine" onClick={() => handleImageClickOpen(isMobile ? imageMobileUrl : imageDesktopUrl)} src={`${isMobile ? imageMobileUrl : imageDesktopUrl}`} className='w-full' />
              </div>)
          })}
        </div>}

      {isOpen && (
        <div className="select-none">
          <div
            onClick={handleImageClickClosed}
            className="bg-black bg-opacity-50 fixed top-0 left-0 w-full z-20">
            <div className='rounded-lg overflow-hidden flex items-center justify-center h-screen'>
              <button
                className="bg-black p-0 rounded-3xl float-right relative left-1"
                onClick={handleImageClickClosed}
              >
                <img alt="descrizione immagine" onClick={handleImageClickClosed} src={`${selectedImageUrl}`} className='m-auto py-auto' />
                <CloseIcon onClick={handleImageClickClosed} />
              </button>
            </div>

          </div>
        </div>

      )}
    </>
  );
};

export default InfoGraphic;
