import { LoyaltyBalanceDataAtom } from "@/atoms";
import { useAuth } from "@/context/AuthContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { FreeMode } from 'swiper';
import { useIsMobile } from "@/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { t } from "i18next";
import { MoonLoader } from "react-spinners";

import toast from "react-hot-toast";
import CustomIcon from "../CustomIcon/CustomIcon";
import React from "react";

const rewardUrl = `${process.env.NEXT_PUBLIC_LOYALTY_PATH}/ols-loyalty-services/v1/LoyaltyRewardAdapter/getRewardCatalog`;
const redeemUrl = `${process.env.NEXT_PUBLIC_LOYALTY_PATH}/ols-loyalty-services/v1/LoyaltyRewardAdapter/redeemPrize`;

interface IRewardCatalogProps {
	lang: string,
    fetchMovements: any,
    fetchRewards2: any
}

const RewardCatalog: React.FC<IRewardCatalogProps> = ({ lang, fetchMovements, fetchRewards2 }) => {

    const balanceData = useRecoilValue(LoyaltyBalanceDataAtom);
    const [catalogData, setCatalogData] = useState<any[]>([]);
    const swiperRef = useRef<null | any>(null);
    const [isMobile, isTablet] = useIsMobile();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [itemData, setItemData] = useState({policyId: "", bouquetId: ""})
    const auth = useAuth();

    const fetchRewards = () => {

        fetch(`${rewardUrl}${balanceData ? ("?" + new URLSearchParams({'kOperator': '0', profileId: 'null'}).toString()) : ''}`, {

            headers: {
                'keyId': process.env.NEXT_PUBLIC_LOYALTY_KEY as string
            }
        })
        .then(response => response.json())
        .then(response => setCatalogData(response.getRewardCatalogResponse.result.rewardList))
        .finally(() => {

            setisLoading(false);
        })
        
    }

    const redeemPrize = (policyId: string, bouquetId: string) => {

        setisLoading(true);
        
        fetch(`${redeemUrl}${auth ? ("?" + new URLSearchParams({'kOperator': '0', accountNumber: auth?.user?.loyalityNumber as string , rewardPolicyId: policyId, rewardBouquetId: bouquetId, accessChannelId: '3'}).toString()) : ''}`, {
            
            headers: {
                'keyId': process.env.NEXT_PUBLIC_LOYALTY_KEY as string
            }
        })
            .then(response => response.json())
            .then(response => {

                if (response.redeemPrizeResponse.result.statusCode === 17) {

                    toast.error(response.redeemPrizeResponse.result.statusDesc);
                } else {

                    toast.success(response.redeemPrizeResponse.result.statusDesc);
                    fetchMovements();
                    fetchRewards2();

                }                    
            })
            .finally(() => {
                
                setisLoading(false);
            })

        setConfirm(false);
    };

    const handleOpenModal = (policyId: string, bouquetId: string) => {
        setIsModalVisible(true);
        setItemData(
            { policyId : policyId, bouquetId: bouquetId }
        )
    }

    useEffect(() => {
        confirm ? redeemPrize(itemData.policyId, itemData.bouquetId) : '';
    }, [confirm])

    useEffect(() => {
        if (balanceData) fetchRewards();
    }, [balanceData])

    function isRequestable(balance: any, creditCost: any) {

        return balance >= creditCost;
    }

    const catalogList = useMemo(() => {
        return catalogData?.map((catalogItem, index) => {
            return <SwiperSlide key={index}>
                <CatalogRewardCard lang={lang} onClick={() => isRequestable(balanceData?.balance, catalogItem.creditCost) ? handleOpenModal(catalogItem.rewardPolicyId, catalogItem.rewardBouquetId) : null} cost={catalogItem.creditCost} rewardName={catalogItem.rewardItems.rewardName} requestable={isRequestable(balanceData?.balance, catalogItem.creditCost)} />
            </SwiperSlide>
        })
    }, [catalogData])

    return (
        <div className="flex flex-col bg-lightgrey desk:bg-white py-4 px-2 desk:py-0 desk:px-0">
            <div className="text-lg text-black font-bold mb-7">{t('loyalty.rewardCatalog')}</div>
            <div className="flex w-full">
                <Swiper modules={[FreeMode]} style={{margin: 0}} slidesPerView={(catalogList && catalogList.length >= 4) ? (isMobile || isTablet) ? 1.3 : 3.25 : 1.3} freeMode  ref={swiperRef}>
                    {catalogList}
                </Swiper>
            </div>
            { isModalVisible && !isLoading ? 
                <div className="w-[30%] h-[30%] rounded-3xl" style={{ boxShadow: '2px 5px 38px -18px #000000', position: "fixed", top:"40%", left:"40%", border:"none", overflow: "hidden", zIndex: 999999}}>                 
                    <div className="rounded-3xl bg-white h-full w-full p-10 flex flex-col gap-5 justify-center items-center text-center">
                        <h1>{t('loyalty.confirm')}</h1>
                        <div className="flex gap-10">
                            <div className="bg-black w-fit rounded-3xl cursor-pointer text-white py-3 px-4 font-bold text-sm uppercase flex gap-2 items-center" onClick={() => (setIsModalVisible(false), setConfirm(true))}>
                                {t('loyalty.request')}
                            </div>
                            <button className="bg-black w-fit rounded-3xl cursor-pointer text-white py-3 px-4 font-bold text-sm uppercase flex gap-2 items-center" onClick={() => (setIsModalVisible(false), setConfirm(false))}>{t('loyalty.cancel')}</button>
                        </div>
                        </div>
                </div>
                :
                isModalVisible ?
                <div className="w-[30%] h-[30%] rounded-3xl" style={{ boxShadow: '2px 5px 38px -18px #000000', position: "fixed", top:"40%", left:"40%", border:"none", overflow: "hidden", zIndex: 999999}}>                 
                    <div className="rounded-3xl bg-white h-full w-full p-10 flex flex-col gap-5 justify-center items-center text-center">
                        <MoonLoader className="m-auto" color="#6ABACE"/>
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}

interface ICatalogRewardItemCard{
    key?: any,
    requestable: boolean,
    rewardName: string,
    cost: number,
    onClick: () => any,
    lang: string
}

const CatalogRewardCard: React.FC<ICatalogRewardItemCard> = ({requestable, rewardName, cost, onClick, lang}: ICatalogRewardItemCard) => {

    return(
        <div className="flex gap-6 h-full">
            <div>
                <div className="w-[40px] h-[40px] rounded-2xl flex items-center justify-center">
                    <CustomIcon iconName="cup-bg-blue" className="h-[40px] w-[40px]" />
                </div>
            </div>
            <div className="flex flex-col gap-3 w-[180px] h-full justify-between mr-7">
                <div className="flex">
                    <div className="flex flex-col gap-3 border-r border-darkgrey desk:max-w-full">
                        <div className="font-bold text-lg text-black leading-5 mr-2">{rewardName?.length > 20 ? rewardName.substring(0, 20) + '..' : rewardName}</div>
                        <div className="leading-4 text-sm w-[180px]">
                            <b>{cost.toLocaleString(lang)} {t('account.points2')}</b>
                        </div>
                    </div>
                </div>
                <div onClick={() => onClick()} className={"flex items-center gap-2 justify-center w-fit font-bold rounded-3xl padding px-5 py-2 uppercase " + (requestable ? 'hover:bg-lightblue active:bg-lightblue bg-black text-white cursor-pointer' : 'bg-lightgrey text-darkgrey')}>
                   {!requestable ? <CustomIcon iconName="lock" /> : null} {t('loyalty.request')}
                </div>
            </div>
        </div>
    )
}

export default RewardCatalog;
