import { FlightListAtom, IsSearchingAtom, LastFlightListCallAtom, PreviousFlightListAtom } from "@/atoms";
import { useRecoilValue } from "recoil";
import FlightRow, { IFlightItem } from "../FlightRow/FlightRow";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { MovementType } from "@/config/flightPages";
import CustomIcon from "../CustomIcon/CustomIcon";
import { useEffect, useMemo, useState } from "react";
import LaunchList from "../LaunchList/LaunchList";
import { MoonLoader } from "react-spinners";
import { SeeOtherBtn } from "..";
import { t } from "i18next";
import { useModule } from "../../hooks";
import { ICrysisStateApiResponse } from "../../interfaces/ICrysisStateDTO";
import React from "react";

export interface IFlightsProps {
    dividerTargetUrl?: string,
    language: string
}

const Flights: React.FC<IFlightsProps> = ({ dividerTargetUrl, language }: IFlightsProps) => {

    return (
        <div>
            <FlightList language={language} dividerTargetUrl={dividerTargetUrl} />
        </div>
    )
}

const EmptyState: React.FC = () => {
    return (
        <div className='flex items-center gap-4 my-4 desk:my-10 m-auto'>
            <div className='bg-lightgrey h-[60px] w-[60px] flex items-center justify-center rounded-3xl'>
                <CustomIcon iconName="noflights" />
            </div>
            <span className='font-bold text-lg'>{t('flights.flightEmpty')}</span>
        </div>
    )
}

const FlightList: React.FC<IFlightsProps> = ({ dividerTargetUrl, language }: IFlightsProps) => {

    const flightList = useRecoilValue<IFlightItem[]>(FlightListAtom);
    const previousFlightList = useRecoilValue<IFlightItem[]>(PreviousFlightListAtom)
    const isSearching = useRecoilValue<boolean>(IsSearchingAtom);
    const flightListCallDate = useRecoilValue(LastFlightListCallAtom)
    const [flightLabelArrival, setFlightLabelArrival] = useState("");
    const [flightLabelDeparture, setFlightLabelDeparture] = useState("");
    const crysisState = useModule<ICrysisStateApiResponse>(process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/pages/web/statoDiCrisi")
    const [isPreviousFlight, setIsPreviousFlight] = useState(false);
    const router = useRouter();
    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);
    const currentFlights = useMemo(() => {
        return flightList.slice(0, currentIndex + 8)
    }, [currentIndex, flightList])

    useEffect(() => {

        if (window.location.href.includes('arrivi') || window.location.href.includes('arrivals')) {

            let str = t('flights.comingFrom');
            setFlightLabelArrival(str);
        }

        if (window.location.href.includes('partenze') || window.location.href.includes('departures')) {

            let str = t('flights.departingFrom');
            setFlightLabelDeparture(str);
        }
    }), [];

    return (
        <div className="text-black max-w-cont-2 mx-auto w-full pb-12 desk:pt-8">

            {flightListCallDate ?
                <div className="flex items-center gap-2 ml-auto w-fit pb-2 px-8 desk:mr-4 desk:mt-0 mt-6">
                    <div>{t('flights.lastUpdate')}: </div>
                    <div className="w-10">
                        {flightListCallDate?.getHours() + ':' + (flightListCallDate?.getMinutes() < 10 ? '0' : '') + flightListCallDate?.getMinutes()}
                    </div>
                    <div className="cursor-pointer" onClick={() => window.location.reload()}><CustomIcon iconName="refresh" /></div>
                </div> :
                <></>}
            {!isSearching && !(currentIndex + 8 >= flightList.length) ?
                <div className="w-full px-6">
                    <SeeOtherBtn onClick={() => setIsPreviousFlight(!isPreviousFlight)} className="desk:mx-auto my-7 desk:mt-[9px] p-4 w-full" text={t('flights.previousFlights')} iconType={!isPreviousFlight ? "previous" : "next"} />
                </div> : null}
            <div className="desk:px-10 px-6 text-lightblue font-bold text-lg mb-5">
                {/* {query && query?.movementType && query?.movementType === MovementType.D ? t('flights.departingFrom') : (query && query.movementType && query.movementType === MovementType.A ? t('flights.comingFrom') : null)} */}
                {flightLabelArrival !== "" ? flightLabelArrival : flightLabelDeparture}
            </div>
            <div className="flex flex-col gap-8 w-full">
                {isPreviousFlight ?
                    previousFlightList.length ? previousFlightList.map((flightItem, loopIndex) => {
                        return (
                            <React.Fragment key={loopIndex}>
                                <FlightRow key={flightItem.flightId} flightItem={flightItem} crysisInfo={crysisState?.data} />
                                {(loopIndex + 1) % 4 === 0 ? <LaunchList language={language} data={null} targetUrl={dividerTargetUrl!} dividerMode /> : null}
                            </React.Fragment>
                        )
                    }) : null

                    : null}
                {isSearching ? <MoonLoader className="m-auto" color="#6ABACE" /> :
                    currentFlights.length ? currentFlights.map((flightItem, loopIndex) => {

                        return (
                            <React.Fragment key={loopIndex}>
                                <FlightRow key={flightItem.flightId} flightItem={flightItem} crysisInfo={crysisState?.data} />
                                {(loopIndex + 1) % 4 === 0 ? <LaunchList language={language} data={null} targetUrl={dividerTargetUrl!} dividerMode /> : null}
                            </React.Fragment>
                        )
                    }) : (flightListCallDate ? <EmptyState /> : <MoonLoader className="m-auto" color="#6ABACE" />)
                }
            </div>
            {!(currentIndex + 8 >= flightList.length) && !isSearching ?
                <div className="w-full px-6">
                    <SeeOtherBtn onClick={() => setCurrentIndex(currentIndex + 8)} className="m-auto mt-8" text={t('flights.nextFlights')} iconType="next" />
                </div> : null}
        </div>
    )
}

export default Flights;