import { ReactNode, useState } from "react";
import { IParagraphApiResponse } from "../../interfaces/IParagraph";
import { useIsMobile } from "../../hooks";
import { configColor } from '@/utils';
import { useTranslation } from "react-i18next";

import InfoBox from './InfoBox';
import DOMPurify from 'isomorphic-dompurify';
import Button from "../Button/Button";
import CustomIcon from "../CustomIcon/CustomIcon";
import { CloseIcon } from "../CloseIcon/CloseIcon";

interface IParagraphProps {
    data: IParagraphApiResponse,
    language: string
}

const Paragraph: React.FC<IParagraphProps> = ({ data, language }: IParagraphProps) => {

    const moduleData = data;
    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [isMobile] = useIsMobile();
    const [showMap, setShowMap] = useState({value: false, map: ''});
    
    return (
        <>
            {moduleData?.data?.lslParagraphs?.map((paragraph) => {

                let { mediaserverpath, resourcename } = isMobile ? paragraph.upiImageMobile[lang] : paragraph.upiImageDesktop[lang];
                let imagePath = mediaserverpath && resourcename ? mediaserverpath + '/' + resourcename : '';
                let textColor: string = paragraph.enuSubtitleColor ? configColor('text', paragraph.enuSubtitleColor) : "text-black";

                return (
                    <div key={paragraph.id} className={`desk:pt-14 pt-8 pb-16 ${paragraph.enuBackground ? configColor('bg', paragraph.enuBackground) : "bg-white"}`}>
                        <div className="mx-auto max-w-cont-1 w-full px-6 flex justify-center">
                            <Layout layoutType={paragraph.enuTemplate as LayoutType} isMobile={isMobile} imagePosition={paragraph.enuImagePosition}>
                                {paragraph.lblTitle && paragraph.lblTitle[lang] ?
                                    <h2 className='text-2xl desk:text-3xl text-black font-semibold mb-3 font-secondary'
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph.lblTitle[lang]) }}>
                                    </h2>
                                    : null}

                                {paragraph.txbSubtitle && paragraph.txbSubtitle[lang] ?
                                    <h3 className={`${textColor} underlineLink bullet-style`}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph.txbSubtitle[lang], { ADD_TAGS: ['iframe'] }) }}>
                                    </h3>
                                    : null}

                                {imagePath ?
                                    <div className='flex max-h-80 desk:w-[70%] mt-6 -mb-8 desk:mb-0'>
                                        <img className='rounded-3xl w-full' src={imagePath} />
                                    </div>
                                    : null}

                                {paragraph.lblLabelFirstCta[lang] ?
                                    <div className='mt-8 flex flex-col desk:flex-row gap-5'>
                                        { paragraph.txbMapFirst ? 
                                            <div onClick={() => setShowMap({value: true, map: paragraph.txbMapFirst})}>
                                                <Button
                                                    className="active:bg-lightblue"
                                                    text={paragraph.lblLabelFirstCta[lang]}
                                                    bgColor={configColor('bg', paragraph.enuBackgroundFirstCta)}
                                                    textColor={configColor('bg', paragraph.enuBackgroundFirstCta) === "bg-[#000000]" ? "text-white" : "text-black"}
                                                />
                                            </div>
                                            :
                                            <a href={paragraph.lblLinkFirst[lang] != '' ? paragraph.lblLinkFirst[lang] : paragraph.xconGenericPageFirst?.lblUrlPath ? paragraph.xconGenericPageFirst?.lblUrlPath[lang] : '' } target={paragraph.flgTargetFirst ? "_blank" : "_self"}>
                                                <Button
                                                    className="active:bg-lightblue"
                                                    text={paragraph.lblLabelFirstCta[lang]}
                                                    bgColor={configColor('bg', paragraph.enuBackgroundFirstCta)}
                                                    textColor={configColor('bg', paragraph.enuBackgroundFirstCta) === "bg-[#000000]" ? "text-white" : "text-black"}
                                                />
                                            </a>
                                        }
                                        {paragraph.lblLabelSecondCta[lang] ?
                                            <a href={paragraph.lblLinkSecond[lang] != '' ? paragraph.lblLinkSecond[lang] : paragraph.xconGenericPageSecond?.lblUrlPath ? paragraph.xconGenericPageSecond?.lblUrlPath[lang] : '' } target={paragraph.flgTargetSecond ? "_blank" : "_self"}>
                                                <Button
                                                    text={paragraph.lblLabelSecondCta[lang]}
                                                    bgColor={configColor('bg', paragraph.enuBackgroundSecondCta)}
                                                    textColor={configColor('bg', paragraph.enuBackgroundSecondCta) === "bg-[#000000]" ? "text-white" : "text-black"}
                                                />
                                            </a>
                                            : null}
                                    </div>
                                    : null}

                                {paragraph.lstInfoBox && paragraph.lstInfoBox?.length ?
                                    <div className='mt-8 ml-auto desk:my-auto'>
                                        <InfoBox
                                            lstInfoBox={paragraph.lstInfoBox}
                                            lang={lang}
                                            bgColor="bg-lightgrey"
                                            voiceColor="text-lightblue" />
                                    </div>
                                    : null}

                            </Layout>
                            <div className="" hidden={!showMap.value}>
                                <div className="w-screen h-screen" style={{ padding: 30, position: "fixed", top:0, left:0, bottom:0, right:0, border:"none", overflow: "hidden", zIndex: 999999}}>                 
                                    <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                        background: "black",
                                        opacity: 0.1
                                    }}
                                    />

                                    <div className="cursor-pointer" onClick={() => setShowMap({value: false, map: paragraph.txbMapFirst})}>
                                        <button
                                        className="bg-black p-0 rounded-3xl cursor-pointer w-5 absolute top-6 right-6"
                                        >
                                            <CloseIcon onClick={() => setShowMap({value: false, map: paragraph.txbMapFirst})} />
                                        </button>
                                    </div>
                                    { showMap.value ?
                                        <div className="h-[50rem]" dangerouslySetInnerHTML={{ __html: showMap.map }}></div>
                                        : <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}

export default Paragraph;

enum LayoutType {
    singleColumn = 'Una colonna',
    doubleColumn = 'Due colonne'
}

interface ILayoutProps {
    layoutType: LayoutType,
    children: ReactNode[],
    isMobile: boolean,
    imagePosition: string
}

const Layout: React.FC<ILayoutProps> = ({ layoutType, children, isMobile, imagePosition }: ILayoutProps) => {
    const layoutRules = () => {
        let resultString = '';
        if (layoutType.toLowerCase().includes('una colonna')) resultString = 'flex-col justify-center';
        if (!isMobile && layoutType.toLowerCase().includes('due colonne')) {
            resultString = 'flex max-w-cont-2 items-center gap-2';
            if (imagePosition === 'Sinistra') resultString += ' flex-row-reverse';
        };

        return resultString;
    }

    const [title, subtitle, image, cta, infobox] = children;

    return (
        <div className={layoutRules()}>

            {!image && title || !image && subtitle ?
                <>
                    {title && !layoutType.toLowerCase().includes('dx box lista info') ? <div className='desk:basis-1/5'>{title} </div> : null}
                    <div className='desk:flex desk:flex-col desk:basis-1/2 justify-center'>
                        {title && layoutType.toLowerCase().includes('dx box lista info') ? title : null}
                        {subtitle}
                        {cta}
                    </div>
                </>
                : null}

            {image && title ?
                <div className='desk:flex desk:flex-col desk:justify-end desk:basis-[60%] gap-1.5'>
                    {title}
                    {subtitle}
                    {cta}
                    {layoutType === LayoutType.doubleColumn && imagePosition === 'Centro' || layoutType === LayoutType.singleColumn || isMobile && image ?
                        <div className={`desk:mt-16 mt-8 ${isMobile ? "flex justify-center" : ""}`}>{image}</div> : null}
                </div>
                : null}

            {image && !title ?
                <div className='desk:flex desk:flex-col desk:basis-[70%] desk:justify-between'>
                    {subtitle}
                    {imagePosition === 'Centro' &&
                        <div className='mt-8 desk:mt-10'>
                            {image}
                        </div>}
                </div>
                : null}

            {imagePosition && imagePosition !== 'Centro' && !isMobile && image ?
                image
                : null}

            {infobox}

        </div>
    )
}
