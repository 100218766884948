import { IFlightItem } from "../FlightRow/FlightRow";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { FLIGHT_DETAIL_PAGE_URLS, MovementType } from "@/config/flightPages";

import DestinationFlightRow from "../DestinationFlightRow/DestinationFlightRow";
import EmptyState from "../EmptyState/EmptyState";

interface IIspirationTravelFlightsProps{
    destination: string,
    language: string,
    partnerShipActive: boolean
}

// NOTE: former actionLink: FLIGHT_DETAIL_PAGE_URLS[MovementType.D][lang]+'?flightNumber='+flightItem.flightNumber.replace(" ","")+"&flightId="+flightItem.flightId
const IspirationTravelFlights: React.FC<IIspirationTravelFlightsProps> = ({ destination, language, partnerShipActive }: IIspirationTravelFlightsProps) => {

    const [flightList, setFlightList] = useState<IFlightItem[]>([]);
    const { t, i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const currentFlights = useMemo(() => {
        return flightList?.slice(0, 6)
    }, [flightList, lang])

    console.log('partnerShipActive -> ', partnerShipActive)

    const getFlightsByDestination = () => {
        fetch(process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH + `/${lang}/scheduled/flights/lists?airportReferenceIata=${process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE}&loadingType=P&airportDestinationDescription=${destination}`, {
          headers: {
            'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string
          }
        })
          .then(response => response.json())
          .then(response => setFlightList(response.data))
      }

    useEffect(() => { 
        getFlightsByDestination()
    }, [destination])

    return (
        <div className={currentFlights?.length ? "px-5 tab:px-14 desk:px-32 py-12" : ""}>
            <h1 className="font-semibold font-secondary text-3xl mb-24 uppercase ml-[45px]">{t('travel_inspiration.findYourFlightTo')} {destination}</h1>
            <div className="flex flex-col gap-8 w-full">
                {currentFlights?.length  ? <div className={"tableHeader w-full flex justify-center"}>
                    <div className="w-2/6 desk:w-1/6 desk:pl-12 text-lg font-bold text-lightblue">{t('destinations.flight')}</div>
                    <div className="hidden desk:block w-1/6 text-lg font-bold text-lightblue text-center">{t('destinations.airline')}</div>
                    <div className="w-2/6 desk:w-2/6 text-lg font-bold text-lightblue text-center">{t('destinations.activity')}</div>
                    <div className="w-1/6 text-lg font-bold text-lightblue text-center">{t('destinations.frequency')}</div>
                    <div className="hidden desk:block desk:w-1/6 text-lg font-bold text-lightblue text-center"></div>
                </div> : null}
                {currentFlights?.length ? currentFlights.map((flightItem, loopIndex) => {
                    return (
                        <>
                            <DestinationFlightRow actionColumn={partnerShipActive} actionLabel={t('flights.buyTickets') as string} actionLink={`/${lang}/voli/prenota-il-tuo-volo`} key={flightItem.flightId} flightItem={flightItem} />
                        </>
                        )
                }) : <div className="w-full flex justify-center">{(Array.isArray(flightList) && flightList?.length === 0 )? <EmptyState /> : null}</div>}
            </div>
        </div>
    )
}

export default IspirationTravelFlights;
