import { FlightDetailsAtom } from "@/atoms";
import { useRecoilValue } from 'recoil';
import { IStepperItem } from "@/interfaces/IStepper";
import { useTranslation } from "react-i18next";

interface IStepperProps {
    isMobile:boolean,
    content: IStepperItem,
    language: string
}

const ArrivalCheckPassport: React.FC<IStepperProps> = ({ isMobile, content, language }: IStepperProps) => {

    const lang = language;
    // const flightDetail = useRecoilValue(FlightDetailsAtom);

    return (
        <div>
            <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
            <h1 className="mb-2">{content.txbSubtitle[lang]}</h1>
        </div>
    );
}

export default ArrivalCheckPassport;
