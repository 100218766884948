import { getPathByLink } from "@/utils";
import { INewsDTO } from "../../interfaces/IAccordionListDTO";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DOMPurify from "isomorphic-dompurify";
import CustomIcon from "../CustomIcon/CustomIcon";

interface INewsCardProps {
    data: INewsDTO,
    lang: string,
    isMobile: boolean,
    isRow?: boolean,
    isFull?: boolean
}

const NewsCard: React.FC<INewsCardProps> = ({ data, lang, isMobile, isRow, isFull }: INewsCardProps) => {

    const { t } = useTranslation();
    
    const mediaserverpath = isMobile ? data?.upiImageMobileNews[lang]?.mediaserverpath : data?.upiImageDesktopNews[lang]?.mediaserverpath;
    const resourcename = isMobile ? data?.upiImageMobileNews[lang]?.resourcename : data?.upiImageDesktopNews[lang]?.resourcename;
    const imagePath = mediaserverpath + "/" + resourcename;

    const date = new Date(data?.dteNews);
    const formattedDate = data?.dteNews ? date.toLocaleDateString(`${lang}-${lang.toUpperCase()}`, { day: '2-digit', month: 'long', year: 'numeric' }) : "18 Luglio 1998";

    const [label, setLabel] = useState('');

    async function getLabel () {

        fetch(data?.xtaxNewsCategory?.ti[0]?.targeturl!)
        .then((response) => response?.json())
        .then((response) => {
            
            setLabel(response.data.lblNameCategory[lang]);
        })
    }

    useEffect(() => {
        
        getLabel();
    }, [data])

    return (
        <div className={` flex ${isRow && !isMobile ? "flex-row-reverse justify-between items-center" : "flex-col gap-4"} desk:w-[85%] desk:max-w-[80rem] h-full`}>
            <div className={`rounded-2xl ${isRow && !isMobile ? '' : ''}`}>
                <img className={`rounded-2xl aspect-[414/290] object-cover ${!isRow && !isFull ? 'h-[290px]' : ''} w-full`} src={imagePath} />
            </div>
            <div className={`flex flex-col text-sm h-1/2 gap-[8px] ${isRow && !isMobile ? 'w-[40%]' : ''}`}>
                {isFull &&
                    <>
                        <div className="text-lightblue font-bold">
                            {label.toUpperCase()}
                        </div>
                        <div className="font-bold"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.lblTitleNews && data?.lblTitleNews[lang] ? data?.lblTitleNews[lang] : "") }}></div>
                    </>}
                <div className="capitalize">
                    {formattedDate}
                </div>
                {isFull &&
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.lblSubtitleNews && data?.lblSubtitleNews[lang] ? data?.lblSubtitleNews[lang] : "") }}></div>
                }
                <a className="!text-black !no-underline" href={getPathByLink(data, lang)} >
                    <div className="flex gap-2 cursor-pointer"  >
                        {isFull ?
                            <div className="font-bold">
                                {t('news.readArticle')}
                            </div> :
                            <div className="font-bold"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.lblTitleNews && data?.lblTitleNews[lang] ? data?.lblTitleNews[lang] : "") }}>
                            </div>
                        }
                        <CustomIcon iconName="arrow" className="fill-[#000000] w-2" />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default NewsCard;
