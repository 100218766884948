import AirlineListAtom from "@/atoms/AirlineListAtom";
import { useAirlineList } from "@/hooks";
import IAirline from "@/interfaces/IAirline";
import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Autocomplete from "../Autocomplete/Autocomplete";
import { Dropdown } from "..";
import { t } from "i18next";
import AirlineSelectedCountryCodeAtom from "@/atoms/AirlineSelectedCoutryCode";
import AirlineSelectedIataAtom from "@/atoms/AirlineSelectedIata";
import selectedAirlineIdAtom from "@/atoms/SelectedAirlineIdAtom";

const AirlinesFilterPanel: React.FC = () => {


  const [airlineList] = useAirlineList();

  const selectedAirlineId = useRecoilValue(selectedAirlineIdAtom)
  const [selectedCountryCode, setSelectedCountryCode] = useRecoilState(AirlineSelectedCountryCodeAtom)
  const [selectedAirlineIata, setSelectedAirlineIata] = useRecoilState(AirlineSelectedIataAtom);

  const countryOpts: { label: string, id: string }[] = useMemo(() => {
    let countryCodes: { label: string, id: string }[] = [];
    airlineList.map(airlineItem => {
      if (!countryCodes.some(item => { return item.id === airlineItem.countryCode })) {
        countryCodes.push({ id: airlineItem.countryCode, label: airlineItem.countryDescription })
        countryCodes.sort((p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0)
      }
    })

    return countryCodes as { label: string, id: string }[]
  }, [airlineList])

  const codeOpts: { label: string, id: string }[] = useMemo(() => {
    let codes: { label: string, id: string }[] = [];
    airlineList.map(airlineItem => {
      if (!codes.some(item => { return item.id === airlineItem.airlineIata })) {
        codes.push({ id: airlineItem.airlineIata, label: airlineItem.airlineIata })
        codes.sort((p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0)
      }
    })

    codes.sort((a, b) => {
      var textA = a.label.toUpperCase()
      var textB = b.label.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })

    return codes as { label: string, id: string }[]
  }, [airlineList])

  const handleCountrySelect = (e: any) => {
    const selectedValue: string = countryOpts.find(item => item.label === e.target.value)?.id || '';
    setSelectedCountryCode(selectedValue)
  }

  const handleCodeSelect = (e: any) => {
    const selectedValue: string = codeOpts.find(item => item.label === e.target.value)?.id || '';
    setSelectedAirlineIata(selectedValue)
  }

  const countryDisabled = useMemo(() => [selectedAirlineIata, selectedCountryCode, selectedAirlineId].some(item => item) && !selectedCountryCode, [selectedAirlineIata, selectedCountryCode, selectedAirlineId])
  const codeDisabled = useMemo(() => [selectedAirlineIata, selectedCountryCode, selectedAirlineId].some(item => item) && !selectedAirlineIata, [selectedAirlineIata, selectedCountryCode, selectedAirlineId])
  return (
    // <div className="flex bg-lightgrey rounded-2xl w-fit mx-auto px-6 py-8 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center ">
    <div className="bg-lightgrey flex rounded-2xl px-3 py-5 desk:p-10 flex-col items-start gap-4 desk:flex-row desk:items-center justify-between">
      <div className="flex desk:items-center w-full gap-4 desk:flex-row flex-col items-start justify-center">
        <Autocomplete disabled={!!(selectedCountryCode || selectedAirlineIata)} />
        {/* <Dropdown disabled={countryDisabled} onChange={handleCountrySelect} placeholder={t('destinations.selectNation') as string} valueList={countryOpts} /> */}
        <Dropdown disabled={codeDisabled} onChange={handleCodeSelect} placeholder={t('airlines.selectCode') as string} valueList={codeOpts} />
      </div>
      {/* {airlineList.map(item => { !(countryOpts && countryOpts.length)
        return <div>item</div>
      })} */}
    </div>
  )
}


export default AirlinesFilterPanel;