import { useLayoutEffect, useRef, useState } from "react";
import { IProductCardProps } from "./ICard";
import { useInView } from 'react-intersection-observer';


const ProductCard: React.FC<IProductCardProps> = ({ imgUrl, title, titleColor, subTitle, subTitleColor, price, oldPrice = null, labelCta, bgCta, onClick }) => {
    // const [ref, inView] = useInView({
    //     triggerOnce: false,
    //     threshold: 0.8,
    //     rootMargin: '0px 0px',
    // })

    // const divRef: any = useRef();
    // const h3Ref: any = useRef();
    // const [hasToScroll, setHasToScroll] = useState(false);

    // useLayoutEffect(()=>{
    //     if(h3Ref.current.offsetWidth > divRef.current.offsetWidth)
    //         setHasToScroll(true)
    //     else
    //         setHasToScroll(false)
    // },[])

    return (
        <div 
        // ref={ref} 
        className="flex flex-col w-[216px] desk:w-[300px] h-[316px] desk:h-[445px] rounded-2xl bg-lightgrey overflow-hidden shadow-lg">

            <div className="flex-auto desk:w-full max-h-[165px] min-h-[165px] desk:max-h-[257px] desk:min-h-[257px] overflow-hidden shadow-sm bg-white">
                <img src={imgUrl} className="rounded-t-2xl w-full h-full hover:scale-110 transition duration-500 object-contain" />
            </div>

            <div className="flex-1 items-center mb-4 h-full desk:my-4 mx-4 desk:mx-6 max-h-[75px] ">
                <h2 className={`font-bold ${titleColor} max-h-[70px] min-h-[70px] desk:min-h-min overflow-hidden`} dangerouslySetInnerHTML={{ __html: title }}></h2>
                <div 
                // ref={divRef} 
                className="overflow-hidden desk:mt-1">
                    <h3 
                    // ref={h3Ref} 
                    className={`text-sm leading-4 ${subTitleColor} w-max`} dangerouslySetInnerHTML={{ __html: subTitle }}></h3>
                </div>
            </div>

            <div className="flex h-full flex-1 items-center">

                <div className="flex flex-1 ml-4 desk:ml-6 justify-between h-full">
                    <div className="flex flex-col justify-center">
                        {oldPrice && <div className="text-gray-600 line-through">{oldPrice}</div>}
                        <div className="text-black font-bold" dangerouslySetInnerHTML={{ __html: price }}></div>
                    </div>
                    <div onClick={e => onClick(e)} className="desk:h-[80%] desk:flex desk:self-end">
                        {labelCta != "" ?
                            <button className={`bg-[#6ABACE] text-[#FFFFFF] font-bold h-full rounded-tl-3xl py-2 pl-4 pr-2 desk:px-8 desk:py-0 hover:bg-[#459DB4] active:bg-[#459DB4] transition duration-300`}>
                                {labelCta.toUpperCase()}
                            </button>
                            : null}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProductCard;
