import { ServiceListAtom } from "@/atoms";
import { useRecoilValue } from "recoil";
import React, { ReactElement } from "react";
import ServiceCard from "../Card/SerivceCard";

const Services: React.FC<IServicesProps> = ({ children, language }: IServicesProps) => {

    return (
        <div>
            <ServiceList language={language} />
            {children}
        </div>
    )
}

interface IServicesProps {
    children?: ReactElement<any, any>,
    language: string
}

const ServiceList: React.FC<any> = ({language}) => {

    const serviceList = useRecoilValue(ServiceListAtom)

    return (
        <div className="desk:grid flex flex-col gap-7 desk:grid-cols-4 desk:gap-x-5 desk:gap-y-11 py-8 desk:py-12 max-w-cont-2 mx-auto w-full items-center">
            {serviceList.map((card: any, index) => {
                return (
                    <React.Fragment key={index}>
                        <ServiceCard
                            language={language}
                            data={card}
                        />
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default Services;
