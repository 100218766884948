import { LanguageAtom } from '@/atoms';
import { IInfoCardProps } from './ICard'
import { useRecoilValue } from 'recoil';

import DOMPurify from 'isomorphic-dompurify';

const StaticInfoCard: React.FC<IInfoCardProps> = ({ title, description, imageUrl, bgColor }: IInfoCardProps) => {

    const lang = useRecoilValue(LanguageAtom)
    const tit = DOMPurify.sanitize(title);

    return (
        <div className={`flex flex-col gap-4 items-center ${bgColor} rounded-2xl w-[17rem] py-7 px-6 h-[17rem]`}>
            <div style={{ width: '27px', height: '35px' }}>
                <img className="w-full h-full" src={imageUrl} />
            </div>
            <h2 className='font-bold text-center mb-4' dangerouslySetInnerHTML={{ __html: tit }} />
            <div className='flex flex-col gap-2 justify-between items-center h-full'>
                <div>
                    <h3 className='text-center text-sm'>{description}</h3>
                </div>
            </div>
        </div>
    )
}

export default StaticInfoCard;