function formatDate(date: Date, delimiter: string = '-', withTime: boolean = true): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}${delimiter}${month}${delimiter}${day}` + `${withTime ? ' ' + `${hours}:${minutes}` : ``}`;
}

export default formatDate;