import { useIsMobile, useModule } from "../../hooks";
import { INewsApiResponse } from "../../interfaces/IAccordionListDTO";
import { NewsCard } from "../Card";
import { ITaxonomyDTO } from "../../interfaces/IPageDTO";
import { useTranslation } from "react-i18next";

interface INewsCategoryProps {
    data: {
        grpTypology: string,
        flgAllNews: boolean,
        xtaxNewsCategory: ITaxonomyDTO,
        newsId: number
    },
    language: string
}
const NewsCategory: React.FC<any> = ({ data, language }: INewsCategoryProps) => {

    const { i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [isMobile] = useIsMobile();

    const targetUrl = process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/news?query=" +
        (data?.grpTypology === 'grpNewsManualSelection' ? `metainfo.id=%27${data?.newsId}%27` :
            (data?.flgAllNews ? "" :
                `xtaxNewsCategory.ti.label=%27${data?.xtaxNewsCategory?.ti[0]?.label}%27`)) + "&sort=dteNews:DESC&sort=metainfo.publicationdate:DESC&from=0&size=1"
    const news = useModule<any>(targetUrl)

    return (
        <div className="flex justify-center bg-lightgrey p-EdgeMob tab:p-EdgeTab desk:px-32 desk:py-24">
            <NewsCard
                data={news?.data[0]}
                lang={lang}
                isMobile={isMobile}
                isRow={true}
                isFull={true} />
        </div>
    )
}

export default NewsCategory;
