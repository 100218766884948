import { useRef, useState } from "react";

import DOMPurify from "isomorphic-dompurify"
import CustomIcon from "../CustomIcon/CustomIcon"

interface IBoxFaqProps {
    data: dataDTO[],
    lang: string,
}

interface dataDTO {
    title: { [key: string]: string },
    subTitle: { [key: string]: string },
    id: number
}

// const BoxFaq: React.FC<IBoxFaqProps> = ({ data, lang }: IBoxFaqProps) => {

//     const [expandedId, setExpandedId] = useState(null);
//     const [subtitleHeight, setSubtitleHeight] = useState(0)
//     const [isMobile, isTablet, isDesktop] = useIsMobile()

//     const espandi = (id: any) => {
//         setExpandedId(expandedId === id ? null : id)
//     }

//     useEffect(() => {
//         let elementList: NodeListOf<any> = document?.querySelectorAll('.custom-subtitle');
//         elementList?.length ? setSubtitleHeight(elementList[0]?.offsetHeight) : null
//     }, [expandedId])


//     return (
//         <div className="rounded-2xl shadow-2xl">
//             {data?.map((faq, index) => (
//                 <div key={index} className={`flex flex-col desk:p-7 p-4 ${index != data?.length - 1 && "border-b-2 border-b-gray-300"}`}>
//                     <div className="flex justify-between items-center gap-4">
//                         <h2 className="font-bold desk:text-lg text-sm" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq?.title && faq?.title[lang] ? faq?.title[lang] : "") }}></h2>
//                         <div>
//                             <div className={`bg-black w-10 h-10 rounded-2xl flex justify-center cursor-pointer `} onClick={() => espandi(index)}>
//                                 <CustomIcon iconName="arrow" className={`fill-[#FFFFFF] w-2 cursor-pointer transition-transform origin-center duration-300 ${expandedId === index ? 'rotate-90' : 'rotate-0'}`} />
//                             </div>
//                         </div>
//                     </div>

//                     <div style={{ height: expandedId === index ? subtitleHeight + (isDesktop ? 28 : 16) : 0 }} className={`transition-all duration-300 overflow-hidden`} >
//                         <h3 className="mt-3 custom-subtitle h-auto" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq?.subTitle && faq?.subTitle[lang] ? faq?.subTitle[lang] : "") }}></h3>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     )
// }

const BoxFaq: React.FC<IBoxFaqProps> = ({ data, lang }: IBoxFaqProps) => {

    const [expandedId, setExpandedId] = useState<number | null>(null);
    const subtitleRefs = useRef<(HTMLHeadingElement | null)[]>([]);

    subtitleRefs.current = Array(data?.length).fill(null).map((_, i) => subtitleRefs.current[i] || null);

    const espandi = (id: number) => {
        setExpandedId(expandedId === id ? null : id);
    }

    return (
        <div className="rounded-2xl shadow-2xl">
            {data && data?.map((faq, index) => {
                if (faq?.title[lang]) {
                    return (
                        <div key={index} className={`flex flex-col desk:p-7 p-4 ${index !== data.length - 1 && 'border-b-2 border-b-gray-300 border-l'} ${index === expandedId && "border-l-[6.3px] border-l-black rounded-bl-[10px] transition-colors delay-75"}`}>
                            <div className="flex justify-between items-center gap-4">
                                <h2 className="font-bold desk:text-lg text-sm" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq?.title && faq?.title[lang] ? faq?.title[lang] : "") }}></h2>
                                <div>
                                    <div className={`bg-black w-10 h-10 rounded-2xl flex justify-center cursor-pointer `} onClick={() => espandi(index)}>
                                        <CustomIcon iconName="arrow" color="#FFFFFF" className={`fill-[#FFFFFF] w-2 cursor-pointer transition-transform origin-center duration-300 ${expandedId === index ? 'rotate-90' : 'rotate-0'}`} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: expandedId === index ? `${subtitleRefs?.current[index]?.offsetHeight! + 25}px` : '0' }} className={`transition-all duration-300 overflow-hidden`}>
                                <h3 ref={ref => subtitleRefs.current[index] = ref} className="py-2 mt-3 custom-subtitle h-auto" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq?.subTitle && faq?.subTitle[lang] ? faq?.subTitle[lang] : '', { ADD_ATTR: ['target'] }) }} />
                            </div>
                        </div>)
                }
            })}
        </div>
    );
}

export default BoxFaq
