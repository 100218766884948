import { IAccordionDTO } from "../../interfaces/IAccordionListDTO"

import BoxFaq from "./BoxFaq";
import TitleSubtitle from "./TitleSubtitle";

interface IAccordionListProps {
    lang: string,
    accordionData: {
        lslAccordion: IAccordionDTO[]
        lblTitle: { [key: string]: string },
        lblSubtitle: { [key: string]: string },
    }
}


const AccordionList: React.FC<IAccordionListProps> = ({ lang, accordionData }: IAccordionListProps) => {

    const data = accordionData?.lslAccordion.map((accordion) => {
        return { ...accordion, title: accordion?.lblTitleAccordion, subTitle: accordion.txbSubtitle }
    })

    return (
        <div className="flex flex-col desk:flex-row desk:gap-20">
            <div className="tab:w-1/3">
                <TitleSubtitle
                    title={accordionData?.lblTitle && accordionData?.lblTitle[lang] ? accordionData?.lblTitle[lang] : ""}
                    subTitle={accordionData?.lblSubtitle && accordionData?.lblSubtitle[lang] ? accordionData?.lblSubtitle[lang] : ""} />
            </div>
            <div className="desk:flex-[3] flex-none">
                <BoxFaq
                    data={data}
                    lang={lang} />
            </div>
        </div>)
}

export default AccordionList;