import { IXconCard } from "@/interfaces/IInfoLaunchesDTO";

import useDataCard from "./useDataCard";


const useCardManager = (lstCard: IXconCard[]) => {

    const cardList = lstCard?.map((item) => {
        return item.xconCard.targeturl
    }) || [];

    const cardData = useDataCard({ urls: cardList })

    return cardData
};

export default useCardManager;
