import isToday from "./isToday";

function formatDateFlight(date: Date, delimiter: string = '-', withTime: boolean = true): string {

  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = isToday(date) ? date.getHours().toString().padStart(2, '0') : startOfDay.getHours().toString().padStart(2, '0');
  const minutes = isToday(date) ? date.getMinutes().toString().padStart(2, '0') : startOfDay.getMinutes().toString().padStart(2, '0');

  return `${year}${delimiter}${month}${delimiter}${day}` + `${withTime ? ' ' + `${hours}:${minutes}` : ``}`;
}

export default formatDateFlight;