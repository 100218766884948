import { ICardDTO } from "@/interfaces/ICardDTO";
import { error } from "console";
import { useEffect, useState } from "react";

/* import { ICardDTO } from "../interfaces/IVisoreCarouselDTO"; */

interface IUseDataCardProps {
    urls: string[]
}


function useDataCard<T>({ urls }: IUseDataCardProps): T {

    const [data, setData] = useState<ICardDTO[]>()
    const fetchData = () => {
        Promise.all(urls.map((itemUrls) => {
            return fetch(itemUrls).then(response => response.json())
        })).then(
            response => setData(response)
        ).catch((error) => {

            console.error('[useDataCard] Error -> ', error);
        })
    }

    useEffect(() => {
        if (urls && urls.length) fetchData()
    }, [urls])

    return data as T

}

export default useDataCard
