import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
import { useIsMobile } from "../../hooks";
import { configColor, getPathByLink, navigateTo } from "@/utils";
import { useEffect, useMemo, useState } from "react";
import { ICardResponseDTO } from "../../interfaces/IVisoreCarouselDTO";
import { useTranslation } from "react-i18next";

const Chip = dynamic(() => import("../ChipList/Chip"), { ssr: false });

import DOMPurify from "isomorphic-dompurify";
import ProductCard from "../Card/ProductCard";
import useDataCard from "../../hooks/useDataCard";
import ModuleComponentProps from "../../interfaces/ModuleComponent";
import dynamic from 'next/dynamic';
import React from 'react';

import "swiper/css";
import "swiper/css/free-mode";

const VisoreCarousel: React.FC<ModuleComponentProps> = ({ data, language }: ModuleComponentProps) => {

    const moduleData = data;
    const { i18n, t } = useTranslation();
    const lang = language? language : i18n.language;
    const [isMobile] = useIsMobile();
    const [widgetList, setWidgetList] = useState<any>();
    const [widgetDetail, setWidgetDetail] = useState<any>([]);
    const [hash, setHash] = useState<string>();

    const cardUrlList = useMemo(() => {
        return moduleData?.data?.lstCard?.map((item: { xconCard: { targeturl: any; }; }) => {
            return item.xconCard.targeturl
        }) || []
    }, [moduleData])

    const dataCard = useDataCard<ICardResponseDTO[]>({ urls: cardUrlList });

    async function getHasher() {
        return await fetch (
            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER}${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID}`);
    }

    async function getWidgetList(hash: string, uuid: string) {
                
        return await fetch(

            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_BASE_PATH}/widget/${uuid}`, {
                method: 'GET',
                headers: ({
                    'x-auth-hash': hash,
                    'x-auth-instance-id': process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID!
                })
            }
        )               
    }

    async function getWidgetDetail(hash: string, id: string) {

        return await fetch(
            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_BASE_PATH}/product/${id}`, {
                method: 'GET',
                headers: ({
                    'x-auth-hash': hash,
                    'x-auth-instance-id': process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID!
                })
            }
        )
    }

    if (moduleData.data.grpCardType == "grpAutomatismMarketplace") {

        useEffect(() => {

            getHasher()
                .then(response => response?.json())
                .then((response) => {
                    
                    setHash(response.hash);

                    getWidgetList(response.hash!, moduleData.data.lblCarouselCode)
                        .then(response => response.json())
                        .then((response) => {
                            
                            setWidgetList(response.widget_configuration?.product?.value);
                        })
                        .catch(error => {

                            console.error('[VisoreCarousel] getWidgetList -> ', error);
                        })
                })
                .catch(error => {

                    console.error('[VisoreCarousel] getHasher error -> ', error);
                })
        }, [])

        useEffect(() => {

            let promisesList: any = [];

            widgetList?.map((widget: any) => {

                const url = getWidgetDetail(hash!, widget.productId);
                promisesList = [...promisesList, url];
            })

            Promise.all(promisesList)
                .then(response =>  

                    Promise.all(response.map(r => r.json())))
                        .then((response) => {
                            
                            setWidgetDetail(response);
                        })
                        .catch(error => {
                        
                            console.error('[VisoreCarousel] getWidgetDetail error -> ', error);
                        })

        }, [widgetList])
    }

    var resourcename: string | undefined = "";
    var mediaserverpath: string | undefined = "";

    if (isMobile) {

        resourcename = moduleData?.data?.upiImageMobile && moduleData?.data?.upiImageMobile.resourcename ? moduleData?.data?.upiImageMobile.resourcename : "";
        mediaserverpath = moduleData?.data?.upiImageMobile && moduleData?.data?.upiImageMobile.mediaserverpath ? moduleData?.data?.upiImageMobile.mediaserverpath : "";
    } else {

        resourcename = moduleData?.data?.upiImageDesk && moduleData?.data?.upiImageDesk.resourcename ? moduleData?.data?.upiImageDesk.resourcename : "";
        mediaserverpath = moduleData?.data?.upiImageDesk && moduleData?.data?.upiImageDesk.mediaserverpath ? moduleData?.data?.upiImageDesk.mediaserverpath : "";
    }

    const imagePath = mediaserverpath + '/' + resourcename;

    const bgBtnColor = moduleData?.data?.enuBackgroundCta ? configColor('bg', moduleData?.data?.enuBackgroundCta) : "bg-white";
    const txtColor = moduleData?.data?.enuTitleColor ? configColor('text', moduleData?.data?.enuTitleColor) : "text-black";

    return (
        <div style={{ backgroundImage: `url(${imagePath})` }} className="bg-cover bg-no-repeat bg-center desk:h-[550px] h-auto" >
            <div className="max-w-cont-2 mx-auto h-full w-full flex flex-col desk:flex-row">
                <div className={`mt-9 desk:mt-36 mb-14 desk:mb-56 desk:flex-col flex-[1.25] ${txtColor} px-6`}>
                    {moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ?
                        <h1
                            className='text-2xl desk:text-3xl font-semibold mb-2.5 font-secondary uppercase'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblTitle && moduleData?.data?.lblTitle[lang] ? moduleData?.data?.lblTitle[lang] : '') }}>
                        </h1>
                        : null}

                    {moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ?
                        <h2
                            className="mb-7 desk:mb-10"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(moduleData?.data?.lblSubtitle && moduleData?.data?.lblSubtitle[lang] ? moduleData?.data?.lblSubtitle[lang] : '') }}>
                        </h2>
                        : null}

                    {moduleData?.data?.lblLabelCta && moduleData?.data?.lblLabelCta[lang] ?
                        <div>
                            <Chip
                                text={moduleData?.data?.lblLabelCta[lang]}
                                bgColor={bgBtnColor}
                                txtColor={bgBtnColor === "bg-[#FFFFFF]" ? "text-black" : "text-white"}
                                width="w-full"
                                onClick={() => { }}
                                hrefPath={getPathByLink(moduleData?.data, lang)}
                                target={moduleData?.data?.flgTarget ? "_blank" : "_self"}
                            />
                        </div>
                        : null}
                </div>
                
                <div className="flex flex-[3] mb-12 desk:mb-0 text-black h-full desh:h-[550px]">
                    <Swiper
                        direction={isMobile ? "horizontal" : "vertical"}
                        freeMode={true}
                        modules={[isMobile ? FreeMode : FreeMode, Mousewheel]}
                        slidesPerView={"auto"}
                        spaceBetween={20}
                        mousewheel={isMobile ? false : true}
                        slidesOffsetBefore={isMobile ? 26 : 29}
                        slidesOffsetAfter={isMobile ? 26 : 49}
                    >
                        {moduleData.data.grpCardType == "grpAutomatismMarketplace" ?
                            
                            <React.Fragment>
                                <SwiperSlide className="h-3 w-3"></SwiperSlide>
                                    
                                    {widgetList?.length > 0 && widgetDetail?.length > 0 ? 
                                        
                                        widgetList?.map((widgetMain: any, index: number) => {

                                        let widthCarousel = isMobile ? "!w-auto" : "";

                                        return (
                                            (widgetDetail[index].is_visible || widgetDetail[index].inventory_level != 0) && 
                                            <SwiperSlide key={index} className={`!h-auto ${widthCarousel} overflow-hidden`}>
                                                <ProductCard
                                                    key={index}
                                                    title={widgetMain.productName}
                                                    titleColor="black"
                                                    onClick={() => window.open(lang == 'it' ? 'https://milanomalpensaboutique.com' + widgetDetail[index].custom_url.url : 'https://milanomalpensaboutique.com/en' + widgetDetail[index].custom_url.url)}
                                                    imgUrl={widgetMain.thumbnailUrl}
                                                    subTitle={widgetDetail[index].name}
                                                    subTitleColor="black"
                                                    price={'€' + (widgetDetail[index].price / 1.22).toFixed(2) }
                                                    oldPrice={'€' + widgetDetail[index].price.toString()}
                                                    labelCta={t('shop.book')}
                                                    bgCta="FFFFFF"
                                                />
                                            </SwiperSlide>
                                        )
                                        
                                    }) : <React.Fragment></React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <SwiperSlide className="h-3 w-3"></SwiperSlide>
                                {dataCard?.map((card) => {
                                    let { mediaserverpath, resourcename } = !isMobile ? card?.data?.upiImageShoppingDesktop[lang] : card?.data?.upiImageShoppingMobile[lang];
                                    let imagePath = mediaserverpath + '/' + resourcename;
                                    let widthCarousel = isMobile ? "!w-auto" : ""
                                    return (
                                        <SwiperSlide key={card.data.metainfo.id} className={`!h-auto ${widthCarousel} overflow-hidden`}>
                                            <ProductCard
                                                imgUrl={imagePath}
                                                title={DOMPurify.sanitize(card?.data?.lblTitleShopping && card?.data?.lblTitleShopping[lang] ? card?.data?.lblTitleShopping[lang] : '')}
                                                titleColor={card?.data?.enuTitleColorShopping ? configColor('text', card?.data?.enuTitleColorShopping) : "text-white"}
                                                subTitle={DOMPurify.sanitize(card?.data?.lblSubtitleShopping && card?.data?.lblSubtitleShopping[lang] ? card?.data?.lblSubtitleShopping[lang] : '')}
                                                subTitleColor={card?.data?.enuSubtitleColorShopping ? configColor('text', card?.data?.enuSubtitleColorShopping) : "text-white"}
                                                price={DOMPurify.sanitize(card?.data?.lblPriceShopping && card?.data?.lblPriceShopping[lang] ? card?.data?.lblPriceShopping[lang] : '')}
                                                oldPrice={card?.data?.lblCrossedOutPrice && card?.data?.lblCrossedOutPrice[lang] ? card?.data?.lblCrossedOutPrice[lang] : undefined}
                                                labelCta={card?.data?.lblLabelCtaShopping && card?.data?.lblLabelCtaShopping[lang] ? card?.data?.lblLabelCtaShopping[lang] : ""}
                                                bgCta={card?.data?.enuBackgroundCtaShopping ? configColor('bg', card?.data?.enuBackgroundCtaShopping) : "bg-lightblue"}
                                                onClick={() => navigateTo(card?.data?.lblLinkCtaShopping[lang], card?.data?.flgTargetCtaShopping)} />
                                        </SwiperSlide>
                                    )
                                })}
                            </React.Fragment>
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default VisoreCarousel;
