//NOTA: ROUTE SU CUI UN UTENTE NON PUO' ACCEDERE SENZA TOKEN
export const protectedRoutes = [
  "/it/profile/",
  "/it/profile/account",
  "/it/profile/purchase",
  "/it/profile/flighttracking",
  "/it/profile/fidelitycard",
  "/en/profile/",
  "/en/profile/account",
  "/en/profile/purchase",
  "/en/profile/flighttracking",
  "/en/profile/fidelitycard"
];

//NOTA: 8h
export const expirationTime = 8;

export const buildPasswordRecoveryUrl = (

  lang: string,
  origin_url: string,
  return_url: string
) : string => {

  const url = `${process.env.NEXT_PUBLIC_IDP}/idp/pwdRemind?language=${lang}&site_origin=${origin_url}&return_url=${return_url}`;
  return url;
};

export const buildRegistrationUrl = (

  lang: string,
  origin_url: string,
  return_url: string
) : string => {

  const url = `${process.env.NEXT_PUBLIC_IDP}/idp/registration?company=&language=${lang}&return_url=${return_url}&site_origin=${origin_url}`
  return url;
};

export const buildUpdateAccountUrl = (sign_token: string, lang: string, origin_url: string, return_url: string) => {

  const url = `${process.env.NEXT_PUBLIC_IDP}/idp/profile?company=&return_url=${return_url}&sign_token=${sign_token}&currentUrl=profile&site_origin=${origin_url}&language=${lang}`
  return url;
}
