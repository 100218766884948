import { GlobalSearchInfoAtom, GlobalSearchResultAtom, GlobalSearchTermAtom } from "@/atoms";
import { IPageDTO } from "@/interfaces/IPageDTO";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MoonLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import CustomIcon from "../CustomIcon/CustomIcon";

const GlobalSearchResultList: React.FC = () => {

    const searchResultList = useRecoilValue(GlobalSearchResultAtom)
    const searchTerm = useRecoilValue(GlobalSearchTermAtom)
    const searchResultInfo = useRecoilValue(GlobalSearchInfoAtom)
    const { i18n, t } = useTranslation()

    const searchResultRender = useMemo(() => {
        if (!searchTerm) return null;
        if (!searchResultInfo.searchStarted) return null;
        if (searchResultInfo.callInProgress) return <MoonLoader className="m-auto my-4" />;
        if (!searchResultList.length) return <div className="text-lg font-bold text-mediumgrey mt-6">{t("globalSearch.noResults")} '<b>{searchTerm}</b>'</div>

        return <div className="flex flex-col">
            <div className="my-[2.8rem] text-[24px]">{t("globalSearch.all")} {searchResultList.length} {t("globalSearch.resultsFor")} '<b>{searchTerm}</b>'</div>
            <ResultList searchResultList={searchResultList} />
        </div>

    }, [searchTerm, searchResultList, searchResultInfo, i18n.language])

    return (
        <div className="relative desk:px-0 px-4">
            {searchResultRender}
        </div>
    )
}

interface IResultListProps {
    searchResultList: IPageDTO[]
}

const ResultList: React.FC<IResultListProps> = ({ searchResultList }: IResultListProps) => {
    return <div className="flex flex-col gap-[40px] py-6">
        {searchResultList.map(pageItem => <SearchResultRow resultItem={pageItem} key={pageItem.metainfo.id} />)}
    </div>
}

interface ISearchResultRowProps {
    resultItem: IPageDTO
}

const SearchResultRow: React.FC<ISearchResultRowProps> = ({ resultItem, ...props }) => {
    const { i18n, t } = useTranslation()
    const lang = i18n.language;

    const searchTerm = useRecoilValue(GlobalSearchTermAtom)

    function formatText () {
        const regex = new RegExp(searchTerm, 'gi');

        const formattedText = resultItem?.lblSeoDescription[lang].replace(regex, `<strong>$&</strong>`);
        
        return formattedText
    }

    return (
        <div {...props} className="w-full flex flex-col gap-4 border-b pb-10 border-b-darkgrey">
            <div className="text-[24px]">{resultItem.lblSeoTitle[lang]}</div>
            <div dangerouslySetInnerHTML={{__html: formatText()}}></div>
            <a target="_blank" href={resultItem.lblUrlPath[lang]}>
                <div className="group">
                    <div className="flex text-normal font-bold items-center gap-2 group-hover:text-lightblue ">
                        {t("globalSearch.visitPage")}
                        <CustomIcon iconName="chevron-r-search" className="stroke-black group-hover:stroke-[lightblue]" />
                    </div>
                </div>
            </a>
        </div>)
}

export default GlobalSearchResultList;