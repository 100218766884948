const ConfigurableColors: ConfigurableColors = {
    rosso: ['text-[#FF0000]', 'bg-[#FF0000]', 'border-[#FF0000]', 'fill-[#FF0000]'],   // Red
    bianco: ['text-[#FFFFFF]', 'bg-[#FFFFFF]', 'border-[#FFFFFF]', 'fill-[#FFFFFF]'],  // White
    nero: ['text-[#000000]', 'bg-[#000000]', 'border-[#000000]', 'fill-[#000000]'],    // Black
    azzurro: ['text-[#6ABACE]', 'bg-[#6ABACE]', 'border-[#6ABACE]', 'fill-[#6ABACE]'], // LightBlue
    grigio: ['text-[#F2F2F2]', 'bg-[#F2F2F2]', 'border-[#F2F2F2]', 'fill-[#F2F2F2]'],  // LightGrey
    grigiomedio: ['text-[#909090]', 'bg-[#909090]', 'border-[#909090]', 'fill-[#909090]'],  // MediumGrey
};

interface ConfigurableColors {
    [key: string]: [string, string, string, string];
}

type ColorType = 'text' | 'bg' | 'border' | 'fill';


function configColor(type: ColorType, colorKey: string): string {
    const types: Record<ColorType, number> = {
        text: 0,
        bg: 1,
        border: 2,
        fill: 3,
    };
    const colorArray = ConfigurableColors[colorKey?.toLowerCase()];

    if (colorArray && types.hasOwnProperty(type)) {
        return '' + colorArray[types[type]];
    } else {
        return '';
    }
}

export default configColor;