import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { FLIGHT_DETAIL_PAGE_URLS, MovementType } from "@/config/flightPages";
import { BaseSyntheticEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import styles from './Livesearch.module.css';
import CustomIcon from "../CustomIcon/CustomIcon";
import TextInput from "../TextInput/TextInput";

interface ILivesearchProps {
    movementType: string,
    placeholder?: string,
    searchInput: string,
    setSearchInput: (newValue: string) => void | Dispatch<SetStateAction<string>>,
    searchResults: any[],
    setSearchResults: (newValue: any[]) => void | Dispatch<SetStateAction<any[]>>,
    filterDate?: string | Date | null,
    terminal?: string,
    setSelectedElement: (newValue: any) => void | Dispatch<SetStateAction<any>>
    selectedElement: { type: string, data: any }
}

const Livesearch: React.FC<ILivesearchProps> = ({ selectedElement, setSelectedElement, terminal, movementType, placeholder = '', searchInput = '', setSearchInput = () => console.log('Livesearch input setter not initialized!'), searchResults = [], setSearchResults = () => console.log('Livesearch result setter not initialized!'), filterDate = null }: ILivesearchProps) => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    /* const [searchInput, setSearchInput] = useState<string>(''); */
    /* const [searchResults, setSearchResults] = useState<any[]>([]); */
    const [isOpen, setIsOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const router = useRouter();

    /* const resultList = useMemo(() => {
        return searchResults?.map((item: { type: 'airline' | 'flight' | 'destination', data: any }) => {
            switch (item.type) {
                case 'airline': return <a key={item.data.airlineId} href={FLIGHT_LIST_PAGE_URLS[movementType][lang] + '?airlineIata=' + item.data.airlineIata + '&airportReferenceIata=' + process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE + '&movementType=' + movementType + '&loadingType=P' + (terminal ? '&terminal=' + terminal : '') + (filterDate ? (movementType === MovementType.D ? ('&dateFrom=' + filterDate) : ('&dateFrom='+ filterDate + '&dateTo=' + formatDate(addDays(new Date(), 4)))) : '')}><div className="cursor-pointer text-lightblack text-normal">{item.data.airlineLabel}</div></a>
                case 'destination': return <a key={item.data.airlineIata} href={FLIGHT_LIST_PAGE_URLS[movementType][lang] + "?airportDestinationIata=" + item.data.airportIata + '&airportReferenceIata=' + process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE + '&movementType=' + movementType + '&loadingType=P' + (terminal ? '&terminal=' + terminal : '') + (filterDate ? (movementType === MovementType.D ? ('&dateFrom=' + filterDate) : ('&dateFrom='+ filterDate + '&dateTo=' + formatDate(addDays(new Date(), 4)))) : '')} ><div className="cursor-pointer text-lightblack text-normal" key={item.data.airportIata}>{item.data.airportLabel}</div></a>
                case 'flight': return <a key={item.data.flightId} href={FLIGHT_DETAIL_PAGE_URLS[movementType][lang] + '?flightId=' + item.data.flightId + '&flightNumber=' + item.data.flightNumber?.replace(" ", '')}><div className="cursor-pointer text-lightblack text-normal">{item.data.airlineDescription + ' ' + item.data.flightNumber}</div></a>
            }
        }) || []
    }, [searchResults, lang]) */

    const resultList = useMemo(() => {
        return searchResults?.map((item: { type: 'airline' | 'flight' | 'destination', data: any }) => {
            switch (item.type) {
                case 'airline': return <div key={item.data.airlineId} onClick={() => setSelectedElement(item)} className="cursor-pointer text-lightblack text-normal">{item.data.airlineLabel}</div>
                case 'destination': return <div key={item.data.airlineIata} onClick={() => setSelectedElement(item)} className="cursor-pointer text-lightblack text-normal">{item.data.airportLabel}</div>
                case 'flight': return <a key={item.data.flightId} href={FLIGHT_DETAIL_PAGE_URLS[movementType][lang] + '?flightId=' + item.data.flightId + '&flightNumber=' + item.data.flightNumber?.replace(" ", '')}><div className="cursor-pointer text-lightblack text-normal">{item.data.airlineDescription + ' ' + item.data.flightNumber}</div></a>
            }
        }) || []
    }, [searchResults, lang])

    /*     const debouncedFetchResult = useCallback(debounce((lang, searchInput, movementType) => fetchResults(lang, searchInput, movementType), 300), [])
     */
    const handleSearchChange = (e: BaseSyntheticEvent) => {

        setSearchInput(e.target.value);
    };

    const changeHandler = (e: BaseSyntheticEvent) => {

        setIsSearching(true);
        handleSearchChange(e);
    };

    const fetchResults = (lang: string, searchInput: string, movementType: 'D' | 'A') => {
        fetch(`${process.env.NEXT_PUBLIC_FLIGHTS_BASE_PATH}/${lang}/autocomplete/search?airportReferenceIata=${process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE}&loadingType=P&movementType=${movementType}&querySearch=${searchInput}${filterDate ? '&dateFrom=' + filterDate : ''}${terminal ? '&terminal=' + terminal : ''}`, {
            headers: {
                'Content-Type': 'application/json',
                'keyId': process.env.NEXT_PUBLIC_GATEWAY_KEY as string
            }
        })
            .then(response => response.json())
            .then(
                result => {
                    setSearchResults([
                        ...result.flights.map((item: any) => { return { type: 'flight', data: item } }),
                        ...result.destinations.map((item: any) => { return { type: 'destination', data: item } }),
                        ...result.airlines.map((item: any) => { return { type: 'airline', data: item } }),
                    ])

                    if (router.query['searchText'] && !isSearching) {

                        if (result.destinations.length > 0) {

                            let item = {
                                type: 'destination',
                                data: result.destinations[0]
                            };

                            setSelectedElement(item);

                        } else if (result.airlines.length > 0) {

                            let item = {
                                type: 'airline',
                                data: result.airlines.find((airline: any) => airline.airlineDescription === router.query['searchText'])
                            };

                            setSelectedElement(item);

                        }
                    } else {
                        setIsOpen(true);
                    }
                }
            )
            .catch(error => {

                console.error('[LiveSearch] error -> ', error);
            })
    }

    useEffect(() => {

        if (searchInput.length >= 3) fetchResults(lang, searchInput, movementType as MovementType);
        if (!searchInput.length) setSearchResults([]);
    }, [searchInput, lang, movementType])

    return (
        <div className="flex-1 relative w-[inherit]">
            <TextInput
                placeholder={placeholder}
                value={selectedElement ? (selectedElement?.type === 'destination' ? selectedElement.data.airportLabel : (selectedElement?.type === 'airline' ? selectedElement.data.airlineLabel : null)) : searchInput}
                postfix={<CustomIcon iconName="search" />}
                onChange={e => changeHandler(e)}
            />
            {
                isOpen ?
                    <div className="absolute h-fit overflow-hidden bg-white py-2 w-full rounded-b-2xl" style={{ top: '35px' }}>
                        <div onClick={e => setIsOpen(false)} style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15)' }} className={styles.customSelect + " overflow-auto max-h-64 flex rounded-b-2xl flex-col border-2 px-4 py-8 gap-8"}>
                            {resultList.length >= 1 ? resultList :
                                <div className="text-gray-400">{t('flights.noResults')}</div>}
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default Livesearch;
