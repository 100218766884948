import { FlightDetailsAtom } from "@/atoms";
import { useRecoilValue } from 'recoil';
import { IStepperItem } from "@/interfaces/IStepper";
import { useTranslation } from "react-i18next";

interface IStepperProps {
    isMobile: boolean,
    content: IStepperItem,
    language: string
}

const ArrivalBaggage: React.FC<IStepperProps> = ({ isMobile, content, language }: IStepperProps) => {

    const lang = language;
    const { t } = useTranslation()
    const flightDetail = useRecoilValue(FlightDetailsAtom);
    const baggageClaim = flightDetail?.baggageClaim ? flightDetail?.baggageClaim : '-';

    return (
        <>
            <h1 className="min-h-[66px] text-lg desk:text-2xl text-base/1 text-[#333333] font-bold">{content.lblTitle[lang]}</h1>
            <h2 className="">{t('flights.baggageCarousel') + " " + baggageClaim}</h2>
        </>
    );
}

export default ArrivalBaggage;
