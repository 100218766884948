import { IToken } from "@/api/auth";
import Cookies from "js-cookie";
import { useState, createContext, useMemo, useContext } from "react";

interface AuthProviderContext {
  user: IToken | null;
  /**
   * This method should be called after login success API in order to save token and other information
   *  on this Provider.
   * @param token
   */
  updateUser: (token: IToken) => void;
  /**
   * This method allow (after calling Logout API) to set null user state
   * in order to update entire application and component which use useAuth() function
   */
  removeUser: () => void;
}

export const AuthContext = createContext<AuthProviderContext | undefined>(
  undefined
);
export type AuthContexrProps = React.PropsWithChildren;

export const AuthProvider = ({ children }: AuthContexrProps) => {
  const userInfo = Cookies.get("token");

  const initialToken = userInfo
    ? (JSON.parse(userInfo as unknown as string) as IToken)
    : null;
  const [user, setUser] = useState<IToken | null>(initialToken);

  const updateUser = (token: IToken) => {
    setUser(token);
  };

  const removeUser = () => {
    setUser(null);
  };

  const value: AuthProviderContext = useMemo(() => {
    return {
      user,
      updateUser,
      removeUser,
    };
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
