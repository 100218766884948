import { FLIGHT_LIST_PAGE_URLS, MovementType, flightPanelInitialValues } from "@/config/flightPages";
import { BaseSyntheticEvent, useCallback, useState } from "react";
import { addDays, formatDate } from "@/utils";
import { add, endOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

import styles from './FlightPanel.module.css';
import Button from "../Button/Button";
import Livesearch from "../Livesearch/Livesearch";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it'
import CustomIcon from "../CustomIcon/CustomIcon";
import "react-datepicker/dist/react-datepicker.css";
import formatDateFlight from "@/utils/formatDateFlight";

interface IFlightPanelProps {
    data: any,
    language: string
}

const FlightPanel: React.FC<IFlightPanelProps> = ({ data, language }) => {
    const [liveSearchResults, setLiveSearchResults] = useState<any[]>([]);
    const [selectedElement, setSelectedElement] = useState<any>(null);

    const [form, setForm] = useState(flightPanelInitialValues);
    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;
    const { t } = useTranslation()
    lang === "it" && registerLocale(lang, it)

    const handleRadioChange = (e: BaseSyntheticEvent) => {
        let newValue = Array.prototype.slice.call(e.target.parentElement.childNodes).find(item => item.name === 'movementType').value;
        setForm({
            ...form,
            movementType: newValue
        })
    }

    const handleTextSearchChange = (e: BaseSyntheticEvent) => {
        setForm({
            ...form,
            searchText: e.target.value
        })
    }

    const searchButton = useCallback(() => {

        let paramsObj: any = {
            movementType: form.movementType,
            /* dateFrom: formatDate(form.dateFrom), */
            dateFrom: formatDateFlight(form.dateFrom),
            dateTo: formatDate(endOfDay(new Date(form.dateFrom))),
            loadingType: flightPanelInitialValues.loadingType,
            airportReferenceIata: process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE,
            mfFlightType: 'P'
        };

        if (selectedElement) {

            paramsObj = selectedElement?.type === 'destination' ? {...paramsObj, airportDestinationIata : selectedElement.data.airportIata} : paramsObj
            paramsObj = selectedElement?.type === 'airline' ? {...paramsObj, airlineDescription : selectedElement.data.airlineDescription }: paramsObj

            if (selectedElement?.type === 'destination') {

                paramsObj = { ...paramsObj, searchText: selectedElement.data.airportLabel };
            }

            if (selectedElement?.type === 'airline') {

                paramsObj = { ...paramsObj, searchText: selectedElement.data.airlineDescription };
                paramsObj = { ...paramsObj, airlineIata: selectedElement.data.airlineIata };
            }
        }

        let queryParams = new URLSearchParams(paramsObj);

        return (
            <a href={FLIGHT_LIST_PAGE_URLS[form.movementType][lang] + '?' + queryParams}>
                <Button className="active:bg-lightblue" text={`${data?.lblButton[lang]}`} />
            </a>
        )
    }, [lang, form, selectedElement])

    return <>
        <div className=" text-black flex w-full px-3 py-5 desk:pl-12 desk:pr-8 desk:py-5 flex-col items-start gap-4 desk:gap-0 desk:flex-row desk:items-center ">
            <div className="radioContainer flex gap-8 mr-4">
                <div className="radioItem flex justify-center items-center">
                    <div onClick={handleRadioChange} className={styles.customRadio + ' transition duration-300 flex items-center justify-center cursor-pointer '} style={form.movementType === 'A' ? { background: 'black' } : {}}><div className={styles.selectedItem + ' ' + (form.movementType === 'A' ? styles.activeRadio : '')}></div></div>
                    <input style={{ display: 'none' }} type="radio" defaultChecked={form.movementType === 'A'} value="A" name="movementType" />
                    <label className="ml-2 text-sm">{t('flights.arrivals')}</label>
                </div>
                <div className="radioItem flex justify-center items-center">
                    <div onClick={handleRadioChange} className={styles.customRadio + ' transition duration-300 flex items-center justify-center cursor-pointer '} style={form.movementType === 'D' ? { background: 'black' } : {}}><div className={styles.selectedItem + ' ' + (form.movementType === 'D' ? styles.activeRadio : '')}></div></div>
                    <input style={{ display: 'none' }} type="radio" defaultChecked={form.movementType === 'D'} value="D" name="movementType" />
                    <label className="ml-2 text-sm">{t('flights.departures')}</label>
                </div>
            </div>
            <div className="flex w-full desk:items-center gap-4 desk:flex-row flex-col desk:ml-14 desk:mr-4">
                <div className="customPickerWrapper w-full desk:w-fit desk:flex desk:flex-1 relative items-center">
                    <ReactDatePicker dateFormat="dd MMMM yyyy" locale={lang === 'it' ? 'it' : 'en'} minDate={new Date()} maxDate={add(new Date(), { days: 4 })} wrapperClassName="customPicker" selected={form.dateFrom} onChange={(date) => setForm({ ...form, dateFrom: date as Date })} />
                    <CustomIcon iconName="chevron-r" className="w-2 absolute right-5 top-4 transition-transform origin-center duration-300" />
                </div>
                <div className="flex-1">
                    <Livesearch selectedElement={selectedElement} setSelectedElement={setSelectedElement} filterDate={formatDate(form.dateFrom)} searchInput={form.searchText} setSearchInput={(newSearch: string) => setForm({...form, searchText: newSearch})} searchResults={liveSearchResults} setSearchResults={(newValue: any[]) => setLiveSearchResults(newValue)} movementType={form.movementType} placeholder={t('flights.livesearchPlaceholder').toString()} />
                </div>
            </div>
            <div className="buttonWrapper desk:ml-4 flex justify-center w-full desk:w-fit">
                {searchButton()}
            </div>
        </div>
    </>
}

export default FlightPanel;