import { IFooterDTO } from "@/interfaces/IFooterDTO";

import gridCol from "@/utils/gridCol";
import CustomIcon from "../CustomIcon/CustomIcon";
import availableSites from "@/config/sitesConfig";
import { MutableRefObject, useRef, useState } from "react";
import { useOutsideClick } from "@/hooks";
import getPathByLink from "../../utils/getPathByLink";
import { useTranslation } from "react-i18next";

interface IFooterProps {
  dataFooter: IFooterDTO[];
  lang: string;
}

const Footer: React.FC<IFooterProps> = ({ dataFooter, lang }: IFooterProps) => {

  const { t, i18n } = useTranslation();
  const moduleData: IFooterDTO | undefined = dataFooter[0];

  const language = lang? lang : i18n.language;

  const lenghtlstSiteMap: number = moduleData?.lstSiteMap?.length ? moduleData?.lstSiteMap.length : 0;
  const lenghtlstSocial: number = moduleData?.lstSocial?.length ? moduleData?.lstSocial.length : 0;
  const lenghtlstAppStore: number = moduleData?.lstAppStore?.length ? moduleData?.lstAppStore.length : 0;

  const flgTarget: string = moduleData?.flgTarget ? "_blank" : "_self";
  const flgTargetPrm: string = moduleData?.flgTargetPrm ? "_blank" : "_self";
  const flgTargetSea: string = moduleData?.flgTargetSea ? "_blank" : "_self";
  const upiLogoWhite: string = moduleData?.xtaxAirport?.ti?.upiLogoWhite ? moduleData.xtaxAirport.ti.upiLogoWhite.mediaserverpath + "/" + moduleData.xtaxAirport.ti.upiLogoWhite.resourcename : "";

  const upiImageDeskPrm: string = moduleData?.upiImageDeskPrm?.mediaserverpath ? moduleData?.upiImageDeskPrm?.mediaserverpath + "/" + moduleData?.upiImageDeskPrm?.resourcename : "";
  const upiImageDeskSea: string = moduleData?.upiImageDeskSea?.mediaserverpath + "/" + moduleData?.upiImageDeskSea?.resourcename

  const txbSubtitle: string = moduleData?.txbSubtitle && moduleData?.txbSubtitle[language] ? moduleData?.txbSubtitle[language] : "";
  const lblCellphoneNumber: string = moduleData?.lblCellphoneNumber && moduleData?.lblCellphoneNumber[language] ? moduleData?.lblCellphoneNumber[language] : "";
  const lblAppStore: string = moduleData?.lblAppStore && moduleData?.lblAppStore[language] ? moduleData?.lblAppStore[language] : "";
  const lblLinkSea: string = moduleData?.lblLinkSea && moduleData?.lblLinkSea[language] ? moduleData?.lblLinkSea[language] : "";
  const lblLinkPrm: string = moduleData?.lblLinkPrm && moduleData?.lblLinkPrm[language] ? moduleData?.lblLinkPrm[language] : moduleData?.xconGenericPagePrm?.lblUrlPath && moduleData?.xconGenericPagePrm?.lblUrlPath[language] ? moduleData?.xconGenericPagePrm?.lblUrlPath[language] : "";

  const textCopyright: string = "Content Copyright " + new Date().getFullYear() + " S.E.A. S.p.A. P.IVA 00826040156";

  const siteList = availableSites();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useOutsideClick(dropdownRef as MutableRefObject<HTMLDivElement>, () => { console.log("Setto l'elemento selezionato a null"); setIsDropdownOpen(false) });


  const LogoAirport =
    <a href={`/${language}`} target={flgTarget}>
      <img src={upiLogoWhite} alt="logoAirport" width={241} height={29} />
    </a>

  const text =
    <h2 className="desk:text-sm">
      {txbSubtitle}
    </h2>

  const seaIcon =
    <a href={lblLinkSea} target={flgTargetSea} className="flex justify-end">
      <img src={upiImageDeskSea} alt="seaIcon" className="w-fit" />
    </a>

  const disabileIcon = upiImageDeskPrm ?
    <a href={lblLinkPrm} target={flgTargetPrm} className="flex justify-end">
      <img src={upiImageDeskPrm} alt="disableIcon" height={40} width={40} className="max-w-none" />
    </a>
    : null

  const phone = lblCellphoneNumber ?
    <a href={`tel:${lblCellphoneNumber}`} className="text-[#F2F2F2] flex gap-2 items-center font-bold desk:font-normal">
      <CustomIcon iconName="phone" className="fill-[#F2F2F2] w-6 h-6 desk:w-4 desk:h-4" />
      {lblCellphoneNumber}
    </a> : null

  const appStore = lenghtlstAppStore == 0 ? null :
    <div className="flex flex-col gap-2">
      <p className="desk:text-sm">
        {lblAppStore}
      </p>
      <div className="flex gap-4">
        {moduleData?.lstAppStore?.map((item) => {

          const itemId: number = item.id
          const lblUrlAppStore: string = item.lblUrlAppStore
          const upiImageDeskLogoAlt: string = item.upiImageDeskLogo.alt
          const upiImageDeskLogo: string = item.upiImageDeskLogo.mediaserverpath + "/" + item.upiImageDeskLogo.resourcename


          return (
            <a key={itemId} href={lblUrlAppStore}>
              <img src={upiImageDeskLogo} alt={upiImageDeskLogoAlt} className="w-28" />
            </a>
          );
        })}
      </div>
    </div>

  const socialIcons = lenghtlstSocial == 0 ? null :
    <div className="flex gap-11 desk:gap-5 tab:col-start-2">
      {moduleData?.lstSocial?.map((item) => {

        const itemId: number = item.id
        const lblUrlSocial: string = item.lblUrlSocial
        const flgTargetSocial: string = item.flgTargetSocial ? "_black" : "_self"
        const upiImageSocial: string = item.upiImageSocial.mediaserverpath + "/" + item.upiImageSocial.resourcename
        const upiImageSocialAlt: string = item.upiImageSocial.alt

        return (
          <a key={itemId} href={lblUrlSocial} target={flgTargetSocial}>
            <img src={upiImageSocial} alt={"boh"} className="w-5 h-5 desk:w-4 desk:h-4" />
          </a>
        )
      })}
    </div>

  const siteMapMobTab =
    <div className="flex flex-col gap-5 py-3">
      {moduleData?.lstSiteMap?.map((item) => {

        const itemId: number = item.id
        const lblTitle: string = item.lblTitle[language]
        const flgTargetSiteMap: string = item.flgTargetSiteMap ? "_blank" : "_self"
        const lblLinkSiteMap: string = item.lblLinkSiteMap[language]
        const [clicked, setClicked] = useState(false);

        return (
          <div key={itemId} className="flex flex-col" onClick={() => !clicked ? setClicked(true) : setClicked(false)}>
            <div className="flex gap-3 items-center">
              {lblLinkSiteMap ?
                <a href={lblLinkSiteMap} target={flgTargetSiteMap} className="text-[#F2F2F2] font-bold text-xl">
                  {lblTitle}
                </a>
                : <div className="text-[#F2F2F2] font-bold text-xl">
                  {lblTitle}
                </div>}
              <CustomIcon iconName="arrow" color={"#FFFFFF"} className="fill-[#FFFFFF] h-3 w-3" />
            </div>
            <div className={`${clicked ? '' : 'hidden'} ml-3 mt-2 flex flex-col`}>
              {item.lstLink.map((el) => {

                const elId: number = el.id
                const lblUrlField: string = el.lblUrlField[language]
                const flgTargetSiteMap: string = item.flgTargetSiteMap ? "_blank" : "_self"
                const lblFieldName: string = el.lblFieldName[language]

                return (
                  <a key={elId} href={lblUrlField} target={flgTargetSiteMap} className="pb-2 text-sm font-bold">
                    {lblFieldName}
                  </a>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>

  const siteMapDesk =
    <div className={`grid ${gridCol(lenghtlstSiteMap, 3)}`}>
      {moduleData?.lstSiteMap?.map((item) => {

        const itemId: number = item.id
        const lblTitle: string = item.lblTitle[language]
        const flgTargetSiteMap: string = item.flgTargetSiteMap ? "_blank" : "_self"
        const lblLinkSiteMap: string = item.lblLinkSiteMap[language]

        return (
          <div key={itemId} className="flex flex-col px-4">
            {lblLinkSiteMap ?
              <a href={lblLinkSiteMap} target={flgTargetSiteMap} className="text-[#F2F2F2] pb-8 text-sm">
                {lblTitle}
              </a>
              : <div className="text-[#F2F2F2] pb-8 text-sm">
                {lblTitle}
              </div>}
            {item.lstLink.map((el) => {

              const elId: number = el.id
              const lblUrlField: string = el.lblUrlField[language]
              const flgTargetSiteMap: string = item.flgTargetSiteMap ? "_blank" : "_self"
              const lblFieldName: string = el.lblFieldName[language]

              return (
                <a key={elId} href={lblUrlField} target={flgTargetSiteMap} className="pb-2 text-sm font-bold">
                  {lblFieldName}
                </a>
              );
            })}
          </div>
        );
      })}
    </div>



  return (
    <>
      <div className="bg-[#333333] text-[#FFFFFF] flex flex-col gap-8">


        {/* MOBILE */}
        <div className="tab:hidden flex flex-col gap-8 px-6 pt-9">
          {/* Milano Malpensa */}
          {LogoAirport}
          {/* Testo */}
          {text}
          {/* Numero di telefono */}
          {phone}
          {/* SiteMap */}
          {siteMapMobTab}
          {/* Icone Social */}
          {socialIcons}
        </div>


        {/* TABLET */}
        <div className="hidden tab:flex desk:hidden flex-col gap-8 px-6 pt-14">
          <div className="grid grid-cols-2 gap-28">
            <div className="flex flex-col gap-8">
              {/* Milano Malpensa */}
              {LogoAirport}
              {/* Testo */}
              {text}
            </div>
            {/* SiteMap */}
            {siteMapMobTab}
          </div>
          <div className="grid grid-cols-2 items-center gap-28">
            {/* Numero di telefono */}
            {phone}
            {/* Icone Social */}
            {socialIcons}
          </div>
        </div>


        {/* DESKTOP */}
        <div className="hidden desk:flex px-6 pt-24 max-w-cont-1 mx-auto w-full">
          <div className="grid grid-cols-6 gap-16">
            <div className="flex flex-col gap-7 col-span-2 px-0">
              {/* Milano Malpensa */}
              {LogoAirport}
              {/* Testo */}
              {text}
              {/* Numero di telefono */}
              {phone}
              {/* Icone Social */}
              {socialIcons}
            </div>
            {/* SiteMap */}
            <div className="col-span-4 flex justify-center">
              {siteMapDesk}
            </div>
          </div>

          <div>
            {disabileIcon}
          </div>

        </div>

        {/* Download Store */}
        {/* <div className="flex px-6 tab:px-12 desk:pl-24 desk:pr-7 pb-14 tab:pb-7"> */}
        <div className="grid grid-cols-5 pb-14 tab:pb-7 items-end max-w-cont-1 mx-auto px-6 w-full">
          <div className="col-span-4 ">
            {appStore}
          </div>
          <div className="block tab:hidden col-span-1">
            {disabileIcon}
          </div>
          <div className="hidden tab:block col-span-1">
            {seaIcon}
          </div>

        </div>



      </div>

      <div className="border-4 border-[#E20129]"></div>

      {/* SubFooter */}
      <div className="text-xs bg-lightblack ">
        <div className="p-6 flex justify-between flex-col tab:flex-row gap-y-5 max-w-cont-1 w-full mx-auto">
          <div className="text-[#F2F2F2] font-bold flex gap-2">
            <div className="text-black">
              <div className="p-2 gap-3 flex text-sm text-lightgrey custom-select">
                <div>{t('homepage.footer_airport')}</div>
                <div className="relative">
                  <div className="selectedOpt  gap-2 items-center flex cursor-pointer" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>{process.env.NEXT_PUBLIC_AIRPORT_REFERENCE} <CustomIcon color="lightgrey" className="rotate-90" iconName="chevron-r" /></div>
                  {isDropdownOpen ? <div ref={dropdownRef} className="flex flex-col z-10 bg-lightblack border border-lightgrey p-2 rounded-xl absolute top-7 left-0">
                    {siteList.map((item) => {

                      return (
                        <a className="custom-select-opt cursor-pointer" key={item.id} href={item.siteUrl} >
                          {item.label}
                        </a>
                      )
                    })}
                  </div> : null}

                </div>

              </div>

            </div>

          </div>
          <div className="flex flex-wrap gap-x-10 gap-y-3 tab:gap-x-5 desk:gap-x-10 items-center">
            {moduleData?.lstLinkSubfooter?.map((item) => {

              const itemId: number = item.id
              const lblFieldNameSubfooter: string = item.lblFieldNameSubfooter[language]
              const lblLinkSiteMapSubFooter: string = item.lblLinkSiteMapSubFooter[language]
              const flgTargetSiteMapSubFooter: string = item.flgTargetSiteMapSubFooter ? "_blank" : "_self"

              return (
                <a href={getPathByLink(item, language)} key={itemId} target={flgTargetSiteMapSubFooter} className="text-[#FFFFFF] underline underline-offset-1 hover:no-underline">
                  {lblFieldNameSubfooter}
                </a>
              );
            })}
          </div>
        </div>
        <p className="bg-[#F2F2F2] text-[#333333] p-3 text-center text-xs">
          {textCopyright}
        </p>
      </div>
    </>
  );
};

export default Footer;
