import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LoyaltyBalanceDataAtom, LoyaltyRewardDataAtom } from "@/atoms";
import LoyaltyCatalogListSelector from "@/selectors/LoyaltyRewardListSelector";
import uuid from "react-uuid";
import CustomIcon from "../CustomIcon/CustomIcon";
import React from "react";


// const rewardUrl = `${process.env.NEXT_PUBLIC_LOYALTY_PATH}/ols-loyalty-services/v1/LoyaltyRewardAdapter/getRewardCatalog`;

interface IMyRewards {
	fetchRewards: any,
    residual: any
}

const MyRewards: React.FC<IMyRewards> = ({ fetchRewards, residual }) => {

    
    const [ftResidual, setFtResidual] = useState<number>(0);
    const [voucheurResidual, setVoucheurResidual] = useState<number>(0);
    const [vipResidual, setVipResidual] = useState<number>(0);
    
    const balanceData = useRecoilValue(LoyaltyBalanceDataAtom);

    //const filteredRewardList = useRecoilValue(LoyaltyCatalogListSelector);

    useEffect(() => {

        if (residual?.accessListFT?.length > 0) {

            let count = 0;

            residual.accessListFT.map((ftItem: any) => {

                count += ftItem.residual as number;                
            });

            setFtResidual(count);

        } else if (residual?.accessListFT?.length == undefined && residual?.accessListFT?.residual) {

            setFtResidual(residual.accessListFT.residual);
        }

        if (residual?.accessListSV?.length > 0) {

            let count = 0;

            residual.accessListSV.map((svItem: any) => {

                count += svItem.residual as number;                
            });

            setVipResidual(count);

        } else if (residual?.accessListSV?.length == undefined && residual?.accessListSV?.residual) {

            setVipResidual(residual.accessListSV.residual);
        }

        if (residual?.voucherList?.length > 0) {

            let count = 0;

            residual.voucherList.map((vItem: any) => {

                count += vItem.residual as number;                
            });

            setVoucheurResidual(count);

        } else if (residual?.voucherList?.length == undefined && residual?.voucherList?.residual) {

            setVoucheurResidual(residual.voucherList.residual);
        }
        
    }, [residual])

    // const rewardList = useMemo(() => {

    //     // if (filteredRewardList.length > 0) {

    //     //     return filteredRewardList.map(catalogItem => {

    //     //         return <RewardCard title={catalogItem.profileDescr} counter={catalogItem.residual} key={uuid()} />
    //     //     })
    //     // } else {

    //         return (

                
    //         )
    //     // }

        
    // }, []);

    useEffect(() => {

        if (balanceData) {
            
            fetchRewards();
        }
         
    }, [balanceData])

    return(
        <div>
            <div className="flex justify-between mb-4">
                <div className="text-lg font-bold text-black">
                    {t('loyalty.myRewards')}
                </div>
                <a href="#prize-history" className="underline text-sm text-black cursor-pointer hover:text-[lightblue]">
                    {t('loyalty.rewardHistoryCta')}
                </a>
            </div>
            <div className="flex flex-col desk:flex-row gap-4">
                <React.Fragment>
                    <RewardCard title={'Fast Track'} counter={ftResidual} key={uuid()} />
                    <RewardCard title={'Voucher'} counter={voucheurResidual} key={uuid()} />
                    <RewardCard title={'Vip Lounge'} counter={vipResidual} key={uuid()} />
                </React.Fragment>
            </div>
        </div>
    )
}

interface IRewardCardProps{
    title: string, 
    counter: number
}

const RewardCard: React.FC<IRewardCardProps> = ({title, counter}: IRewardCardProps) => {
    return (
        <div className="flex w-full desk:w-[250px] desk:h-[84px] p-4 bg-lightgrey rounded-3xl justify-between">
            <div className="flex items-center gap-2">
                <CustomIcon className="min-w-[27px] w-[2.5rem]" color="white" iconName="cup-blue" stroke="lightblue" />
                <div className="text-black font-bold text-base desk:text-[15px] leading-4">{title}</div>
            </div>
            <div className="flex flex-col items-center border-l pl-4 border-darkgrey">
                <div className="font-semibold text-3xl">
                    {counter}
                </div>
                <div className="text-sm">{t('loyalty.residue')}</div>
            </div>
        </div>
    )
}

export default MyRewards;