import { RecoilState, useRecoilValue } from "recoil";
import { IFlightsProps } from "../Flights/Flights";
import { IFlightItem } from "../FlightRow/FlightRow";
import FlightByDestinationListAtom from "@/atoms/FlightByDestinationListAtom";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import React, { ReactElement, useMemo, useState } from "react";
import { DestinationFlightRow, EmptyState, LaunchList, SeeOtherBtn } from "..";;
import DestinationCityAtom from "@/atoms/DestinationCityAtom";
import { LanguageAtom } from "@/atoms";
import DestinationPageAtom from "@/atoms/DestinationPageAtom";
import { useIsMobile } from "../../hooks";
import FlightByDestinationListActiveAtom from "@/atoms/FlightByDestinationListActiveAtom";

export const FlightsByDestination: React.FC<IFlightByDestinationListProps> = ({ dividerTargetUrl, children, language }: IFlightByDestinationListProps) => {

    return (
        <div className="mt-20">
            <FlightByDestinationList language={language} dividerTargetUrl={dividerTargetUrl} />
            {children}
        </div>
    )
}

interface IFlightByDestinationListProps extends IFlightsProps {
    children?: ReactElement<any, any>
}

export const FlightByDestinationList: React.FC<IFlightByDestinationListProps> = ({ dividerTargetUrl, language }: IFlightByDestinationListProps) => {

    const flightList = useRecoilValue<IFlightItem[]>(FlightByDestinationListAtom as RecoilState<IFlightItem[]>);
    const isActive = useRecoilValue<boolean>(FlightByDestinationListActiveAtom as RecoilState<boolean>);
    const { t, i18n } = useTranslation();
    const lang = language ? language : i18n.language;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile] = useIsMobile()
    const currentFlights = useMemo(() => {
        return flightList?.slice(0, currentIndex + 14)
    }, [currentIndex, flightList, lang])
    const pageData = useRecoilValue(DestinationPageAtom);

    return (
        <div className={currentFlights?.length ? "max-w-[91rem] mx-auto" : ""}>
            <div className="flex flex-col gap-8 w-full">
                {currentFlights?.length ? <div className={"tableHeader w-full flex " + (!pageData.flgActivateVoice && !isMobile ? 'justify-center' : '')}>
                    <div className="w-2/6 desk:w-1/6 desk:pl-12 text-lg font-bold text-lightblue text-center desk:text-left">{t('destinations.flight')}</div>
                    <div className="hidden desk:block w-1/6 text-lg font-bold text-lightblue text-center">{t('destinations.airline')}</div>
                    <div className="w-2/6 desk:w-2/6 text-lg font-bold text-lightblue text-center">{`${isMobile ? t('destinations.activityShort') : t('destinations.activity')}`}</div>
                    <div className="w-2/6 desk:w-1/6 text-lg font-bold text-lightblue text-center">{`${isMobile ? t('destinations.frequencyShort') : t('destinations.frequency')}`}</div>
                    { isActive ? <div className="w-2/6 desk:w-1/6 text-lg font-bold text-lightblue text-center text-white">.</div> : <></> }
                    {pageData.flgActivateVoice ? <div className="hidden desk:block desk:w-1/6 text-lg font-bold text-lightblue text-center"></div> : null}
                </div> : null}
                {currentFlights?.length ? currentFlights.map((flightItem, loopIndex) => {
                    return (
                        <React.Fragment key={loopIndex}>
                            {(loopIndex + 1) % 6 === 0 ?
                                <LaunchList language={lang} data={null} key={loopIndex} dividerMode targetUrl={dividerTargetUrl!} /> :
                                <DestinationFlightRow actionColumn={isActive} actionLabel={t('flights.buyTickets') as string} actionLink={`/${lang}/voli/prenota-il-tuo-volo`} key={flightItem.flightId} flightItem={flightItem} />}
                        </React.Fragment>
                    )
                }) : <div className="w-full flex justify-center mb-8">{(Array.isArray(flightList) && flightList?.length === 0) ? <EmptyState /> : null}</div>}
            </div>
            {!(currentIndex + 14 >= flightList?.length) && currentFlights?.length ? <SeeOtherBtn onClick={() => setCurrentIndex(currentIndex + 14)} className="m-auto mt-8 mb-8" text={t('flights.nextFlights')} iconType="next" /> : null}
        </div>
    )
}


