import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IToken, LoginRequest, login } from "@/api/auth";
import { useAuth } from "@/context/AuthContext";
import {
  buildPasswordRecoveryUrl,
  buildRegistrationUrl
} from "@/auth/auth-config";
import { useRecoilState } from "recoil";
import { IsDialogLoginOpenAtom } from "@/atoms";
import { CloseIcon } from "../CloseIcon/CloseIcon";

import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import CustomIcon from "../CustomIcon/CustomIcon";
import { MoonLoader } from "react-spinners";



const LoginDialog: React.FC = () => {
  const auth = useAuth();
  const [isError, setIsError] = useState(false);
  
  const [isDialogLoginOpen, setIsDialogLoginOpen] = useRecoilState(IsDialogLoginOpenAtom);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [notification, setNotification] = useState<string>('');
  const [click, setClick] = useState<any>();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [body, setBody] = useState<LoginRequest>({
    username: "",
    password: "",
  });

  const handleChange = (e: any, key: string) => {

    setBody({

      ...body,
      [key]: e.target.value,
    } as LoginRequest);

    setIsError(false);

    if (key === "password") {

      if (e.target.value.length > 0) {

        setIsPasswordSet(true);
      } else {

        setIsPasswordSet(false);
      }
    }

    if (key === "username") {

      const check = /\S+@\S+\.\S+/.test(e.target.value);

      if (check) {

        setNotification("");
        setIsEmailValid(true);
      } else {
        
        setNotification(t('login.wrongEmail') as string);
        setIsEmailValid(false);
      }
    }
  };

  const onClose = () => {

    setIsDialogLoginOpen(false)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const check = /\S+@\S+\.\S+/.test(body?.username);
    if(check){
        setIsError(false);
    } else {
        setIsError(true);
    }

    if (body) {
      try {
        const result = await login(body);
        if (result?.token) {
          auth?.updateUser(result as unknown as IToken);
          setIsError(false);
          onClose();
        }
      } catch (error) {
        setIsError(true);
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const onRecoveryPasswordClick = () => {

    const origin = window.location.origin;
    const url = buildPasswordRecoveryUrl(lang, process.env.NEXT_PUBLIC_AIRPORT_REFERENCE_IDP!, origin);
    window.open(url, '_blank');
  };

  const onRegistrationClick = () => {

    const origin = window.location.origin;
    const url = buildRegistrationUrl(lang, process.env.NEXT_PUBLIC_AIRPORT_REFERENCE_IDP!, origin);
    window.open(url, '_blank');
  };

  const { t } = useTranslation();

  useEffect(()=>{
    const concernedElement = document?.querySelector(".click-text"); 

    document.addEventListener("mousedown", (event: any) => {
        if (concernedElement?.contains(event.target) || event.key === 'Enter') {
            setClick(false);
        } else {
            setClick(true);
        }
    });  
  },[])

  return (
    <>
      <div className="flex justify-center items-center fixed inset-0 z-[99]">
        <div className="relative mob:w-[415px] w-[80%] h-max bg-[#F2F2F2] rounded-2xl">
          <button
            className="bg-black p-0 rounded-3xl float-right relative left-1"
            onClick={onClose}
          >
            <CloseIcon onClick={onClose} />
          </button>
          <h3
            className={`font-semibold text-3xl font-secondary mt-8 px-8 uppercase`}
          >
            {t("login.title")}
          </h3>
          <div className={`border-0 relative flex flex-col w-full outline-none focus:outline-none px-8 flex-auto`}>
            <form>
              <div className="mt-8">
               <div className={`${isError || (!isEmailValid && body?.username != "" && click) ? "border-2 border-solid border-red rounded-2xl" : ""}`}>
                  <TextInput
                    className={`click-text text-sm`}
                    fullWidth
                    placeholder={t("login.placeholderEmail")!}
                    value={body?.username}
                    onChange={(e) => handleChange(e, "username")}
                  />
                </div>
                <div className={`${isError ? "border-2 border-solid border-red rounded-2xl" : ""} mt-4`}>
                  <TextInput
                    className="text-sm"
                    fullWidth
                    placeholder={
                      t("login.placeholderPassword")!
                    }
                    value={body?.password}
                    onChange={isEmailValid ? (e: any) => handleChange(e, "password") : () => {}}
                    type={showPassword ? "text" : "password"}
                    postfix={<div onClick={() => setShowPassword(!showPassword)}><CustomIcon iconName={"show-password"} className="h-6 w-5" /></div>}
                  />
                </div>
                {isError ?
                <div className="flex justify-center mt-4 -mb-2 text-red font-primary font-bold text-sm">{t("login.wrongEmailOrPassword")}</div>
                : null}
                { notification && click && body?.username != "" ?
                        <div className="text-center mt-4">
                            <h1 className="text-red text-sm font-bold">{notification}</h1>
                        </div>
                        : <></>
                    }
                { !isLoading ?
                <div className="flex items-center desk:gap-x-10 gap-x-5 text-center">
                  <div className="mt-6">
                    <Button
                      className={`${!isEmailValid || !isPasswordSet ? "bg-[grey] hover:!bg-[grey]" : "bg-[black]"}`}
                      type="submit"
                      text={t("login.loginButton")}
                      onClick={onSubmit}
                      disabled={!isEmailValid || !isPasswordSet}
                    />
                  </div>
                  <div
                    className="underline self-center mt-5 text-sm cursor-pointer hover:text-[lightblue]"
                    onClick={onRecoveryPasswordClick}
                  >
                    {t("login.forgetPasswordText")}
                  </div>
                </div>
                : <MoonLoader className="m-auto mt-6" color="#6ABACE"/>
                }
              </div>
            </form>
          </div>

          {/*footer*/}

          <div className="w-full border-solid border border-[#6ABACE] mt-7" />
          <div
            className="flex justify-center items-center gap-2 my-5"
            onClick={onRegistrationClick}
          >
            <h3 className="font-bold text-[#6ABACE] text-base uppercase cursor-pointer flex">
              {t("login.newUserText")}
            </h3>
            <div className="w-2">
              <CustomIcon stroke="#6ABACE" fill="#6ABACE" iconName="arrow-profile" />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default LoginDialog;
